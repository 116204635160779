import { BaseAPI, API } from './base-api';

export default class LogoutApi extends BaseAPI {

  constructor() {
    super(API.LOGOUT);
  }

  execute() {
    return super.connect(null);
  }
}
