import { BaseAPI, API } from './base-api';
import Utils from '../utils/common-utils';
const os = require("os");

export default class LoginAPI extends BaseAPI {

  constructor() {
    super(API.LOGIN);
  }

  execute(username, password, otp, otpContactMethod) {

    const machineId = Utils.getMachineId();
    const payload = {
      email: username,
      password: password,
      udi: machineId,
      model_name: os.platform(),
    };

    if (otp) {
      payload.otp = otp;
    }

    if (otpContactMethod) {
      payload.otp_contact_method = otpContactMethod;
    }

    return super.connect(payload, null, false);
  }
}
