export const FEED_CONVERSATION_LIST = 'FEED_CONVERSATION_LIST';
export const FROM_DB = 'FROM_DB';
export const FROM_API = 'FROM_API';
export const FROM_ANYTHING = 'FROM_ANYTHING';
export const FROM_ANYTHING_AND_RELOAD = 'FROM_ANYTHING_AND_RELOAD';
export const SET_BULK_REQUEST = 'SET_BULK_REQUEST';
export const UPDATE_BULK_REQUEST = 'UPDATE_BULK_REQUEST';
export const REQUEST_CONVERSATIONS = 'REQUEST_CONVERSATIONS';
export const REFRESH_CONVERSTIONS = 'REFRESH_CONVERSTIONS';
export const UPDATE_CONVERSATIONS = 'UPDATE_CONVERSATIONS';
export const DELETE_CONVERSATIONS = 'DELETE_CONVERSATIONS';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const RELOAD_CONVERSATIONS = 'RELOAD_CONVERSATIONS';
export const SET_SELETED_CONVERSATIONS = 'SET_SELETED_CONVERSATIONS';
export const SET_MENU_ACTIONS = 'SET_MENU_ACTIONS';
export const SET_CURRENT_SELECTION = 'SET_CURRENT_SELECTION';
export const SET_ACTIONS_SUPPORTED = 'SET_ACTIONS_SUPPORTED';
export const SET_UNDO_STATE = 'SET_UNDO_STATE';
export const ADD_TO_UNDO_STATE = 'ADD_TO_UNDO_STATE';
export const MARK_UNDO_STATE_DIRTY = 'MARK_UNDO_STATE_DIRTY';
export const REMOVE_FROM_UNDO_STATE = 'REMOVE_FROM_UNDO_STATE';
export const CLEAR_UNDO_ACTION = 'CLEAR_UNDO_ACTION';
export const SEARCH_INITIALIZE = 'SEARCH_INITIALIZE';
export const SEARCH_SUGGESTIONS = 'SEARCH_SUGGESTIONS';
export const SET_SELECTED_SEARCH_ACCOUNT = 'SET_SELECTED_SEARCH_ACCOUNT';
export const CLEAR_LIST = 'CLEAR_LIST';
export const SET_FILTER = 'SET_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const REQUEST_MORE_CONVERSATIONS = 'REQUEST_MORE_CONVERSATIONS';
export const REFRESH_LIST_VIEW = 'REFRESH_LIST_VIEW';
export const RESET_FLUSH_FLAG = 'RESET_FLUSH_FLAG';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const SET_UNDO_TOAST_EXTRAS = 'SET_UNDO_TOAST_EXTRAS';
export const REMOVE_UNDO_TOAST_EXTRAS = 'REMOVE_UNDO_TOAST_EXTRAS';
export const CLEAR_ACTIVE_UNDO_TOAST_EXTRAS = 'CLEAR_ACTIVE_UNDO_TOAST_EXTRAS';
export const UNDO_SEND_MESSAGE = 'undo_send_message';
export const RESET_UNDO_SEND_MESSAGE = 'reset_undo_send_message';
export const RESCHEDULE_SNOOZE_NOTIFICATIONS = 'RESCHEDULE_SNOOZE_NOTIFICATIONS';
export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export const REMOVE_CONVERSATIONS = 'REMOVE_CONVERSATIONS';
export const SHOW_WAIT_LOADER = 'SHOW_WAIT_LOADER';
export const CACHE_CONVERSATION_CHANGES = 'CACHE_CONVERSATION_CHANGES';
export const APPLY_CONVERSATION_CHANGES = 'APPLY_CONVERSATION_CHANGES';
export const CANCEL_EXISTING_TASK = 'CANCEL_EXISTING_TASK';
export const SHOW_INSIGHTS = 'SHOW_INSIGHTS';
export const UPDATE_INSIGHTS = 'UPDATE_INSIGHTS';
export const UPDATE_INSIGHTS_BLUE_DOT = 'UPDATE_INSIGHTS_BLUE_DOT';

export const initSearch = query => ({
  type: SEARCH_INITIALIZE,
  payload: {
    query,
  },
});

export const setSearchSuggestions = suggestions => ({
  type: SEARCH_SUGGESTIONS,
  payload: {
    suggestions,
  },
});

export const setSelectedSearchAccount = selectedSearchAccount => ({
  type: SET_SELECTED_SEARCH_ACCOUNT,
  payload: {
    selectedSearchAccount,
  },
});

export const clearConversationList = () => ({
  type: CLEAR_LIST,
});

export const clearSearchResult = () => ({
  type: CLEAR_SEARCH_RESULT,
});

export const feedConversationList = (cancelExistingTasks = false) => ({
  type: FEED_CONVERSATION_LIST,
  category: FROM_ANYTHING,
  cancelExistingTasks,
});

export const feedConversationListFromDB = (cache = false) => ({
  type: FEED_CONVERSATION_LIST,
  category: FROM_DB,
  cache,
});

export const feedConversationListFromAPI = (cache = false) => ({
  type: FEED_CONVERSATION_LIST,
  category: FROM_API,
  cache,
});

export const feedConversationListAndReload = () => ({
  type: FEED_CONVERSATION_LIST,
  category: FROM_ANYTHING_AND_RELOAD,
  cancelExistingTasks: true,
});

export const setBulkRequest = requests => ({
  type: SET_BULK_REQUEST,
  payload: {
    requests,
  },
});

export const updateBulkRequest = request => ({
  type: UPDATE_BULK_REQUEST,
  payload: {
    request,
  },
});

export const requestConversations = (isFetching = true) => ({
  type: REQUEST_CONVERSATIONS,
  payload: {
    isFetching,
  },
});

export const deleteConversations = conversations => ({
  type: REFRESH_CONVERSTIONS,
  payload: {
    category: DELETE_CONVERSATIONS,
    conversationChanges: conversations,
  },
});

export const removeConversations = conversations => ({
  type: REMOVE_CONVERSATIONS,
  payload: {
    conversations,
  },
});

export const updateConversations = (conversations, fromAction = false) => ({
  type: REFRESH_CONVERSTIONS,
  payload: {
    category: UPDATE_CONVERSATIONS,
    conversationChanges: conversations,
    fromAction,
  },
});

export const setConversations = (conversations, isFromSync = false) => ({
  type: SET_CONVERSATIONS,
  payload: {
    conversations,
    isFromSync,
  },
});

export const reloadConversations = () => ({
  type: FEED_CONVERSATION_LIST,
  category: RELOAD_CONVERSATIONS,
});

export const setActionsSupported = actionsSupported => ({
  type: SET_ACTIONS_SUPPORTED,
  payload: {
    actionsSupported,
  },
});

export const setCurrentSelection = (conversation, index) => ({
  type: SET_CURRENT_SELECTION,
  payload: {
    conversation,
    index,
  },
});

export const setMenuActions = actions => ({
  type: SET_MENU_ACTIONS,
  actions,
});

export const setSelectedConversations = conversations => ({
  type: SET_SELETED_CONVERSATIONS,
  payload: {
    selectedConversations: conversations,
  },
});

export const addToUndoState = payload => ({
  type: ADD_TO_UNDO_STATE,
  payload,
});

export const markUndoStateDirty = () => ({
  type: MARK_UNDO_STATE_DIRTY,
});

export const removeFromUndoState = actionId => ({
  type: REMOVE_FROM_UNDO_STATE,
  actionId,
});

export const clearUndoAction = () => ({
  type: CLEAR_UNDO_ACTION,
});

export const setUndoState = undoState => ({
  type: SET_UNDO_STATE,
  payload: {
    undoState,
  },
});

export const setFilter = filter => ({
  type: SET_FILTER,
  filter,
});

export const updateFilter = filter => ({
  type: UPDATE_FILTER,
  payload: {
    filter,
  },
});

export const requestMoreFromUI = () => ({
  type: REQUEST_MORE_CONVERSATIONS,
});

export const refreshView = refresh => ({
  type: REFRESH_LIST_VIEW,
  refreshView: refresh,
});

export const resetFlushFlag = () => ({
  type: RESET_FLUSH_FLAG,
});

export const updateError = error => ({
  type: UPDATE_ERROR,
  error,
});

export const setUndoToastExtras = extras => ({
  type: SET_UNDO_TOAST_EXTRAS,
  extras,
});

export const removeUndoToastExtras = id => ({
  type: REMOVE_UNDO_TOAST_EXTRAS,
  id,
});

export const clearActiveUndoToastExtras = () => ({
  type: CLEAR_ACTIVE_UNDO_TOAST_EXTRAS,
});

export const setUndoSendMessage = (
  outboxActionType, message, outboxMessageFromServer,
  referenceConversation, referenceMessage, referenceFolder,
) =>
  ({
    type: UNDO_SEND_MESSAGE,
    payload: {
      outboxActionType,
      message,
      outboxMessageFromServer,
      referenceConversation,
      referenceMessage,
      referenceFolder,
    },
  });

export const resetUndoSendInfo = () => ({
  type: RESET_UNDO_SEND_MESSAGE,
});

export const reScheduleSnoozeNotifications = rescheduleType => ({
  type: RESCHEDULE_SNOOZE_NOTIFICATIONS,
  rescheduleType,
});

export const showWaitLoader = show => ({
  type: SHOW_WAIT_LOADER,
  payload: {
    showWaitLoader: show,
  },
});

export const cacheConversationChanges = changes => ({
  type: CACHE_CONVERSATION_CHANGES,
  payload: {
    changes,
  },
});

export const applyConversationChanges = () => ({
  type: APPLY_CONVERSATION_CHANGES,
});

export const cancelExistingTask = () => ({
  type: FEED_CONVERSATION_LIST,
  category: CANCEL_EXISTING_TASK,
  cancelExistingTasks: true,
});

export const showAccountInsights = showInsights => ({
  type: SHOW_INSIGHTS,
  showInsights,
});

export const updateInsights = showInsights => ({
  type: UPDATE_INSIGHTS,
  showInsights,
});

export const updateNewInsights = newInsights => ({
  type: UPDATE_INSIGHTS_BLUE_DOT,
  newInsights,
});
