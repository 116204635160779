import { BaseAPI, API } from './base-api';

export default class ResetPasswordAPI extends BaseAPI {
  constructor() {
    super(API.RESET_PASSWORD);
  }

  execute(username) {
    const postParams = { email: username };
    return super.connect(postParams, null, false);
  }
}
