// import DBWrapper from '../data/db-wrapper';
// import Folder from '../data/entities/folder';
// import Logger from './logger';
// import Filter from '../data/entities/filter';

// const { app, remote } = require('electron');
// const path = require('path');
// const fs = require('fs');

let singleton;
const singletonEnforcer = Symbol('Singleton for account preferences');

const parseDataFile = (defaults) => {
  try {
    const settings = localStorage.getItem('AccountPreferences');
    if (settings)
      return JSON.parse(settings);
    return {};
  } catch (error) {
    return defaults;
  }
};

class AccountPreferences {
  constructor(opts) {
    this.data = parseDataFile(opts.defaults);
  }

  get(key, defaultValue) {
    if (this.contains(key)) {
      return this.data[key];
    }
    return defaultValue;
  }

  save(settings) {
    localStorage.setItem('AccountPreferences', JSON.stringify(settings))
  }

  set(key, value) {
    this.data[key] = value;
    this.save(this.data);
  }

  remove(key) {
    delete this.data[key];
    this.save(this.data);
  }

  removeAll() {
    this.data = {};
    this.save(this.data);
  }

  contains(key) {
    return Object.prototype.hasOwnProperty.call(this.data, key);
  }

  parseDataFile() {
    this.data = parseDataFile({});
  }
}

export default class AccountSettingsPreferences {

  static get TYPE_ARCHIVE_FOLDER_ID() { return 'archive_folder'; }
  static get TYPE_DELETE_FOLDER_ID() { return 'delete_folder'; }
  static get TYPE_SPAM_FOLDER_ID() { return 'spam_folder'; }
  static get TYPE_ARCHIVE_FOLDER_NAME() { return 'archive_folder_name'; }
  static get TYPE_DELETE_FOLDER_NAME() { return 'delete_folder_name'; }
  static get TYPE_SPAM_FOLDER_NAME() { return 'spam_folder_name'; }
  static get TYPE_SENT_FOLDER_LABEL() { return 'sent_folder_label'; }
  static get TYPE_SENT_FOLDER_MAILBOX_PATH() { return 'sent_folder_mailbox_path'; }
  static get TYPE_SENT_FOLDER_NAME() { return 'sent_folder_name'; }
  static get TYPE_SENT_FOLDER_ID() { return 'sent_folder'; }
  static get TYPE_DRAFT_FOLDER_ID() { return 'draft_folder'; }
  static get TYPE_DRAFT_FOLDER_LABEL() { return 'draft_folder_label'; }
  static get TYPE_DRAFT_FOLDER_MAILBOX_PATH() { return 'draft_folder_mailbox_path'; }
  static get TYPE_DRAFT_FOLDER_NAME() { return 'draft_folder_name'; }
  static get TYPE_SIGNATURE() { return 'signature'; }
  static get TYPE_HTML_SIGNATURE() { return 'html_signature'; }
  static get TYPE_SIGNATURE_ATTACHMENT() { return 'html_signature_attachment'; }
  static get TYPE_NAME() { return 'nickname'; }
  static get TYPE_DEFAULT_NAME() { return 'default_nick_name'; }
  static get TYPE_USE_DEFAULT_SIGNATURE_FOR_ALIAS() { return 'use_default_signature_for_alias'; }
  static get TYPE_ALIAS_REPLY_FROM() { return 'reply_using_default_sending_address'; }
  static get TYPE_DEFAULT_ALIAS() { return 'default_sending_address'; }
  static get TYPE_NEW_MAIL() { return 'new_mail'; }
  static get TYPE_NOTIFIABLE_FOLDER() { return 'notifiable_folder'; }
  static get TYPE_IS_FIRST_SENT_FROM_CLOUDMAGIC() { return 'is_first_sent_from_cloudmagic'; }
  static get TYPE_SENT_FROM_CLOUDMAGIC() { return 'sent_using_cm'; }
  static get TYPE_AUTO_DOWNLOAD_ATTACHMENTS() { return 'auto_attachment_download'; }
  static get TYPE_COLOR() { return 'color'; }
  static get TYPE_MAX_ATTACHMENT_SIZE_PER_EMAIL() { return 'max_attachment_size_per_email'; }
  static get TYPE_LAST_INBOX_REQ_TS() { return 'inbox_list_ts'; }
  static get TYPE_LAST_INBOX_UNREAD_REQ_TS() { return 'inbox_list_unread_ts'; }
  static get TYPE_LAST_INBOX_STAR_REQ_TS() { return 'inbox_list_star_ts'; }
  static get TYPE_FOLDER_SYNC_TIP_VISIT_TS() { return 'folder_sync_tip_visit_ts'; }

  static get TYPE_FOCUSED_INBOX_FOLDERS() { return 'focused_inbox_folders'; }
  static get TYPE_FOCUSED_INBOX_BANNER_INFO() { return 'focused_inbox_banner_info'; }
  static get TYPE_FOCUSED_INBOX_STATUS() { return 'focused_inbox_status'; }
  static get TYPE_LAST_YELLOW_NOTIFICATION_API_CALL() { return 'last_yellow_notification_api_call'; }
  static get TYPE_AUTO_BCC_EMAILS() { return 'list_of_auto_bcc_emails'; }
  static get TYPE_SHOW_SIGNATURE_ON_REPLY() { return 'show_signature_on_reply'; }

  // This is deprecated
  static get TYPE_SHOULD_REMOVE_SIGNATURE_ON_REPLY() { return 'should_remove_signature_on_reply'; }

  static get SHOW_SIGNATURE_FOR_FIRST_MAIL() { return 1; }
  static get SHOW_SIGNATURE_FOR_FIRST_MAIL_REST_NICKNAME() { return 2; }
  static get SHOW_SIGNATURE_FOR_ALL_MAILS() { return 3; }

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');

    this.accountPreferences = new AccountPreferences({
      configName: 'account-settings-preferences',
      defaults: {
      },
    });
  }

  static getInstance() {
    if (!singleton) {
      singleton = new AccountSettingsPreferences(singletonEnforcer);
    }

    return singleton;
  }

  get(key) {
    return this.accountPreferences.get(key);
  }

  set(key, value) {
    this.accountPreferences.set(key, value);
  }

  hasKey(key) {
    return this.accountPreferences.contains(key);
  }

  static getPreferenceKey(accountId, preferenceType) {
    return `${accountId}_${preferenceType}`;
  }

  static getAliasPreferenceKey(accountId, aliasEmail, preferenceType) {
    return `${accountId}_${aliasEmail}_${preferenceType}`;
  }

  // getDraftFolderId(key) {
  //   return this.accountPreferences.get(key, Folder.INVALID_FOLDER_ID);
  // }

  // async getSpamFolderId(accountId, key, dbWrapper) {
  //   if (!this.accountPreferences.contains(key)) {
  //     const isDbWrapperPassed = !!dbWrapper;
  //     try {
  //       if (!isDbWrapperPassed) {
  //         dbWrapper = new DBWrapper();
  //       }
  //       let folder = await dbWrapper.getFolderUsingFolderType(
  //         Folder.FOLDER_TYPE_JUNK, accountId);
  //       if (!folder) {
  //         folder = await dbWrapper.getFolderUsingFolderType(
  //           Folder.FOLDER_TYPE_ALLMAIL, accountId);
  //       }
  //       let folderId = Folder.INVALID_FOLDER_ID;
  //       if (folder) {
  //         folderId = folder.id;
  //       }
  //       this.accountPreferences.set(key, folderId);
  //     } catch (error) {
  //       Logger.error(error);
  //     } finally {
  //       if (!isDbWrapperPassed && dbWrapper) {
  //         dbWrapper.close();
  //       }
  //     }
  //   }
  //   return this.accountPreferences.get(key, Folder.INVALID_FOLDER_ID);
  // }

  // async getDeleteFolderId(accountId, key, dbWrapper) {
  //   if (!this.accountPreferences.contains(key)) {
  //     const isDbWrapperPassed = !!dbWrapper;
  //     try {
  //       if (!isDbWrapperPassed) {
  //         dbWrapper = new DBWrapper();
  //       }
  //       const folder = await dbWrapper.getFolderUsingFolderType(
  //         Folder.FOLDER_TYPE_DELETED, accountId);
  //       let folderId = Folder.INVALID_FOLDER_ID;
  //       if (folder) {
  //         folderId = folder.id;
  //       }
  //       this.accountPreferences.set(key, folderId);
  //       return folderId;
  //     } catch (err) {
  //       Logger.error(err);
  //     } finally {
  //       if (!isDbWrapperPassed && dbWrapper) {
  //         dbWrapper.close();
  //       }
  //     }
  //   }
  //   return this.accountPreferences.get(key, Folder.INVALID_FOLDER_ID);
  // }

  // async getDeleteFolderName(accountId, key, dbWrapper) {
  //   if (!this.accountPreferences.contains(key)) {
  //     const isDbWrapperPassed = !!dbWrapper;
  //     try {
  //       if (!isDbWrapperPassed) {
  //         dbWrapper = new DBWrapper();
  //       }
  //       const folder = await dbWrapper.getFolderUsingFolderType(
  //         Folder.FOLDER_TYPE_DELETED, accountId);
  //       let folderName = '';
  //       if (folder) {
  //         folderName = folder.name;
  //       }
  //       this.accountPreferences.set(key, folderName);
  //       return folderName;
  //     } catch (err) {
  //       Logger.error(err);
  //     } finally {
  //       if (!isDbWrapperPassed && dbWrapper) {
  //         dbWrapper.close();
  //       }
  //     }
  //   }
  //   return this.accountPreferences.get(key, '');
  // }

  // getArchiveFolderIdFromPreference(key) {
  //   return this.accountPreferences.get(key, Folder.INVALID_FOLDER_ID);
  // }

  // getSpamFolderIdFromPreference(key) {
  //   return this.accountPreferences.get(key, Folder.INVALID_FOLDER_ID);
  // }

  // getDeleteFolderIdFromPreference(key) {
  //   return this.accountPreferences.get(key, Folder.INVALID_FOLDER_ID);
  // }

  // async getArchiveFolderId(accountId, key, dbWrapper) {
  //   if (!this.accountPreferences.contains(key)) {

  //     const isDbWrapperPassed = !!dbWrapper;
  //     try {
  //       if (!isDbWrapperPassed) {
  //         dbWrapper = new DBWrapper();
  //       }
  //       let folder = await dbWrapper.getFolderUsingFolderType(
  //         Folder.FOLDER_TYPE_ARCHIVE, accountId);
  //       if (!folder) {
  //         folder = await dbWrapper.getFolderUsingFolderType(Folder.FOLDER_TYPE_ALLMAIL, accountId);
  //       }
  //       let folderId = Folder.INVALID_FOLDER_ID;
  //       if (folder) {
  //         folderId = folder.id;
  //       }
  //       this.accountPreferences.set(key, folderId);
  //       return folderId;
  //     } catch (err) {
  //       Logger.error(err);
  //     } finally {
  //       if (!isDbWrapperPassed && dbWrapper) {
  //         dbWrapper.close();
  //       }
  //     }
  //   }
  //   return this.getArchiveFolderIdFromPreference(key);
  // }

  // async getArchiveFolderName(accountId, key, dbWrapper) {
  //   if (!this.accountPreferences.contains(key)) {
  //     const isDbWrapperPassed = !!dbWrapper;
  //     try {
  //       if (!isDbWrapperPassed) {
  //         dbWrapper = new DBWrapper();
  //       }
  //       const folder = await dbWrapper.getFolderUsingFolderType(
  //         Folder.FOLDER_TYPE_ARCHIVE, accountId);
  //       let archiveFolderName = '';
  //       if (folder) {
  //         archiveFolderName = folder.name;
  //       }
  //       this.accountPreferences.set(key, archiveFolderName);
  //       return archiveFolderName;
  //     } catch (err) {
  //       Logger.error(err);
  //     } finally {
  //       if (!isDbWrapperPassed && dbWrapper) {
  //         dbWrapper.close();
  //       }
  //     }
  //   }
  //   return this.accountPreferences.get(key, '');
  // }

  setFolderId(key, id) {
    this.accountPreferences.set(key, id);
  }

  setFolderName(key, name) {
    this.accountPreferences.set(key, name);
  }

  getSignature(key) {
    return this.accountPreferences.get(key, '');
  }

  getNickName(key) {
    return this.accountPreferences.get(key, '');
  }

  getDefaultNickName(key) {
    return this.accountPreferences.get(key, '');
  }

  setDefaultNickName(key, name) {
    this.accountPreferences.set(key, name);
  }

  removeAliasPreferences(accountId, aliasEmail) {
    const signatureKey = AccountSettingsPreferences.getAliasPreferenceKey(accountId, aliasEmail,
      AccountSettingsPreferences.TYPE_HTML_SIGNATURE);
    this.accountPreferences.remove(signatureKey);

    const signatureAttachmentKey = AccountSettingsPreferences.getAliasPreferenceKey(accountId, aliasEmail,
      AccountSettingsPreferences.TYPE_SIGNATURE_ATTACHMENT);
    this.accountPreferences.remove(signatureAttachmentKey);
  }


  setUseDefaultSignatureForAlias(key, value) {
    this.accountPreferences.set(key, value);
  }

  getUseDefaultSignatureForAlias(key) {
    return this.accountPreferences.get(key, false);
  }

  getSignatureAttachment(key) {
    return this.accountPreferences.get(key, '');
  }

  getDefaultAlias(key) {
    return this.accountPreferences.get(key, '');
  }

  getAliasReplyFromDefaultAddress(key) {
    return this.accountPreferences.get(key, false);
  }

  getNotification(key) {
    return this.accountPreferences.get(key, false);
  }

  getNewMailNotification(key) {
    return this.accountPreferences.get(key, true);
  }

  static getFolderSettingPreferenceKey(accountId, mailboxPath, label, preferenceType) {
    return `${accountId}_${mailboxPath}_${label}_${preferenceType}`;
  }

  getFirstSentUsingCloudMagicSetting(key) {
    return this.accountPreferences.get(key, false);
  }

  getSentUsingCloudMagic(key) {
    return this.accountPreferences.get(key, true);
  }

  getMaxAttachmentSizePerEmail(userAccount) {
    if (userAccount) {
      const key = AccountSettingsPreferences.getPreferenceKey(userAccount.accountId, AccountSettingsPreferences.TYPE_MAX_ATTACHMENT_SIZE_PER_EMAIL);
      return this.accountPreferences.get(key, userAccount.maxAttachmentSizePerEmail);
    } else {
      return 0;
    }
  }

  setFolderSyncTipVisitTS(key, ts) {
    this.accountPreferences.set(key, ts);
  }

  getFolderSyncTipVisitTS(key) {
    return this.accountPreferences.get(key, 0);
  }

  getFocusedInboxFolders(key) {
    return this.accountPreferences.get(key, '[]');
  }

  getFocusedInboxStatus(key) {
    return this.accountPreferences.get(key, '');
  }

  // getFocusedInboxBannerInfo(key) {
  //   return this.accountPreferences.get(key, Folder.INVALID_FOLDER_ID);
  // }

  getLastYellowNotificationApiTS(key) {
    return this.accountPreferences.get(key, 0);
  }

  removeKey(key) {
    this.accountPreferences.remove(key);
  }

  isPreferenceKeyExist(key) {
    if (this.accountPreferences.contains(key)) {
      return true;
    }

    return false;
  }

  // setLastInboxListTime(accountId, filterType, time) {
  //   let key = null;

  //   switch (filterType) {
  //     case Filter.FILTER_NONE:
  //       key = AccountSettingsPreferences.getPreferenceKey(accountId, AccountSettingsPreferences.TYPE_LAST_INBOX_REQ_TS);
  //       break;

  //     case Filter.FILTER_UNREAD:
  //       key = AccountSettingsPreferences.getPreferenceKey(accountId, AccountSettingsPreferences.TYPE_LAST_INBOX_UNREAD_REQ_TS);
  //       break;

  //     case Filter.FILTER_STARRED:
  //       key = AccountSettingsPreferences.getPreferenceKey(accountId, AccountSettingsPreferences.TYPE_LAST_INBOX_STAR_REQ_TS);
  //       break;
  //     default:
  //       break;
  //   }

  //   if (key === null) {
  //     return;
  //   }

  //   this.set(key, time);
  // }

  setAutoBccEmails(key, jsonData) {
    this.accountPreferences.set(key, jsonData);
  }

  getAutoBccEmails(key) {
    return this.accountPreferences.get(key, 0);
  }

  getSignatureStateOnReply(key) {
    return this.accountPreferences.get(key, AccountSettingsPreferences.SHOW_SIGNATURE_FOR_ALL_MAILS);
  }

  getAllKeysForAccount(id) {
    const keys = [];
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_NEW_MAIL));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SIGNATURE));
    //keys.push(AccountSettingsPreferences.getPreferenceKey(id, TYPE_RINGTONE));
    //keys.push(AccountSettingsPreferences.getPreferenceKey(id, TYPE_VIBRATE));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_NAME));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_DEFAULT_NAME));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_IS_FIRST_SENT_FROM_CLOUDMAGIC));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SENT_FROM_CLOUDMAGIC));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_ARCHIVE_FOLDER_ID));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_DELETE_FOLDER_ID));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_ARCHIVE_FOLDER_NAME));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_DELETE_FOLDER_NAME));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_LAST_INBOX_REQ_TS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_LAST_INBOX_UNREAD_REQ_TS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_LAST_INBOX_STAR_REQ_TS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_HTML_SIGNATURE));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SIGNATURE_ATTACHMENT));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_ALIAS_REPLY_FROM));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_DEFAULT_ALIAS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SPAM_FOLDER_ID));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SPAM_FOLDER_NAME));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_MAX_ATTACHMENT_SIZE_PER_EMAIL));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SENT_FOLDER_LABEL));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SENT_FOLDER_MAILBOX_PATH));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SENT_FOLDER_NAME));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SENT_FOLDER_ID));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_DRAFT_FOLDER_ID));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_DRAFT_FOLDER_LABEL));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_DRAFT_FOLDER_MAILBOX_PATH));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_DRAFT_FOLDER_NAME));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_FOLDER_SYNC_TIP_VISIT_TS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_FOCUSED_INBOX_FOLDERS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_FOCUSED_INBOX_BANNER_INFO));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_FOCUSED_INBOX_STATUS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_LAST_YELLOW_NOTIFICATION_API_CALL));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_AUTO_DOWNLOAD_ATTACHMENTS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_USE_DEFAULT_SIGNATURE_FOR_ALIAS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_AUTO_BCC_EMAILS));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SHOULD_REMOVE_SIGNATURE_ON_REPLY));
    keys.push(AccountSettingsPreferences.getPreferenceKey(id, AccountSettingsPreferences.TYPE_SHOW_SIGNATURE_ON_REPLY));

    return keys;
  }

  clearPreferences(accountId) {
    const keys = this.getAllKeysForAccount(accountId);
    for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
      this.accountPreferences.remove(keys[keyIndex]);
    }
  }

  clearAccountPreferencesOnLogout() {
    this.accountPreferences.removeAll();
  }

  parseDataFile() {
    this.accountPreferences.parseDataFile();
  }
}
