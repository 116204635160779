import * as Constants from '../helper/constant';

export default class APIError {
  static get ERROR_SHOW_INFO_MESSSAGE() {
    return 0;
  }

  static get ERROR_SHOW_CRITICAL_MESSAGE() {
    return 1;
  }
  static get ERROR_SHOW_EMBEDDED_MESSAGE() {
    return 2;
  }

  static get MESSAGE_SOURCE_API() {
    return 21;
  }

  static get MESSAGE_SOURCE_DEFAULT() {
    return 22;
  }

  setRawResponse(response) {
    this.jsonResponse = response;
  }

  getRawResponse() {
    return this.jsonResponse;
  }

  setErrorMessage(errorMessage) {
    this.errorMessage = errorMessage;
  }

  getErrorMessage() {
    return this.errorMessage;
  }

  setErrorType(errorType) {
    this.errorType = errorType;
  }

  getErrorType() {
    return this.errorType;
  }

  setErrorCode(errorCode) {
    this.errorCode = errorCode;
  }

  getErrorCode() {
    return this.errorCode;
  }

  static getDummyError(errorCode, errorMessage = 'Something went wrong. Please try again later') {
    const apiError = new APIError();
    apiError.setErrorCode(errorCode);
    apiError.setErrorMessage(errorMessage);
    apiError.setErrorType(APIError.ERROR_SHOW_INFO_MESSSAGE);
    return apiError;
  }

  static getDatabaseError() {
    const error = new APIError();
    error.errorMessage = 'Database error';
    error.errorCode = Constants.DBERROR_SQLITEEXCEPTION;
    error.errorMessageSource = APIError.MESSAGE_SOURCE_DEFAULT;
    error.errorType = APIError.ERROR_SHOW_INFO_MESSSAGE;
    return error;
  }

  static getThreadError() {
    const error = new APIError();
    error.errorMessage = 'Thread error';
    error.errorCode = Constants.THREADERROR_INTERRUPTEDEXCEPTION;
    error.errorMessageSource = APIError.MESSAGE_SOURCE_DEFAULT;
    error.errorType = APIError.ERROR_SHOW_INFO_MESSSAGE;
    return error;
  }

  static getSyncError() {
    const error = new APIError();
    error.errorMessage = 'Sync error';
    error.errorCode = Constants.SYNCERROR;
    error.errorMessageSource = APIError.MESSAGE_SOURCE_DEFAULT;
    error.errorType = APIError.ERROR_SHOW_INFO_MESSSAGE;
    return error;
  }
}
