import React, { Component } from 'react';
import DayPicker from 'react-day-picker';
import './LeftSidebar.scss';

export class LeftSidebar extends Component {
  render() {
    return (
      <div className={'left-side-bar'}>
        <div className={'calendar-block'}>
          <div className={'react-calendar-no'}>
            <DayPicker
              disabledDays={{
                before: new Date()
              }}
              // selectedDays={startDateValue}
              onDayClick={this.onChangeStartDate}
            />
          </div>
        </div>
        <div className={'calander-event-list-block'}>
          <h5 className='calendar-event-title'>Today</h5>
          <div className={'calander-even-list'}>
            <h4 className='title'>Sanjay + Lucia + Paul</h4>
            <p className='event-time'>9:30 AM - 10:00 AM</p>
          </div>
          <div className={'calander-even-list orange-list'}>
            <h4 className='title'>Sanjay + Lucia + Paul</h4>
            <p className='event-time'>9:30 AM - 10:00 AM</p>
          </div>
          <h5 className='calendar-event-title'>Tomorrow</h5>
          <div className={'calander-even-list'}>
            <h4 className='title'>Sanjay + Lucia + Paul</h4>
            <p className='event-time'>9:30 AM - 10:00 AM</p>
          </div>
        </div>
      </div>
    );
  }
}

export default LeftSidebar;
