import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './../../components/Header/Header';
import FullCalendearUi from './../../components/FullCalendarUi/FullCalendarUi';
import Drawer from '@material-ui/core/Drawer';
import AddEvent from '../AddEvent';
import '../Home/style.scss';
import Loading from '../../components/Loading/Loading';
import _ from 'lodash';
import LeftSidebar from '../../components/LeftSidebar/LeftSidebar';
import ProfileDrawer from './ProfileDrawer';
import SelectAvailabilityDarawer from './SelectAvailabilityDarawer';
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddEventDrawer: false,
      isOpenprofileDrawer: false,
      isOpenCalendarMenu: false,
      eventDates: ''
    };
  }
  toogleDrawer = () => {
    const { isAddEventDrawer } = this.state;
    this.setState({ isAddEventDrawer: !isAddEventDrawer });
  };
  toogleProfileDrawer = () => {
    const { isOpenprofileDrawer } = this.state;
    this.setState({ isOpenprofileDrawer: !isOpenprofileDrawer });
  };
  triggerEvent(eventname, data) {
    var event = new CustomEvent(eventname, {
      detail: data
    });
    document.dispatchEvent(event);
  }
  setEventDate = (eventDates) => {
    this.setState({ eventDates, eventInfo: undefined }, () => {
      this.toogleDrawer(true);
    });
  };

  onViewChange = (value) => {
    this.calender.onViewChange(value);
  };

  onEventClicked = (event) => {
    this.setState({ eventInfo: event, eventDates: undefined }, () => {
      this.toogleDrawer(true);
    });
  };

  onPressAddEventDrawer = () => {
    const { isAddEventDrawer } = this.state;
    this.setState({ isAddEventDrawer: !isAddEventDrawer });
    // const { toogleDrawer } = this.props;
    // this.toogleDrawer(isAddEventDrawer);
  };

  toggleAvailabilityDrawer = () => {
    const { isOpenCalendarMenu: isOpenAvailabilityDrawer } = this.state;
    this.setState({ isOpenCalendarMenu: !isOpenAvailabilityDrawer });
  };

  render() {
    const { isAddEventDrawer, isOpenprofileDrawer, isOpenCalendarMenu } = this.state;
    const { eventListToDisplay, loading } = this.props.calendar;
    if (loading || _.isEmpty(eventListToDisplay)) {
      return <Loading />;
    }
    return (
      <div className={`wrapper ${isAddEventDrawer ? 'drawer' : ''}`}>
        <Header
          toogleProfileDrawer={this.toogleProfileDrawer}
          toogleDrawer={this.toogleDrawer}
          onViewChange={this.onViewChange}
        />
        <div className={'content-block'}>
          <LeftSidebar />

          <FullCalendearUi
            ref={(value) => (this.calender = value)}
            toogleDrawer={this.toogleDrawer}
            setEventDate={this.setEventDate}
            events={eventListToDisplay}
            onEventClicked={this.onEventClicked}
          />
        </div>
        <div className={'fixed-widget'}>
          <button className={'action-bttn fixed-btn-calander'} type='button' onClick={this.toggleAvailabilityDrawer}>
            <svg width='24' height='24' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284
       15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z'
                stroke='#ffffff'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path d='M12 1.5V4.5' stroke='#ffffff' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
              <path d='M6 1.5V4.5' stroke='#ffffff' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
              <path
                d='M2.25 7.5H15.75'
                stroke='#ffffff'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>
          <button className={'action-bttn fixed-btn-add'} type='button' onClick={this.onPressAddEventDrawer}>
            <svg width='24' height='24' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M9 3.75V14.25' stroke='#ffffff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
              <path d='M3.75 9H14.25' stroke='#ffffff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </button>
        </div>
        <Drawer variant='temporary' anchor='left' open={isOpenprofileDrawer} onClose={this.toogleProfileDrawer}>
          <ProfileDrawer toogleProfileDrawer={this.toogleProfileDrawer} />
        </Drawer>
        <Drawer variant='temporary' anchor='right' open={isAddEventDrawer} onClose={this.toogleDrawer}>
          <AddEvent
            toogleDrawer={this.toogleDrawer}
            eventDates={this.state.eventDates}
            eventInfo={this.state.eventInfo}
          />
        </Drawer>
        <Drawer variant='temporary' anchor='right' open={isOpenCalendarMenu} onClose={this.toggleAvailabilityDrawer}>
          <SelectAvailabilityDarawer onClose={this.toggleAvailabilityDrawer} />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calendar: state.calendar
});

export default connect(mapStateToProps)(Home);
