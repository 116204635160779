import { BaseAPI, API } from './base-api';

export default class CreateCalendarEvent extends BaseAPI {
  constructor() {
    super(API.CALENDER_EVENT_CREATE);
  }

  execute(accountId, action_type, action_token, payload) {
    const postParams = {};
    postParams.account_id = accountId.toString();
    postParams.action_type = action_type;
    postParams.action_token = action_token;
    postParams.payload = payload;
    return super.connect(postParams, { action_type });
  }
}
