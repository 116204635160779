import React, { Component } from 'react';
import './style.scss';

class Loader extends Component {
  render() {
    return <div className={`'loader' ${this.props.className || ''}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      style={this.props.style}>
      <div className={'spinner-wrap'}>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
        <div className={'spinner-blade'}></div>
      </div>
    </div>;
  }
}

export default Loader;