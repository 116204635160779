import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../scss/custom.scss';
import logo_1 from '../../../src/assets/images/logo_1.jpg';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import * as Constants from '../../helper/constant';
import { LOGIN } from '../../reducers/login';
import Actions from '../../actions';
import { CircleLoader as Loader } from '../../components/loader';

class Login extends Component {
  constructor(props) {
    super(props);
    const email = UserPreferenceSingleton.getInstance().getEmail();
    this.state = {
      email: email,
      password: ''
    };
  }

  componentDidUpdate() {
    const userType = UserPreferenceSingleton.getInstance().get(UserPreferenceSingleton.CM_USER_TYPE);
    const { login, error } = this.props.login;
    if (login === LOGIN.SUCCESS && userType === Constants.CM_USER_WITH_ACCOUNT) {
      this.props.history.push('/');
    } else if (login === LOGIN.NETWORK_FAILURE) {
      this._showDialog('Please check your internet');
    } else if (login === LOGIN.ERROR) {
      const { errorMessage } = this.state;
      if (error.errorCode === Constants.APIERROR_2_STEP_VERIFICATION_CODE) {
        const hint = encodeURIComponent(error.jsonResponse.data.hint);
        const medium = encodeURIComponent(error.jsonResponse.data.current_contact_method);
        const username = encodeURIComponent(this.state.email.trim());
        const password = encodeURIComponent(this.state.password.trim());
        const params = `hint=${hint}&medium=${medium}&username=${username}&password=${password}`;
        this.props.history.push(`/two-step-verification?${params}`);
      } else if (error.errorMessage !== errorMessage) {
        this._showDialog(error.errorMessage);
      }
    }
  }

  _onClickForgotPassword = () => {
    const username = this.username.value.trim();
    this.props.history.push(`/reset-password?username=${username}`);
  };

  handleSubmit = (e) => {
    console.log('on login click');
    this.setState({errorMessage:''})
    e.preventDefault();
    const { email: username, password } = this.state;
    if (!username || !password) {
      if (!username) {
        this._showDialog('Email address cannot be empty');
      } else if (!password) {
        this._showDialog('Password cannot be empty');
      }
      return;
    }

    this.props.dispatch(Actions.requestUserLogin(username, password));
  };

  _showDialog = (errorMessage) => {
    this.setState({ errorMessage: errorMessage });
  };

  render() {
    const { email, password, errorMessage } = this.state;
    const { login } = this.props.login;
    return (
      <div className={'login-page'}>
        <div className='head'>
          <img src={logo_1} alt="logo" />
          <div className={'text'}>Calender</div>
        </div>
        <div className={'form'}>
          <form className={"login-form"} onSubmit={this.handleSubmit}>
            <input
              type='text'
              placeholder='Username (Email)'
              autoFocus
              autoComplete='username'
              value={email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
                this.setState({errorMessage:''})
              }}
            />
            <input
              type='password'
              placeholder='Password'
              autoComplete='current-password'
              value={password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
                this.setState({errorMessage:''})
              }}
            />
            <button type='submit' disabled={login === LOGIN.LOADER}>
              Sign in
            </button>
            {(() => {
          if (login === LOGIN.LOADER) {
            return (<div>
               <Loader color="#C0C0C0" size="12px" />
            </div>);
          }
          return null;
        })()}
            {errorMessage ? <div>{errorMessage}</div> : null}
          </form>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  login: state.login,
  router: state.router
});

export default connect(mapStateToProps)(Login);