import { put, call } from 'redux-saga/effects';
import Actions from '../actions';
import Api from '../api';
import { BaseAPI } from '../api/base-api';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import Subscription from '../helper/subscription';
import AccountSettingsPreferences from '../helper/account-settings-preferences';
import * as Constants from '../helper/constant';
// import SetRemoteWipeStatusApi from '../api/set-remote-wipe-status-api'

/*import { getLoginState, getLogoutState } from '../selectors';
import { LOGIN } from '../reducers/login';*/
//import SqliteHelper from '../data/SqliteHelper';

export function* login(action) {
  const username = action.payload.username;
  const password = action.payload.password;
  const otp = action.payload.otp;
  const otpContactMethod = action.payload.otpContactMethod;

  try {
    const loginApi = new Api.LoginAPI();
    const response = yield loginApi.execute(username, password, otp, otpContactMethod);
    if (!response.error) {
      Subscription.processSubscriptionInfo(response.response.data.subscription);
      UserPreferenceSingleton.getInstance().set(UserPreferenceSingleton.ACCESS_TOKEN, response.response.data.access_tokens);
      UserPreferenceSingleton.getInstance().set(UserPreferenceSingleton.USER_IDENTIFIER, response.response.data.user_identifier);
      UserPreferenceSingleton.getInstance().storeEmail(username);
      UserPreferenceSingleton.getInstance().set(UserPreferenceSingleton.CM_USER_TYPE, Constants.CM_USER_WITH_ACCOUNT);

      if (Subscription.getSubscriptionStatus() === Subscription.Status.OLD_USER) {
        // TODO: start trial
        yield put({ type: Actions.START_TRIAL });
      } else {
        const subscription = Subscription.getSubscription();
        const userPreference = UserPreferenceSingleton.getInstance();

        if (subscription.trial && userPreference.getTrialPromptDay() > subscription.expiry.days &&
          subscription.expiry.days === subscription.expiry.totalDays) {
          userPreference.setTrialPromptDay(subscription.expiry.days);
        }

        yield put(Actions.onLoginResponse(username, response));
      }
      yield put(Actions.updateSubscription(Subscription.getSubscription()));
    } else {
      yield put(Actions.onLoginFail(username, response.error));
    }
  } catch (e) {
    console.log(`Error occurrecd while logging`);
    console.log(e);
    // Dispatch network failure
    yield put(Actions.onLoginNetworkFail(username));
  }
}

export function* flush(action) {
  console.log("flush initiated");
  yield call(flushCleaner);
}

export function* deleteAllMyData(action) {
  // yield DBWrapper.deleteDatabaseFiles();
}

export function* logout(action) {
  try {
    if (action.sessionExpired) {
      yield call(logoutCleaner, action.remoteWipeInitiated);
    } else {
      const logoutApi = new Api.LogoutApi();
      const response = yield logoutApi.execute();
      if (response.error) {
        yield put(Actions.onLogoutFail(response.error.getErrorMessage()));
      } else {
        yield call(logoutCleaner);
      }
    }
  } catch (error) {
    console.log("error logout", error)
  }

}

export function* resetPassword(action) {
  const username = action.payload.username;

  try {
    const response = yield (new Api.ResetPasswordAPI()).execute(username);

    if (!response.error)
      yield put(Actions.onResetPasswordResponse(username));
    else
      yield put(Actions.onResetPasswordFail(username, response.error));
  } catch (e) {
    yield put(Actions.onResetPasswordNetworkFail(username));
  }
}

export function* logoutCleaner(remoteWipeInitiated = false) {
  console.log("newtonmail:- logoutCleaner calling...")
  try {
    BaseAPI.SESSION_EXPIRED_TRIGGERED = false;
    UserPreferenceSingleton.getInstance().clearStoredUserData(true);

    if (remoteWipeInitiated) {
      const accessTokens = UserPreferenceSingleton.getInstance().get(UserPreferenceSingleton.ACCESS_TOKEN, null);
      UserPreferenceSingleton.getInstance().set(UserPreferenceSingleton.TEMP_ACCESS_TOKEN, accessTokens);
    }

    AccountSettingsPreferences.getInstance().clearAccountPreferencesOnLogout();
    console.log("newtonmail:- logout is successful - directory cleaned");
  } catch (error) {
    console.log("logoutCleaner error", error)
  }
  finally {
    yield put(Actions.onLogoutSuccess());
  }
}

export function* flushCleaner() {
  UserPreferenceSingleton.getInstance().setLastInsightSyncTime(0);

  console.log("flush is successful - directory cleaned");

  yield put(Actions.onFlushSuccess());
}