import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../scss/custom.scss';
import logo_1 from '../../../src/assets/images/logo_1.jpg';
import * as Constants from '../../helper/constant';
import { LOGIN } from '../../reducers/login';
import Actions from '../../actions';
import { CircleLoader } from '../../components/loader';
const OTP_PLACEHOLDER = '-';
const OTPMedium = { CALL: 'call', MESSAGE: 'message', AUTHENTICATOR: 'authenticator' }

class TwoStepVerification extends Component {
  constructor(props) {
    super(props);
    this.index = 0;
    this.state = { value: OTP_PLACEHOLDER.repeat(6).split(''), showCall: false };
    this.timeout = 0;
    this.requestInProgress = false;
  }

  _onKeyDown = (e) => {
    let value = this.state.value;
    console.log('value', value)

    if (e.keyCode === 8) {
      if (this.index > 0) {
        value[--this.index] = OTP_PLACEHOLDER;
        this.setState({ value });
      }
    }
  }

  _onKeyUp = (e) => {
    let value = this.state.value;

    if (e.keyCode === 13)
      this.handleSubmit(e);
    else if (!Number.isNaN(Number.parseInt(e.key))) {
      if (this.index < value.length) {
        value[this.index++] = e.key;
        this.setState({ value });
      }
    }
  }

  _performLogin = (otp, otpMedium) => {
    this.requestInProgress = true;
    this.props.dispatch(Actions.requestUserLogin(decodeURIComponent(this.props.router.location.query.username),
      decodeURIComponent(this.props.router.location.query.password),
      otp, otpMedium));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.index !== this.state.value.length)
      return this._showError('Please enter verification code to continue');

    this._performLogin(this.state.value.join(''));
  }

  _showError = (errorMessage) => {
    this.setState({ errorMessage: errorMessage });
  };

  componentDidUpdate(prevProps) {
    if (this.props.login.login === prevProps.login.login && !this.requestInProgress)
      return;

    if (this.props.login.login !== LOGIN.LOADER)
      this.requestInProgress = false;

    if (this.props.login.login === LOGIN.SUCCESS) {
      this.props.dispatch(Actions.sync());
      this.props.history.push('/');
    } else if (this.props.login.login === LOGIN.NETWORK_FAILURE)
      this._showError('Please check your internet');
    else if (this.props.login.login === LOGIN.ERROR &&
      this.props.login.error.getErrorCode() !== Constants.APIERROR_2_STEP_VERIFICATION_CODE)
      this._showError(this.props.login.error.getErrorMessage());
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
    this.timeout = 0;
  }

  componentDidMount() {
    if (this.props.router.location.query.medium !== OTPMedium.MESSAGE)
      return;

    this.timeout = setTimeout(() => {
      this.timeout = 0;
      this.setState({ showCall: true });
    }, 30000);
  }

  render() {
    console.log('this.props.router', this.props.router,)
    const hint = decodeURIComponent(this.props.router.location.query.hint || 'Enter the verification code');
    let medium = decodeURIComponent(this.props.router.location.query.medium) || OTPMedium.CALL;
    let showCall = medium === OTPMedium.MESSAGE ? this.state.showCall : medium === OTPMedium.CALL;
    const { errorMessage } = this.state;
    const { login } = this.props.login;

    return (
      <div className={'otp-container'}
        tabIndex='1'
        onKeyDown={e => this._onKeyDown(e)}
        onKeyUp={e => this._onKeyUp(e)}>
        <div className='head'>
          <img src={logo_1} alt="logo" />
          <div className={'text'}>Calender</div>
        </div>
        <div className={'form'} >
          <form className={"login-form"} onSubmit={this.handleSubmit}
          >
            <div className={classNames('hint')}>{hint}</div>
            <div className={classNames('value')}>{
              this.state.value.map((v, index) => <div key={index}>{v}</div>)
            }</div>

            <button type='submit' disabled={login === LOGIN.LOADER}>
              Done
            </button>
            <div className={classNames(!showCall && 'hidden')}
              onClick={() => { this._performLogin(null, OTPMedium.CALL) }}>{
                medium === OTPMedium.CALL ? 'Call again' : 'Send by call'
              }</div>
            <div className={classNames(medium !== OTPMedium.MESSAGE && 'hidden')}
              onClick={() => { this._performLogin(null, OTPMedium.MESSAGE) }}>
              Resend code
          </div>
          </form>
          {this.props.login.login === LOGIN.LOADER ?
            <div className={classNames('loader-container')}>
              <CircleLoader className={classNames('loader')} />
            </div> : null
          }
          {errorMessage ? <div>{errorMessage}</div> : null}
        </div>
      </div>
    );
  }
}

TwoStepVerification.propTypes = {
  login: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  login: state.login,
  router: state.router
});

export default connect(mapStateToProps)(TwoStepVerification);