export const FETCH_CALENDER_LIST = 'FETCH_CALENDER_LIST';
export const FETCH_CALENDER_EVENTS = 'FETCH_CALENDER_EVENTS';
export const CALENDER_EVENT_CREATE = 'CALENDER_EVENT_CREATE';
export const UPDATE_CALENDER_LIST = 'UPDATE_CALENDER_LIST';
export const UPDATE_CALENDER_EVENT_LIST = 'UPDATE_CALENDER_EVENT_LIST';
export const SET_CALENDER_LOADER = 'SET_CALENDER_LOADER';
export const CALENDER_ENABLE_SIBLINGS = 'CALENDER_ENABLE_SIBLINGS';
export const CREATE_EVENT_STATUS = 'CREATE_EVENT_STATUS';

export const setCalenderLoader = (payload) => ({
  type: SET_CALENDER_LOADER,
  payload: payload
});

export const updateCalendarList = (list) => ({
  type: UPDATE_CALENDER_LIST,
  payload: { list }
});

export const updateCalendarEventList = (list) => ({
  type: UPDATE_CALENDER_EVENT_LIST,
  payload: { list }
});

export const createCalendarEvent = (event, calendarAccount) => ({
  type: CALENDER_EVENT_CREATE,
  payload: { event, calendarAccount }
});

export const enableSibligns = (accountIds) => ({
  type: CALENDER_ENABLE_SIBLINGS,
  payload: { accountIds }
});

export const createEventStatus = (payload) => ({
  type: CREATE_EVENT_STATUS,
  payload
});
