const containsKey = (obj, key) => ({}.hasOwnProperty.call(obj || {}, key));

const opt = (obj, key, defaultValue = null) => {
  if (containsKey(obj, key)) {
    return obj[key];
  }
  return defaultValue;
};

const optString = (obj, key, defaultValue = '') => opt(obj, key, defaultValue);

const optNumber = (obj, key, defaultValue = 0) => Number(opt(obj, key, defaultValue));

const optJSONArray = (obj, key) => opt(obj, key, null);

const exports = { containsKey, opt, optString, optNumber, optJSONArray };
export default exports;
