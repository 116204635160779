import { BaseAPI, API } from './base-api';

export default class EnableSiblings extends BaseAPI {
  constructor() {
    super(API.CALENDAR_ENABLE_SIBLINGS);
  }

  execute(accountIds) {
    const postParams = {};

    postParams.account_id = JSON.stringify(accountIds);

    return super.connect(postParams);
  }
}
