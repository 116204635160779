import Actions from '../actions';
const defaultState = {
  createEventStatus: false,
  manageTemplate: false,
  calendarList: [],
  eventList: [],
  eventListToDisplay: [],
  calendarListToDisplay: [],
  loading: false
};
export const calendar = (state = defaultState, action) => {
  switch (action.type) {
    case Actions.UPDATE_CALENDER_LIST: {
      const calendarListToDisplay = [];
      (action.payload.list || []).map((calendar) => {
        calendar.list.map((item) => {
          calendarListToDisplay.push({
            account_id: calendar.account_id,
            ...item
          });
          return item;
        });
        return calendar;
      });
      return Object.assign({}, state, {
        calendarList: action.payload.list,
        calendarListToDisplay
      });
    }
    case Actions.UPDATE_CALENDER_EVENT_LIST: {
      const eventListToDisplay = [];
      (action.payload.list || []).map((calendar) => {
        if (calendar && calendar.list) {
          calendar.list.map((event) => {
            const eventItem = {
              id: event.id,
              allDay: event.is_all_day,
              start: new Date(event.start.date_time * 1000),
              end: new Date(event.end.date_time * 1000),
              title: event.summary,
              // url: event.html_link,
              calendar_uid: calendar.calendar_uid,
              backgroundColor: calendar.color ? calendar.color.background : undefined,
              textColor: calendar.color ? calendar.color.foreground : undefined
            };
            eventListToDisplay.push(eventItem);
            return event;
          });
        }
        return calendar;
      });
      return Object.assign({}, state, {
        eventList: action.payload.list,
        eventListToDisplay
      });
    }
    case Actions.SET_CALENDER_LOADER: {
      return Object.assign({}, state, {
        loading: action.payload
      });
    }
    case Actions.CREATE_EVENT_STATUS: {
      return Object.assign({}, state, {
        createEventStatus: action.payload
      });
    }
    default:
      return state;
  }
};
