import UserPreferences from './UserPreferenceSingleton';
import JSHelper from './jshelper';
import moment from 'moment';

const Status = {
  OLD_USER: 'old_user',
  TRIAL: 'trial',
  TRIAL_DORMANT: 'trial_dormant',
  PAID: 'paid_with_account',
  DORMANT: 'dormant',
  GRANDFATHER_TRIAL: 'grandfather_trial', // use isGifted flag
};
export default class Subscription {
  static get Status() {
    return Status;
  }
  static processSubscriptionInfo(subscription) {
    const isBricked = JSHelper.optNumber(subscription, 'is_bricked') === 1;
    const isGifted = JSHelper.optNumber(subscription, 'is_gift') === 1;
    const status = JSHelper.optString(subscription, 'subscription_status');
    const tsExpiry = JSHelper.optNumber(subscription, 'ts_expiry');
    const tsBegin = JSHelper.optNumber(subscription, 'ts_begin');
    const tsSignup = JSHelper.optNumber(subscription, 'ts_signup');

    const userPreference = UserPreferences.getInstance();
    userPreference.set(UserPreferences.IS_BRICKED, isBricked);
    userPreference.set(UserPreferences.SUBSCRIPTION_EXPIRY_TS, tsExpiry);
    userPreference.set(UserPreferences.SUBSCRIPTION_BEGIN_TS, tsBegin);
    userPreference.set(UserPreferences.IS_GIFTED, isGifted);
    userPreference.set(UserPreferences.NEWTON_SUBSCRIPTION_STATUS, Subscription.getStatus(status));
    userPreference.set(UserPreferences.TS_SIGNUP, tsSignup);
  }

  static getStatus(status) {
    switch (status) {
      case Status.TRIAL:
      case Status.TRIAL_DORMANT:
      case Status.PAID:
      case Status.DORMANT:
        return status;
      default:
        return Status.OLD_USER;
    }
  }

  static getSubscriptionStatus() {
    const userPreference = UserPreferences.getInstance();
    const status = userPreference.get(UserPreferences.NEWTON_SUBSCRIPTION_STATUS, Status.OLD_USER);

    if (status === Status.TRIAL && userPreference.get(UserPreferences.IS_GIFTED, false))
      return Status.GRANDFATHER_TRIAL;
    else if (status === Status.TRIAL && Subscription.expiry().days === -1)
      return Status.TRIAL_DORMANT;

    return status;
  }

  static expiry() {
    const userPreference = UserPreferences.getInstance();
    const beginTs = userPreference.get(UserPreferences.SUBSCRIPTION_BEGIN_TS, 0) * 1000;
    const expiryTs = userPreference.get(UserPreferences.SUBSCRIPTION_EXPIRY_TS, 0) * 1000;
    const totalDays = Math.round((expiryTs - beginTs) / (24 * 60 * 60 * 1000));
    const currentTs = Date.now();
    const expiryDayTs = (new moment(expiryTs)).endOf('day').valueOf();

    let days = (expiryDayTs - currentTs) / (24 * 60 * 60 * 1000);
    days = days > -1 ? days : (expiryDayTs - currentTs) / (24 * 60 * 60 * 1000);
    days = Math.min(Math.max(Math.round(days), -1), totalDays);
    return { days, totalDays, timestamp: expiryDayTs };
  }

  static canAccesSuperChargers() {
    switch (Subscription.getSubscriptionStatus()) {
      case Status.PAID:
      case Status.TRIAL:
      case Status.GRANDFATHER_TRIAL:
        return true;
      default:
        return false;
    }
  }

  static isBricked() {
    const userPreference = UserPreferences.getInstance();
    return userPreference.get(UserPreferences.IS_BRICKED, false);
  }

  static getSubscription() {
    const status = Subscription.getSubscriptionStatus();
    const expiry = Subscription.expiry();

    return {
      active: status === Status.PAID || status === Status.GRANDFATHER_TRIAL,
      status,
      trial: status === Status.TRIAL && expiry.days !== -1,
      expiry: expiry,
    };
  }

  static getUpdatedSubscription(subscription) {
    if (!subscription) return subscription;

    const updatedSubscription = Subscription.getSubscription();

    if (subscription.active !== updatedSubscription.active ||
      subscription.status !== updatedSubscription.status ||
      subscription.trial !== updatedSubscription.trial ||
      subscription.expiry.days !== updatedSubscription.expiry.days ||
      subscription.expiry.totalDays !== updatedSubscription.expiry.totalDays ||
      subscription.expiry.timestamp !== updatedSubscription.expiry.timestamp) {
      return updatedSubscription;
    }

    return subscription;
  }
}
