import { BaseAPI, API } from './base-api';

export default class GetUserEmailList extends BaseAPI {
  constructor() {
    super(API.EMAIL_LIST);
    this.filtered = false;
  }

  filter() {
    this.filtered = true;
    return this;
  }

  execute() {
    const postParams = {};
    postParams.filtered = this.filtered ? 1 : 0;

    return super.connect(postParams);
  }
}
