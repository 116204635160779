export const MESSAGE_LIST_RESPONSE = 'MESSAGE_LIST_RESPONSE';
export const POST_DB_CONVERSATIONS = 'POST_DB_CONVERSATIONS';
export const SEARCH_ACCOUNT_LIST_RESPONSE = 'SEARCH_ACCOUNT_LIST_RESPONSE';

export const postDBMessages = (conversations, offset, dbHasMore = true) =>
  ({
    type: POST_DB_CONVERSATIONS,
    conversations,
    offset,
    dbHasMore,
  });

export const onMessageListResponse =
  (conversations, syncHash, hasMore, isFetching = false, lastMessageIdentifier = null) =>
    ({
      type: MESSAGE_LIST_RESPONSE,
      conversations,
      syncHash,
      hasMore,
      isFetching,
      lastMessageIdentifier,
    });

export const onSearchMessageAccountListResponse = searchAccountList => ({
  type: SEARCH_ACCOUNT_LIST_RESPONSE,
  searchAccountList,
});

