import 'isomorphic-fetch';
import { v4 as uuid } from 'uuid';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import Logger from '../helper/logger';
import * as Constants from '../helper/constant';
import APIError from '../api/api-error';
import JSHelper from '../helper/jshelper';

const version = '/v6';
const tEndpoint = '/t';
const kEndpoint = '/k';
const fEndpoint = '/f';

export const API = {
  LOGIN: `${kEndpoint}${version}/user/session_create`,
  LOGOUT: `${kEndpoint}${version}/user/session_destroy`,
  CHANGE_GET: `${tEndpoint}${version}/change/get`,
  ACCOUNT_LIST: `${kEndpoint}${version}/account/details`,
  PROFILE_INFO_GET: `${kEndpoint}${version}/profileinfo/get`,
  CALENDER_LIST: `${fEndpoint}${version}/data/event/getcalendarlist`,
  CALENDER_EVENT_LIST: `${fEndpoint}${version}/data/event/sync`,
  CALENDER_EVENT_CREATE: `${tEndpoint}${version}/action/set`,
  CALENDAR_ENABLE_SIBLINGS: `${kEndpoint}${version}/account/enable_siblings`
};

let userFrozen = false;
let userRelocated = 0;
let userGetChangeWarmupTS = 0;
const MAX_RETRIES_RELOCATION = 2;
let sessionExpiredTriggered = false;

export class BaseAPI {
  constructor(endpoint) {
    this.retry = false;
    this.noOfTriesForRelocation = 0;
    this.noOfTries = 0;
    this.endpoint = endpoint;
    this.isGetChangeApiCall = false;
    this.isPostDataLoggable = true;
  }

  static get SESSION_EXPIRED_TRIGGERED() {
    return sessionExpiredTriggered;
  }

  static set SESSION_EXPIRED_TRIGGERED(value) {
    sessionExpiredTriggered = value;
  }

  static get USER_FROZEN() {
    return userFrozen;
  }

  static set USER_FROZEN(value) {
    userFrozen = value;
  }

  static get USER_RELOCATED() {
    return userRelocated;
  }

  static set USER_RELOCATED(value) {
    userRelocated = value;
  }

  static get USER_GET_CHANGE_WARMUP_TS() {
    return userGetChangeWarmupTS;
  }

  static set USER_GET_CHANGE_WARMUP_TS(value) {
    userGetChangeWarmupTS = value;
  }

  static getAppVersion() {
    return '1.0.0-web';
  }

  static getAddAccountPath(accountGroup) {
    const rootUrl = BaseAPI.getServerMobilePageUrl();
    const appVersion = BaseAPI.getAppVersion();
    return `${rootUrl}${API.ACCOUNT_ADD_PATH}/${accountGroup}?ct=cpi&ln=en&pv=14.1&cv=${appVersion}&ln=en&tz=19800`;
  }

  static getServerApiUrl() {
    const userProvidedurl = UserPreferenceSingleton.getInstance().getServerUrl();
    if (userProvidedurl) {
      if (userProvidedurl === 'cloudmagic.com') {
        return Constants.API_ROOT;
      }
      return `https://api-${userProvidedurl}`;
    }
    return Constants.API_ROOT;
    // return '';
  }

  static getServerMobilePageUrl() {
    const userProvidedurl = UserPreferenceSingleton.getInstance().getServerUrl();
    if (userProvidedurl) {
      return `https://${userProvidedurl}`;
    }
    return Constants.MOBILE_PAGE_ROOT;
  }

  static getQueryParams(getParams, queue = null) {
    const appVersion = BaseAPI.getAppVersion();

    let getParamStr;
    if (getParams && Object.keys(getParams).length > 0) {
      const params = Object.keys(getParams).map((key) => {
        const value = getParams[key];
        return `${key}=${encodeURIComponent(value)}`;
      });
      getParamStr = params.join('&');
    }
    if (getParamStr) {
      return `?ct=cpi&ln=en&pv=10.0&cv=${appVersion}&ln=en&tz=19800&${getParamStr}`;
    }
    if (queue) {
      return `?ct=cpi&ln=en&pv=10.0&cv=${appVersion}&ln=en&tz=19800&queue=${queue}`;
    }
    return `?ct=cpi&ln=en&pv=10.0&cv=${appVersion}&ln=en&tz=19800`;
  }

  static authHeaders(contentLength = 0, accessTokens = null) {
    if (!accessTokens) {
      accessTokens = UserPreferenceSingleton.getInstance().get('access_tokens');
    }

    const header = {};
    if (accessTokens) {
      Object.keys(accessTokens).forEach((key) => {
        if (key && {}.hasOwnProperty.call(accessTokens, key)) {
          header[`X-${key}`] = accessTokens[key];
        }
      });
    }
    header['Content-Type'] = 'application/x-www-form-urlencoded';
    // header['Accept-Encoding'] = 'deflate,gzip';
    // header['Content-Length'] = contentLength;
    header['X-CMREQID'] = uuid();
    header['CM-USER-IDENTIFIER'] = UserPreferenceSingleton.getInstance().get('user_identifier');
    return header;
  }
  static encode(data) {
    const out = [];
    Object.keys(data).forEach((key) => {
      out.push(`${key}=${encodeURIComponent(data[key])}`);
    });
    return out.join('&');
  }

  callApi(endpoint, method = 'GET', { postParams, getParams, accessTokens }, isJSONBody = false) {
    this.retry = false;

    const currentTimeInMillis = new Date().valueOf();
    if (currentTimeInMillis - BaseAPI.USER_RELOCATED < Constants.USER_RELOCATION_INTERVAL) {
      const apiError = APIError.getDummyError(Constants.APIERROR_USER_BEING_RELOCATED);
      return { error: apiError };
    } else if (BaseAPI.USER_FROZEN) {
      if (this.isGetChangeApiCall) {
        // It's a valid request and let it proceed.
      } else {
        const apiError = APIError.getDummyError(Constants.APIERROR_USER_FROZEN);
        return { error: apiError };
      }
    }

    let fullUrl = BaseAPI.getServerApiUrl() + endpoint + BaseAPI.getQueryParams(getParams, this.queue);

    if (endpoint === API.DELETE_PUBLIC_SHARE_EMAIL || endpoint === API.UPDATE_PUBLIC_SHARE_EMAIL) {
      fullUrl =
        BaseAPI.getServerApiUrl() +
        endpoint +
        '/' +
        postParams.message_unique_id +
        BaseAPI.getQueryParams(getParams, this.queue);
    }
    Logger.info(`Request URL: ${fullUrl}`);

    const startTime = new Date().valueOf();

    const options = {};
    options.method = method;
    options.headers = BaseAPI.authHeaders(postParams ? postParams.length : 0, accessTokens);

    Logger.info(`UUID: ${options.headers['X-CMREQID']}`);

    if (method === 'POST' && postParams) {
      if (
        endpoint === API.REPORT_CRASH_PATH ||
        endpoint === API.MESSAGE_INSIGHT_API ||
        endpoint === API.DISMISS_INSIGHT_API ||
        endpoint === API.LATER_INSIGHT_API ||
        endpoint === API.REPORT_INSIGHT_API ||
        endpoint === API.INSIGHT_VIEWED_API ||
        endpoint === API.TRACKING_FEE_LIST_API ||
        endpoint === API.TOS_ACCEPT ||
        isJSONBody
      ) {
        options.body = JSON.stringify(postParams);
        options.headers['Content-Type'] = 'application/json';
      } else {
        const encodedParams = BaseAPI.encode(postParams);
        options.body = encodedParams;

        if (this.isPostDataLoggable) {
          Logger.info(`Request data: ${encodedParams}`);
        }
      }
    }

    this.noOfTriesForRelocation += 1;
    this.noOfTries += 1;

    if (!this.isOnline()) {
      const apiError = new APIError();
      apiError.setErrorCode(Constants.OFFLINE_ERROR);
      apiError.setErrorMessage('You are Offline');
      Logger.error('Error: Connection error');

      return { error: apiError };
    }

    return fetch(fullUrl, options)
      .then((response) => response.json().then((json) => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
      .then(
        (responseObj) => {
          // on success
          Logger.info(`Response: ${responseObj.error_code}`);
          const endTime = new Date().valueOf();
          Logger.info(`Total Time: ${(endTime - startTime) / 1000}s`);

          const errorObj = BaseAPI.validateResponse(options, responseObj);
          Logger.info('response validated');
          Logger.log(errorObj);
          if (errorObj) {
            Logger.error(`Error: ${errorObj.getErrorMessage()}`);

            this.handleApiError(errorObj);
            if (this.retry) {
              return this.callApi(endpoint, method, { postParams, getParams });
            }
            return { error: errorObj };
          }
          BaseAPI.USER_FROZEN = false;
          BaseAPI.USER_RELOCATED = 0;
          return { response: responseObj };
        },
        (error) => {
          // on reject
          Logger.error('An error occured!');
          Logger.error(error.message ? error.message : error);
          if (!error.message) {
            const errorObj = BaseAPI.validateResponse(options, error);

            Logger.info(`Response: ${errorObj.getErrorCode()}`);
            Logger.error(`Error: ${errorObj.getErrorMessage()}`);

            Logger.warn('error validated');
            Logger.warn(errorObj);
            this.handleApiError(errorObj);
            return { error: errorObj };
          }
          const apiError = new APIError();

          if (error.message && error.message === 'Failed to fetch') {
            apiError.setErrorMessage('Cannot reach server. Please try again later.');
          } else {
            apiError.setErrorMessage(error.message);
          }

          apiError.setErrorCode(500);
          Logger.info(`Response: ${apiError.getErrorCode()}`);
          Logger.error(`Error: ${apiError.getErrorMessage()}`);

          return { error: apiError };
        }
      );
  }

  isOnline() {
    return navigator.onLine;
  }

  handleApiError(apiError) {
    if (!apiError) {
      return;
    }

    switch (apiError.getErrorCode()) {
      case Constants.APIERROR_USER_MOVED:
        this.updateAccessTokens(apiError);
        if (this.noOfTriesForRelocation <= MAX_RETRIES_RELOCATION) {
          this.retry = true;
        }
        break;

      case Constants.APIERROR_SESSION_EXPIRED:
        if (!BaseAPI.SESSION_EXPIRED_TRIGGERED) {
          Logger.info('session expired triggered');
          BaseAPI.SESSION_EXPIRED_TRIGGERED = true;
          // ipcRenderer.send('session_expired');
        }
        break;

      case Constants.APIERROR_BREAKING_CHANGES:
        UserPreferenceSingleton.getInstance().set(UserPreferenceSingleton.APP_BROKEN_MSG, apiError.getErrorMessage());
        // TODO: dispatch breaking changes
        break;

      case Constants.APIERROR_USER_FROZEN: {
        const currentTimeInMillis = new Date().valueOf();
        if (
          BaseAPI.USER_GET_CHANGE_WARMUP_TS !== 0 &&
          currentTimeInMillis - BaseAPI.USER_GET_CHANGE_WARMUP_TS < Constants.USER_FROZEN_STATE_IGNORE_INTERVAL
        ) {
          BaseAPI.USER_FROZEN = false;
        } else {
          BaseAPI.USER_FROZEN = true;
        }
        break;
      }

      case Constants.APIERROR_USER_BEING_RELOCATED:
        BaseAPI.USER_RELOCATED = new Date().valueOf();
        break;

      default:
        break;
    }
  }

  static validateResponse(requestOptions, response) {
    const apiError = new APIError();
    apiError.setRawResponse(response);

    try {
      const errorCode = response.error_code;
      if (errorCode === 0 || errorCode === '0') {
        return null;
      }

      Logger.error(`inside validate response - ${errorCode}`);
      Logger.error(BaseAPI.getServerProvidedErrorMessage(response));

      if (requestOptions) {
        // TODO: log request data here
      }

      apiError.setErrorCode(errorCode);

      switch (errorCode) {
        case Constants.APIERROR_SESSION_EXPIRED:
          apiError.setErrorMessage(BaseAPI.getServerProvidedErrorMessage(response));
          if (!apiError.getErrorMessage()) {
            apiError.setErrorMessage(`Something went wrong [${errorCode}]`);
          }

          apiError.setErrorType(APIError.ERROR_SHOW_CRITICAL_MESSAGE);
          return apiError;

        case Constants.APIERROR_SERVICE_MAINTENANCE:
          apiError.setErrorMessage(BaseAPI.getServerProvidedErrorMessage(response));
          if (!apiError.getErrorMessage()) {
            apiError.setErrorMessage(`Something went wrong [${errorCode}]`);
          }

          apiError.setErrorType(APIError.ERROR_SHOW_EMBEDDED_MESSAGE);
          return apiError;

        case Constants.APIERROR_BREAKING_CHANGES:
          apiError.setErrorMessage(BaseAPI.getServerProvidedErrorMessage(response));
          if (!apiError.getErrorMessage()) {
            apiError.setErrorMessage(`Something went wrong [${errorCode}]`);
          }

          apiError.setErrorType(APIError.ERROR_SHOW_INFO_MESSSAGE);
          return apiError;

        case Constants.APIERROR_INVALID_INPUT:
        default:
          apiError.setErrorMessage(BaseAPI.getServerProvidedErrorMessage(response));
          if (!apiError.getErrorMessage()) {
            apiError.setErrorMessage(`Something went wrong [${errorCode}]`);
          }

          apiError.setErrorType(APIError.ERROR_SHOW_INFO_MESSSAGE);

          if (errorCode === Constants.APIERROR_INVALID_INPUT) {
            // TODO: handle critical error here
          }

          return apiError;
        /* case Constants.APIERROR_REDIRECT_URL:
          apiError.setErrorMessage(getUrl(response));
          apiError.setErrorType(APIError.ERROR_SHOW_INFO_MESSSAGE);
          apiError.setErrorCode(errorCode);
          return errorObj; */
      }
    } catch (error) {
      Logger.error('json exception in validate response');
      Logger.error(error);
      apiError.setErrorMessage('Something went wrong [Invalid data format]');
      apiError.setErrorType(APIError.ERROR_SHOW_INFO_MESSSAGE);

      if (requestOptions) {
        // TODO: log request data here and handle critical log
      }

      return apiError;
    }
  }

  updateAccessTokens(error) {
    try {
      const existingAccessTokens = UserPreferenceSingleton.getInstance().get('access_tokens');

      const changeInAccessTokens = error.getRawResponse();

      if (!existingAccessTokens || !changeInAccessTokens || existingAccessTokens.length === 0) {
        return false;
      }

      if (JSHelper.containsKey(changeInAccessTokens, 'data')) {
        Object.keys(changeInAccessTokens.data).forEach((key) => {
          existingAccessTokens[key] = changeInAccessTokens.data[key];
        });

        UserPreferenceSingleton.getInstance().set('access_tokens', existingAccessTokens);
        return true;
      }
      return false;
    } catch (jsonError) {
      return false;
    }
  }

  static getServerProvidedErrorMessage(response) {
    try {
      if (JSHelper.containsKey(response, 'data')) {
        const { data } = response;

        if (JSHelper.containsKey(data, 'msg')) {
          return data.msg;
        }
      }
    } catch (error) {
      Logger.error(error);
    }
    return null;
  }

  setQueue(queue) {
    this.queue = queue;
  }

  connect(data = null, getParams = null, isPostDataLoggable = true, accessTokens = null, isJSONBody = false) {
    this.isPostDataLoggable = isPostDataLoggable;

    try {
      if (data !== null) {
        return this.callApi(this.endpoint, 'POST', { postParams: data, getParams, accessTokens }, isJSONBody);
      }
      return this.callApi(this.endpoint, 'GET', { getParams });
    } catch (error) {
      Logger.error(`base api error - ${error}`);
    }
    return null;
  }
}
