/**
 * @module /sagas/
 */

import { race, take, call, put, fork, select, cancel, all } from 'redux-saga/effects';
import { login, resetPassword, deleteAllMyData } from './login';
import Actions from '../actions';
import { getLoginState, getLogoutState } from '../selectors';
import { logout, flush } from './login';
import { LOGIN } from '../reducers/login';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import { watchSyncInit } from './initial-sync';
import { watchCalenderEvents } from './events';

export default function* root() {

  try {
    while (true) {
      let loginState = yield select(getLoginState);
      console.log("newtonmail:- check login state in root.....", loginState)
      let requireWarmUpDuringSync = true;
      if (!loginState || loginState !== LOGIN.SUCCESS) {
        console.log("newtonmail:- check login actions.....")
        const action = yield take([Actions.LOGIN, Actions.LOGIN_SUCCESS, Actions.PASSWORD_RESET, Actions.DELETE_ALL_MY_DATA]);
        console.log('action', action)
        if (action.type === Actions.LOGIN) {

          yield call(login, action);
          loginState = yield select(getLoginState);

          if (loginState && loginState !== LOGIN.SUCCESS) {
            continue;
          }
          requireWarmUpDuringSync = false;
        } else if (action.type === Actions.PASSWORD_RESET) {
          yield call(resetPassword, action);
          continue;
        } else if (action.type === Actions.GET_PRODUCT_LIST) {
          // yield fork(getProductList, action);
          continue;
        } else if (action.type === Actions.DELETE_ALL_MY_DATA) {
          yield call(deleteAllMyData, action);
          continue;
        }
      }

      while (true) {
        const syncTask = yield fork(watchSyncActions);
        console.log("newtonmail:-  sync start here.....", loginState)

        const isWarmup = requireWarmUpDuringSync;

        UserPreferenceSingleton.getInstance().set(UserPreferenceSingleton.LAST_GETCHANGE_WITH_WARMUP_TS,
          new Date().valueOf());

        yield put(Actions.sync(isWarmup));

        const { cancelAction } = yield race({
          task: all([call(watchCalenderEvents)]),
          cancelAction: take([Actions.LOGOUT, Actions.FLUSH, Actions.DELETE_NEWTON_ACCOUNT])
        });

        if (cancelAction) {
          console.log("tasks cancelled - start logout saga - " + cancelAction.type);
          console.log(cancelAction);

          if (syncTask) {
            yield cancel(syncTask);
          }

          if (cancelAction.type === Actions.LOGOUT) {
            console.log("newtonmail:- call action logout....")
            yield call(logout, cancelAction);

            const logoutState = yield select(getLogoutState);

            if (logoutState && logoutState.isSuccessful) {
              console.log("logout successful in saga");
              break;
            }
          } else if (cancelAction.type === Actions.FLUSH) {
            yield call(flush, cancelAction);
          }
        }
      }
    }
  } catch (error) {
    console.error("error in root saga");
    console.error(error);
    if (!UserPreferenceSingleton.crashOccured) {
      UserPreferenceSingleton.crashOccured = true;
      // Utils.closeOnCrash();
    }
  }
}

function* watchSyncActions() {
  try {
    yield all([fork(watchSyncInit)]);
  } catch (error) {
    console.log("watchSyncActions", error)
  }
}
