import Actions from '../actions';

export const folders = (state = { manageFolder: false }, action) => {
  switch (action.type) {
    case Actions.CREATE_FOLDER:
    case Actions.RENAME_FOLDER:
    case Actions.DELETE_FOLDER:
      return Object.assign({}, state, { manageFolder: true });

    case Actions.MANAGE_FOLDER_RESPONSE:
      return Object.assign({}, state, { manageFolder: false });

    case Actions.MANAGE_FOLDER_ERROR:
      return Object.assign({}, state, { manageFolder: action.payload });

    case Actions.ACCOUNT_N_FOLDER_SYNC_COMPLETE:
      return Object.assign({}, state, {
        selectedFolder: action.payload.selectedFolder,
        selectedAccount: action.payload.selectedAccount,
      });
    case Actions.SET_SELECTED_FOLDER: {
      return Object.assign({}, state, {
        selectedFolder: action.payload.folder,
        selectedAccount: action.payload.account,
      });
    }
    case Actions.UPDATE_FOLDER_LIST: {
      const selectedFolder = state.selectedFolder;
      if (selectedFolder) {
        const folderList = action.payload.folderMap[selectedFolder.accountId];
        if (folderList) {
          const updatedSelectedFolder = folderList.find(folder => folder.id === selectedFolder.id);
          if (updatedSelectedFolder) {
            return Object.assign({}, state, {
              folderMap: action.payload.folderMap,
              accountMap: action.payload.accountMap,
              selectedFolder: updatedSelectedFolder,
            });
          }
        }
      }
      return Object.assign({}, state, {
        folderMap: action.payload.folderMap,
        accountMap: action.payload.accountMap,
        noAccountsPresent: !action.payload.accountMap || Object.keys(action.payload.accountMap).length === 0,
      });
    }
    case Actions.SET_SYSTEM_FOLDERS: {
      return Object.assign({}, state, {
        systemFolders: action.payload.folders,
      });
    }
    case Actions.NO_ACCOUNTS_PRESENT: {
      return {
        ...state,
        noAccountsPresent: true,
      };
    }
    case Actions.SHOW_DRAWER: {
      return Object.assign({}, state, {
        showDrawer: typeof action.show === 'boolean' ? action.show : (state.showDrawer ? false : true)
      });
    }
    default:
      return state;
  }
};
