import { BaseAPI, API } from './base-api';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';

export default class SetProfileInfoApi extends BaseAPI {

  constructor() {
    super(API.PROFILE_INFO_SET);
  }

  execute() {
    const postParams = {};
    const userPreferences = UserPreferenceSingleton.getInstance();
    postParams.nickname = userPreferences.getUserNickName();
    postParams.profile_pic = userPreferences.getProfilePicUrl();

    return super.connect(postParams);
  }
}
