export const getConversationsOffset = state =>
  (state.conversations.db ? state.conversations.db.offset : 0);
export const getSelectedAccount = state => state.folders.selectedAccount;
export const getSelectedFolder = state => state.folders.selectedFolder;
export const getFolderMap = state => state.folders.folderMap;
export const getAccountMap = state => state.folders.accountMap;
export const getAPISyncHash = state => (state.conversations.api ? state.conversations.api.syncHash : '');
export const getConversationList = state => state.conversations.conversations || [];
export const getDbHasMore = state =>
  (state.conversations.db ? state.conversations.db.hasMore : true);
export const getServerHasMore = state =>
  (state.conversations.api ? state.conversations.api.hasMore : true);
export const getLoginState = state => state.login.login;
export const getBulkRequest = state =>
  (state.conversations.bulk ? state.conversations.bulk.requests : undefined);
export const getAccountColorMap = state => state.accounts.accountColorMap;
export const getAccountActionStatusMap = state => state.accounts.accountActionStatusMap;
export const getSearchQuery = state =>
  (state.conversations.search ? state.conversations.search.query : '');
export const getSelectedSearchAccount = state => state.conversations.search ?
  (state.conversations.search.selectedSearchAccount ? state.conversations.search.selectedSearchAccount : null) : null;
export const getLogoutState = state => state.login.logout;
export const getNewtonDeleteAccountState = state => state.signup.deleteAccountResponse;
export const getSelectedFilter = state => state.conversations.filter;
export const getSubscriptionInfo = state => state.inappPurchase ? state.inappPurchase.subscription : null;
export const getClosedYellowNotificationIds = state => state.yellowNotification ? state.yellowNotification.clearedNotifications : null;
export const waitLoaderVisibility = state => state.conversations.conversationUpdates ? state.conversations.conversationUpdates.showWaitLoader : false;
export const getConversationChanges = state => state.conversations.conversationUpdates ? state.conversations.conversationUpdates.changes : null;
export const showInsightView = state => state.conversations.showInsights;
export const getCalendarList = state => state.calendar.calendarList;
export const getCalendarEventList = state => state.calendar.eventList;