import { BaseAPI, API } from './base-api';

export default class PreferenceGetApi extends BaseAPI {
  constructor() {
    super(API.PREFERENCE_GET);
  }

  execute() {
    return super.connect(null);
  }
}