import Logger from '../helper/logger';

export const FETCH_FOLDER_LIST = 'FETCH_FOLDER_LIST';
export const FOLDER_LIST_RESPONSE = 'FOLDER_LIST_RESPONSE';
export const UPDATE_FOLDER_LIST = 'UPDATE_FOLDER_LIST';
export const SET_SELECTED_FOLDER = 'SET_SELECTED_FOLDER';
export const SET_SYSTEM_FOLDERS = 'SET_SYSTEM_FOLDERS';
export const SHOW_DRAWER = 'SHOW_DRAWER';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const RENAME_FOLDER = 'RENAME_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const MANAGE_FOLDER_RESPONSE = 'MANAGE_FOLDER_RESPONSE';
export const MANAGE_FOLDER_ERROR = 'MANAGE_FOLDER_ERROR';

function getError(error) {
  const ret = {
    error,
    message: 'Something went wrong',
  };

  try {
    if (error && typeof error === 'string') {
      ret.message = error;
    } else if (error.message && typeof error.message === 'string') {
      ret.message = error.message;
    }
    if (error.title && typeof error.title === 'string') {
      ret.title = error.title;
    }
  } catch (e) {
    Logger.error(e);
  }
  return ret;
}

export const createFolder = (accountId, parentFolder, folderName, payloadExtras = undefined) => ({
  type: CREATE_FOLDER,
  payload: {
    accountId,
    parentFolder,
    folderName: folderName.trim(),
    payloadExtras,
  },
});

export const renameFolder = (folder, folderName) =>
  ({
    type: RENAME_FOLDER,
    payload: { folder, folderName: folderName.trim() },
  });

export const deleteFolder = folder =>
  ({ type: DELETE_FOLDER, payload: { folder } });

export const onManageFolderResponse = () => ({ type: MANAGE_FOLDER_RESPONSE });

export const onManageFolderError = error => ({
  type: MANAGE_FOLDER_ERROR,
  payload: getError(error),
});

export const fetchFolders = (selectFolder = false) => ({
  type: FETCH_FOLDER_LIST,
  selectFolder,
});

export const onFolderListResponse = () => ({ type: FOLDER_LIST_RESPONSE });

export const updateFolderList = (folderMap, accountMap) =>
  ({
    type: UPDATE_FOLDER_LIST,
    payload: { folderMap, accountMap },
  });

export const setSelectedFolder = (folder, account, accountChanged = false) =>
  ({
    type: SET_SELECTED_FOLDER,
    payload: { folder, account, accountChanged },
  });

export const setSelectedFolderWithRecap = (folder, account, accountChanged = false) =>
  ({
    type: SET_SELECTED_FOLDER,
    payload: { folder, account, accountChanged, showInsights: true },
  });

export const setSystemFolderList = folders => ({
  type: SET_SYSTEM_FOLDERS,
  payload: {
    folders,
  },
});

export const showNavDrawer = show => ({
  type: SHOW_DRAWER,
  show,
});
