export const ACCOUNT_COLOR_PALETTE = {
  data: {
    account_group_color_list: {
      custom: [
        [
          "Iris",
          [
            255,
            255,
            255
          ],
          [
            99,
            98,
            190
          ]
        ],
        [
          "Turquoise",
          [
            0,
            145,
            131
          ],
          [
            88,
            199,
            199
          ]
        ],
        [
          "Fire Brick",
          [
            192,
            67,
            55
          ],
          [
            209,
            75,
            72
          ]
        ],
        [
          "Tiger Eye",
          [
            171,
            115,
            35
          ],
          [
            224,
            157,
            90
          ]
        ],
        [
          "Soft Blue",
          [
            98,
            101,
            191
          ],
          [
            153,
            153,
            232
          ]
        ],
        [
          "Steel Blue",
          [
            10,
            114,
            153
          ],
          [
            70,
            144,
            199
          ]
        ],
        [
          "Emerald",
          [
            55,
            128,
            58
          ],
          [
            55,
            161,
            126
          ]
        ],
        [
          "Inchworm",
          [
            118,
            148,
            47
          ],
          [
            178,
            196,
            96
          ]
        ],
        [
          "Manatee",
          [
            77,
            108,
            112
          ],
          [
            146,
            163,
            179
          ]
        ],
        [
          "Pale Violet Red",
          [
            179,
            98,
            112
          ],
          [
            224,
            123,
            140
          ]
        ],
        [
          "Tea Rose",
          [
            198,
            104,
            96
          ],
          [
            250,
            153,
            144
          ]
        ],
        [
          "Medium Aqua Marine",
          [
            81,
            164,
            136
          ],
          [
            102,
            205,
            171
          ]
        ],
      ],
      exchange: [
        [
          [
            72,
            180,
            235,
          ],
          [
            242,
            234,
            219,
          ],
        ],
        [
          [
            255,
            149,
            102,
          ],
          [
            222,
            240,
            248,
          ],
        ],
        [
          [
            216,
            196,
            120,
          ],
          [
            242,
            219,
            237,
          ],
        ],
        [
          [
            33,
            197,
            195,
          ],
          [
            246,
            220,
            220,
          ],
        ],
        [
          [
            65,
            106,
            109,
          ],
          [
            223,
            221,
            245,
          ],
        ],
      ],
      google: [
        [
          [
            206,
            95,
            93,
          ],
          [
            242,
            215,
            215,
          ],
        ],
        [
          [
            66,
            165,
            160,
          ],
          [
            215,
            215,
            215,
          ],
        ],
        [
          [
            211,
            169,
            96,
          ],
          [
            213,
            229,
            224,
          ],
        ],
        [
          [
            102,
            102,
            102,
          ],
          [
            251,
            228,
            224,
          ],
        ],
        [
          [
            83,
            165,
            129,
          ],
          [
            221,
            229,
            247,
          ],
        ],
      ],
      icloud: [
        [
          [
            83,
            116,
            112,
          ],
          [
            215,
            210,
            208,
          ],
        ],
        [
          [
            245,
            128,
            114,
          ],
          [
            220,
            245,
            234,
          ],
        ],
        [
          [
            138,
            137,
            242,
          ],
          [
            229,
            220,
            251,
          ],
        ],
        [
          [
            221,
            137,
            111,
          ],
          [
            248,
            230,
            222,
          ],
        ],
        [
          [
            209,
            125,
            142,
          ],
          [
            252,
            215,
            214,
          ],
        ],
      ],
      imap: [
        [
          [
            211,
            128,
            95,
          ],
          [
            248,
            232,
            222,
          ],
        ],
        [
          [
            92,
            208,
            176,
          ],
          [
            217,
            238,
            223,
          ],
        ],
        [
          [
            175,
            170,
            86,
          ],
          [
            238,
            232,
            217,
          ],
        ],
        [
          [
            195,
            116,
            123,
          ],
          [
            244,
            221,
            221,
          ],
        ],
        [
          [
            195,
            129,
            199,
          ],
          [
            242,
            219,
            237,
          ],
        ],
      ],
      outlook: [
        [
          [
            164,
            146,
            119,
          ],
          [
            226,
            224,
            224,
          ],
        ],
        [
          [
            81,
            185,
            186,
          ],
          [
            216,
            239,
            239,
          ],
        ],
        [
          [
            211,
            156,
            106,
          ],
          [
            255,
            228,
            215,
          ],
        ],
        [
          [
            167,
            135,
            216,
          ],
          [
            223,
            221,
            245,
          ],
        ],
        [
          [
            100,
            196,
            147,
          ],
          [
            224,
            244,
            221,
          ],
        ],
      ],
      yahoo: [
        [
          [
            191,
            140,
            235,
          ],
          [
            236,
            220,
            242,
          ],
        ],
        [
          [
            136,
            208,
            109,
          ],
          [
            245,
            220,
            231,
          ],
        ],
        [
          [
            235,
            160,
            165,
          ],
          [
            251,
            222,
            220,
          ],
        ],
        [
          [
            77,
            142,
            157,
          ],
          [
            213,
            244,
            244,
          ],
        ],
        [
          [
            83,
            116,
            112,
          ],
          [
            252,
            233,
            214,
          ],
        ],
      ],
    },
  },
};

export const API_ROOT = 'https://api.cloudmagic.com';
export const MOBILE_PAGE_ROOT = 'https://cloudmagic.com';
export const TRACKING_API_ROOT = 'https://tr.cloudmagic.com';

export const CATEGORY_MESSAGE = 'message';
export const CATEGORY_PEOPLE = 'people';
export const CATEGORY_EVENT = 'event';
export const ACCOUNT_TYPE_GMAIL_MESSAGE = 2;
export const ACCOUNT_TYPE_YAHOO_MESSAGE = 1002;
export const ACCOUNT_TYPE_EWS_MESSAGE = 64;
export const ACCOUNT_TYPE_EAS_MESSAGE = 1010;
export const ACCOUNT_TYPE_HOTMAIL_EAS_MESSAGE = 1011;
export const ACCOUNT_TYPE_OUTLOOK_EAS_MESSAGE = 1014;
export const ACCOUNT_TYPE_ICLOUD_MESSAGE = 1005;
export const ACCOUNT_TYPE_HOTMAIL_IMAP_MESSAGE = 1015;
export const ACCOUNT_TYPE_OUTLOOK_IMAP_MESSAGE = 1016;
export const ACCOUNT_TYPE_GOOGLE_EVENT = 8;
export const ACCOUNT_TYPE_EWS_EVENT = 128;
export const ACCOUNT_TYPE_AOL_MESSAGE = 1003;
export const ACCOUNT_TYPE_GMX_MESSAGE = 1004;
export const ACCOUNT_TYPE_GENERIC_IMAP_MESSAGE = 1006;
export const ACCOUNT_TYPE_MAIL_DOT_COM_MESSAGE = 1007;
export const ACCOUNT_TYPE_EXCHANGE_IMAP_MESSAGE = 1008;

export const SUB_CONTENT_PREVIEW_LENGTH = 80;

export const FAST_CACHE_PERIOD = 259200;

export const LIMIT_MESSAGE_ARRAY_LENGTH = 5;
export const LIMIT_PEOPLE_ARRAY_LENGTH = 10;

export const SYMBOL_FTS_ACCOUNT_ID = 0xE000;
export const SYMBOL_FTS_ATTACHMENT = 0x2580;
export const SYMBOL_FTS_UNREAD = 0x2581;
export const SYMBOL_FTS_STARRED = 0x2582;

export const APIERROR_INVALID_INPUT = 1003;
export const APIERROR_SESSION_EXPIRED = 1006;
export const APIERROR_SERVICE_MAINTENANCE = 1015;
export const APIERROR_REDIRECT_URL = 1022;
export const APIERROR_BREAKING_CHANGES = 1012;
export const APIERROR_USER_MOVED = 1025;
export const APIERROR_NO_ACCOUNT_ADDED = 1026;
export const APIERROR_USER_FROZEN = 1030;
export const APIERROR_USER_BEING_RELOCATED = 1031;
export const APIERROR_INVALID_CLIENT_ID = 1032;
export const APIERROR_USER_ALREADY_EXSIST = 1004;
export const APIERROR_DMARC_ERROR_CODE = 1034;
export const APIERROR_2_STEP_VERIFICATION_CODE = 1036;

export const DBERROR_SQLITEEXCEPTION = -10000;
export const THREADERROR_INTERRUPTEDEXCEPTION = -10001;
export const SYNCERROR = -10002;
export const ATTACHMENT_DOWNLOAD_PERMISSION_ERROR = -10003;
export const OFFLINE_ERROR = -10004;
export const ACCOUNT_AUTH_ERROR = -10005;

export const GETCHANGE_WITH_WARMUP_THROTTLE_INTERVAL = 60 * 1000; // 5 minutes

export const GETCHANGE_RESPONSE_INTERVAL = 60 * 60 * 1000; // 60 minutes

export const YELLOW_NOTIFICATION_API_INTERVAL = 30 * 60 * 1000; // 30 minutes

export const USER_FROZEN_STATE_IGNORE_INTERVAL = 900000;

export const USER_RELOCATION_INTERVAL = 30000; // 30s

export const MAX_SEARCH_SUGGESTION_LIMIT = 3;

export const REFRESH_AFTER_ACTION_THROTTLE_INTERVAL = 30000; // 30s

export const PROMOTIONAL_MESSAGE_API_INTERVAL = 24 * 60 * 60 * 1000; // 24 hours

export const MESSAGE_LANDING_OFFSET_INSEC = 1800; // 30 minutes

export const MAX_SNOOZE_DELAY_IN_MILLIS = 432000000; // 5 days
export const SNOOZE_RESCHEDULE_DELAY_IN_MILLIS = 86400000; // 1 day
export const SNOOZE_RESCHEDULE_INTERVAL_IN_MILLIS = 345600000 // 4 days

export const FOLDER_SYNC_TIP_INTERVAL = 24 * 60 * 60 * 1000; // 24 hours

export const FOCUSED_INBOX_BANNER_CHECK_INTERVAL_INMILLIS = 3600000; // 1 hour

// Increase it on each whatsnew added in release
export const WHATSNEW_VERSION_CODE = 8;

export const CID = 'cid';
export const S3_FILE_TOKEN = 's3_file_token';
export const S3_FILE_TOKEN_SIGNATURE = 's3_file_token_signature';
export const DOWNLOAD_PATH = 'download_path';
export const CONTENT_PATH = 'content_path';
export const LOCAL_FILE_PATH = 'local_file_path';
export const CONTENT_TYPE = 'ct';

export const CM_USER_WITHOUT_ACCOUNT = 'cm_user_without_account';
export const CM_USER_WITH_ACCOUNT = 'cm_user_with_account';
export const SUPPORTED_ACCOUNT_LIST = ['google', 'outlook', 'hotmail', 'exchange', 'imap', 'aol', 'yahoo', 'maildotcom', 'icloud', 'gmx', 'office365'];

export const MAX_ATTACHMENT_SIZE_PER_EMAIL = [
  25, 25,
  24, 24, 24, 24, 24, 24,
  20, 20
];

export const LOG_FILE_NAME_1 = "Newton1.log";
export const LOG_FILE_NAME_2 = "Newton2.log";
export const LOG_FILE_NAME = "Newton.log";
export const PAYMENT_LOG = "Payment.log";

export const FORCE_REFRESH_WHITELIST = [
  ACCOUNT_TYPE_GMAIL_MESSAGE,
  ACCOUNT_TYPE_EWS_MESSAGE,
  ACCOUNT_TYPE_EAS_MESSAGE,
  ACCOUNT_TYPE_HOTMAIL_EAS_MESSAGE,
  ACCOUNT_TYPE_OUTLOOK_EAS_MESSAGE,
  ACCOUNT_TYPE_GOOGLE_EVENT,
  ACCOUNT_TYPE_EWS_EVENT
];

export const ALIAS_SUPPORTED_ACCOUNTLIST = [
  ACCOUNT_TYPE_GMAIL_MESSAGE,
  ACCOUNT_TYPE_EWS_MESSAGE,
  ACCOUNT_TYPE_YAHOO_MESSAGE,
  ACCOUNT_TYPE_AOL_MESSAGE,
  ACCOUNT_TYPE_GMX_MESSAGE,
  ACCOUNT_TYPE_ICLOUD_MESSAGE,
  ACCOUNT_TYPE_GENERIC_IMAP_MESSAGE,
  ACCOUNT_TYPE_MAIL_DOT_COM_MESSAGE,
  ACCOUNT_TYPE_EXCHANGE_IMAP_MESSAGE,
  ACCOUNT_TYPE_HOTMAIL_IMAP_MESSAGE,
  ACCOUNT_TYPE_OUTLOOK_IMAP_MESSAGE
];

export const ALIAS_SUPPORT_VIA_WEB = [
  ACCOUNT_TYPE_AOL_MESSAGE,
  ACCOUNT_TYPE_GMX_MESSAGE,
  ACCOUNT_TYPE_GENERIC_IMAP_MESSAGE,
  ACCOUNT_TYPE_MAIL_DOT_COM_MESSAGE,
  ACCOUNT_TYPE_EXCHANGE_IMAP_MESSAGE,
  ACCOUNT_TYPE_GMAIL_MESSAGE
];

export const MB_IN_BYTES = 1048576;
export let ACTIVE_CONNECTIONS = 0;

export const FAQ_TRASH_FRAGMENT = "#trash";

export const ACCOUNT_TYPES = [
  ACCOUNT_TYPE_GMAIL_MESSAGE,
  ACCOUNT_TYPE_YAHOO_MESSAGE,
  ACCOUNT_TYPE_EWS_MESSAGE,
  ACCOUNT_TYPE_EAS_MESSAGE,
  ACCOUNT_TYPE_HOTMAIL_EAS_MESSAGE,
  ACCOUNT_TYPE_OUTLOOK_EAS_MESSAGE,
  ACCOUNT_TYPE_HOTMAIL_IMAP_MESSAGE,
  ACCOUNT_TYPE_OUTLOOK_IMAP_MESSAGE,
  ACCOUNT_TYPE_ICLOUD_MESSAGE,
  ACCOUNT_TYPE_GENERIC_IMAP_MESSAGE
];

export const SENTRY_KEY = "https://e54f31f0134a4145acba03d4cdcb499f@o476155.ingest.sentry.io/5515664";
export const CRASH_REPORTER_API = "https://o476155.ingest.sentry.io/api/5515664/minidump/?sentry_key=e54f31f0134a4145acba03d4cdcb499f";
export const AMPLITUDE_ANALYTICS_KEY = "32e009df673ebf09a15266ed15c63aae";