export const SET_ACCOUNTS_COLOR_MAP = 'SET_ACCOUNTS_COLOR_MAP';
export const SET_ACCOUNTS_ACTION_STATUS_MAP = 'SET_ACCOUNTS_ACTION_STATUS_MAP';
export const GET_ACCOUNT_LIST = 'get_account_list';
export const ACCOUNT_UPDATE = 'account_update';
export const ACCOUNT_ADDED = 'account_added';
export const ADDED_ACCOUNT_RESET = 'added_account_reset';
export const NO_ACCOUNTS_PRESENT = 'no_accounts_present';

export const getAccountList = accountIds => ({
  type: GET_ACCOUNT_LIST,
  payload: { accountIds },
});

export const accountUpdated = (accountList, error) => ({
  type: ACCOUNT_ADDED,
  payload: { accountList, error },
});

export const setAccountColorMap = colorMap => ({
  type: SET_ACCOUNTS_COLOR_MAP,
  payload: { accountColorMap: colorMap },
});

export const setAccountActionStatusMap = actionStatusMap => ({
  type: SET_ACCOUNTS_ACTION_STATUS_MAP,
  payload: { accountActionStatusMap: actionStatusMap },
});

export const noAccountsPresent = () => ({
  type: NO_ACCOUNTS_PRESENT,
});
