import React from 'react';
import classnames from 'classnames';
// eslint-disable-next-line no-unused-vars
import Style from './style.css';

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen || false
    };
  }

  onToggleBody = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { headerText, children } = this.props;
    const { isOpen } = this.state;
    return (
      <div className={'accordion-box'}>
        <div className={classnames('accordion-head', 'mail')} onClick={this.onToggleBody}>
          <span>{headerText}</span>
          <div className={'accordion-arrow'}>
            <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M4.5 6.75L9 11.25L13.5 6.75'
                stroke='var(--icon-color)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        </div>
        {isOpen && <div className={'accordion-panel'}>{children}</div>}
      </div>
    );
  }
}

export default Accordion;
