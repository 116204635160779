import { BaseAPI, API } from './base-api';
import Utils from '../utils/common-utils';
import os from 'os';

export default class SignUp extends BaseAPI {
  constructor() {
    super(API.CREATE_ACCOUNT);
  }

  execute(email, password) {
    const payload = {};
    payload.username = email;
    payload.password = password;
    payload.udi = Utils.getMachineId();
    payload.model_name = os.platform();

    return super.connect(payload, null, false);
  }
}
