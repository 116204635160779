import { BaseAPI, API } from './base-api';

export default class ProfilePicsUrlListAPI extends BaseAPI {

  constructor() {
    super(API.PROFILE_PICS_URL_PATH);
  }

  execute() {
    return super.connect(null);
  }
}
