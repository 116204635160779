import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import './FullCalendar.scss';

export default class DemoApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEvents: [],
      isAddEventDrawer: true,
      calendarView: 'timeGridWeek',
      selectedDate: new Date()
    };
  }
  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible
    });
  };
  createEventId = () => {
    let eventGuid = 0;
    return String(eventGuid++);
  };
  handleDateSelect = (selectInfo) => {
    const { setEventDate } = this.props;
    const obj = {
      startDate: new Date(selectInfo.startStr),
      endDate: new Date(selectInfo.endStr)
    };
    setEventDate(obj);
  };
  handleEventClick = (clickInfo) => {
    if (clickInfo && clickInfo.event) {
      const { onEventClicked } = this.props;
      if (onEventClicked) {
        const obj = {
          id: clickInfo.event.id,
          startDate: new Date(clickInfo.event.startStr),
          endDate: new Date(clickInfo.event.endStr)
        };
        onEventClicked(obj);
      }
    }
  };
  handleEvents = (events) => {
    this.setState({
      currentEvents: events
    });
  };
  renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };
  onViewChange = (value) => {
    const { selectedDate } = this.state;
    if (value === 'DAY') {
      this.calendar.getApi().changeView('timeGridDay', selectedDate);
    } else if (value === 'WEEK') {
      this.calendar.getApi().changeView('timeGridWeek', selectedDate);
    } else if (value === 'MONTH') {
      this.calendar.getApi().changeView('dayGridMonth', selectedDate);
    }
  };

  render() {
    const { calendarView } = this.state;
    const { events } = this.props;
    return (
      <div className='calendar-app'>
        <FullCalendar
          ref={(input) => (this.calendar = input)}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          headerToolbar={false}
          initialView={calendarView}
          allDaySlot={false}
          nowIndicator={true}
          editable={true}
          selectable={true}
          selectMirror={true}
          // eventDataTransform={ }
          // eventClassNames={'event'}
          dayMaxEvents={true}
          expandRows={false}
          dayHeaderFormat={{ omitCommas: true, day: 'numeric', weekday: 'short' }}
          weekends={true}
          // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
          select={this.handleDateSelect}
          eventContent={this.renderEventContent} // custom render function
          eventClick={this.handleEventClick}
          eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        />
      </div>
    );
  }
}
