export const MESSAGE_STAR = 'star';
export const MESSAGE_UNSTAR = 'unstar';
export const MESSAGE_READ = 'read';
export const MESSAGE_UNREAD = 'unread';
export const MESSAGE_ARCHIVE = 'archive';
export const MESSAGE_DELETE = 'delete';
export const MESSAGE_MOVE = 'move_to';
export const MESSAGE_SEND_NOW = 'MESSAGE_SEND_NOW';
export const MESSAGE_SEND_DRAFT = 'MESSAGE_SEND_DRAFT';
export const FOLDER_NOT_SET = 'FOLDER_NOT_SET';
export const SET_ARCHIVE_FOLDER = 'SET_ARCHIVE_FOLDER';
export const GET_MOVE_DESTIONATION_FOLDERS = 'GET_MOVE_DESTIONATION_FOLDERS';
export const SET_MOVE_DESTIONATION_FOLDERS = 'SET_MOVE_DESTIONATION_FOLDERS';
export const ACTION_QUEUE_START = 'ACTION_QUEUE_START';
export const ACTION_QUEUE_STOP = 'ACTION_QUEUE_STOP';
export const CLEAR_FOLDER_SELECTION = 'CLEAR_FOLDER_SELECTION';
export const ACTION_TYPE_RESUME_RESUME_ACTION_QUEUE_PROCESSOR = 'resume_processor';
export const ACTION_TYPE_STAR = 'star';
export const ACTION_TYPE_UNSTAR = 'unstar';
export const ACTION_TYPE_REMINDER = 'reminder';
export const ACTION_TYPE_COMPOSE = 'compose';
export const ACTION_TYPE_REPLY = 'reply';
export const ACTION_TYPE_REPLY_ALL = 'reply_all';
export const ACTION_TYPE_FORWARD = 'forward';
export const ACTION_TYPE_MARK_READ = 'read';
export const ACTION_TYPE_MARK_UNREAD = 'unread';
export const ACTION_TYPE_ARCHIVE = 'archive';
export const ACTION_TYPE_DELETE = 'delete';
export const ACTION_TYPE_MOVE_TO_INBOX = 'move_to_inbox';
export const ACTION_TYPE_MOVETO = 'move_to';
export const ACTION_TYPE_SPAM = 'mark_spam';
export const ACTION_TYPE_UNMARK_SPAM = 'unmark_spam';
export const ACTION_TYPE_OUTBOX_ITEM_DELETE = 'outbox_item_delete';
export const ACTION_TYPE_SET_NOTIFICATION_DETAILS = 'notification_details';
export const ACTION_TYPE_VALIDATE_PAYMENT = 'validate_plan';
export const ACTION_TYPE_REPORT_ERROR = 'report_error';
export const ACTION_TYPE_CARD_DELETE = 'card_delete';
export const ACTION_TYPE_FOLDER_SYNC = 'folder_sync';
export const ACTION_TYPE_CREATE_DRAFT = 'create_draft';
export const ACTION_TYPE_EDIT_DRAFT = 'edit_draft';
export const ACTION_TYPE_EDIT_OUTBOX = 'edit_outbox';
export const ACTION_TYPE_SEND_DRAFT = 'send_draft';
export const ACTION_TYPE_DISCARD_DRAFT = 'discard_draft';
export const ACTION_TYPE_SENT = 'sent';
export const ACTION_TYPE_SET_PROFILE_INFO = 'profile_info';
export const ACTION_TYPE_PREFERENCE_SET = 'preference_set';
export const ACTION_TYPE_ALIAS_ADD = 'alias_add';
export const ACTION_TYPE_ALIAS_UPDATE = 'alias_update';
export const ACTION_TYPE_ALIAS_SET_REPLY_DEFAULT = 'alias_set_reply_default';
export const ACTION_TYPE_RSVP = 'rsvp';
export const ACTION_TYPE_UPDATE_CALENDAR_COLOR = 'update_calendar_color';
export const ACTION_TYPE_REMOTE_WIPE_COMPLETED = 'remote_wipe_completed';
export const ACTION_TYPE_ACCEPT_MEETING = 'accept_meeting_request';
export const ACTION_TYPE_DECLINE_MEETING = 'decline_meeting_request';
export const ACTION_TYPE_RSVP_EXCHANGE = 'rsvp_exchange';
export const ACTION_TYPE_INTERACTION_SUMMARY_RECEIPT = 'interaction_summary_receipt';
export const ACTION_LOCATION_INBOX = 'inbox';
export const ACTION_LOCATION_SEARCH = 'search';
export const ACTION_LOCATION_PREVIEW = 'preview';
export const ACTION_LOCATION_PUSH_NOTIFICATION = 'push_notification';
export const ACTION_LOCATION_WEARABLE = 'watch';
export const ACTION_LOCATION_NOTIFICATION = 'notification';
export const ACTION_LOCATION_UPGRADE = 'upgrade';
export const ACTION_TYPE_PURGE_SIGNATURE_ATTACHMENT = 'purge_signature_attachment';
export const ACTION_TYPE_CREATE_EVENT = 'create_event';
export const ACTION_TYPE_UPDATE_EVENT = 'update_event';
export const ACTION_TYPE_DELETE_EVENT = 'delete_event';
export const ACTION_TYPE_SNOOZE = 'snooze';
export const ACTION_TYPE_SNOOZE_UPDATE = 'snooze_update';
export const ACTION_TYPE_SNOOZE_CANCEL = 'snooze_cancel';
export const ACTION_TYPE_SNOOZE_RETURN = 'snooze_return';
export const ACTION_TYPE_TIMEZONE_UPDATE = 'timezone_update';
export const ACTION_TYPE_TRACK_NOTIFICATION_SET = 'email_track_notification_set';
export const ACTION_TYPE_CANCEL_SCHEDULE = 'cancel_schedule';
export const ACTION_TYPE_SEND_NOW = 'send_now';

export const ACTION_TYPE_MARK_ALL_READ = 'mark_all_read';

export const ACTION_TYPE_DELETE_SINGLE_MAIL = 'delete_single_mail';

export const ACTION_TYPE_OUTBOX_RESENT = 'outbox_resent';

export const CUSTOM_COMPOSE = 'custom_compose';

export const ALIAS_ADD = 'ALIAS_ADD';
export const ALIAS_UPDATE = 'ALIAS_UPDATE';
export const ALIAS_DELETE = 'ALIAS_DELETE';
export const ALIAS_REPLY_FROM = 'ALIAS_REPLY_FROM';
export const ALIAS_UPDATE_RESULT = 'ALIAS_UPDATE_RESULT';
export const ALIAS_FETCH = 'ALIAS_FETCH';
export const FOLDER_SYNC = 'FOLDER_SYNC';
export const FOLDER_NOTIFICATION = 'FOLDER_NOTIFICATION';
export const NEW_MAIL_NOTIFICATION = 'NEW_MAIL_NOTIFICATION';
export const PREFERENCE_SET = 'PREFERENCE_SET';
export const RELOAD_PREFERENCES_DATA_ACROSS_PROCESSES = 'RELOAD_PREFERENCES_DATA_ACROSS_PROCESSES';
export const RELOAD_PREFERENCES_DATA_ACROSS_PROCESSES_COMPLETED = 'RELOAD_PREFERENCES_DATA_ACROSS_PROCESSES_COMPLETED';

export const ACCOUNT_COLOR_CHANGE = 'ACCOUNT_COLOR_CHANGE';
export const FOCUSED_INBOX_TOGGLE = 'FOCUSED_INBOX_TOGGLE';
export const FOCUSED_INBOX_TOGGLE_RESULT = 'FOCUSED_INBOX_TOGGLE_RESULT';

export const UNIFIED_VIEW_TOGGLE = 'UNIFIED_VIEW_TOGGLE';
export const UNIFIED_VIEW_TOGGLE_RESULT = 'UNIFIED_VIEW_TOGGLE_RESULT';

export const CHECK_FOLDER_EXISTS_FOR_ACTION = 'CHECK_FOLDER_EXISTS_FOR_ACTION';
export const SET_FOLDER_FOR_ACTION = 'SET_FOLDER_FOR_ACTION';
export const LOAD_UNDO_SEND_MESSAGE = 'load_undo_send_message';

export const SET_TIMEZONE = 'set_timezone';

export const CARD_GALLERY_LIST = 'CARD_GALLERY_LIST';
export const CARD_GALLERY_LIST_RESPONSE = 'CARD_GALLERY_LIST_RESPONSE';
export const CARD_INSTALL = 'CARD_INSTALL';
export const CARD_INSTALLED = 'CARD_INSTALLED';
export const CARD_UNINSTALL = 'CARD_UNINSTALL';
export const CARD_UNINSTALLED = 'CARD_UNINSTALLED';

export const START_TRIAL = 'START_TRIAL';

export const REPORT_CRASH = 'REPORT_CRASH';

export const ACTION_TYPE_DELETE_AFTER_UNSUBSCRIBE = 'delete_after_unsubscribe';

export const ACTION_TYPE_EMPTY_FOLDER = 'empty_folder';

export const RECAP_VIEW_TOGGLE = 'recap_view_toggle';
export const RECAP_VIEW_TOGGLE_RESULT = 'recap_view_toggle_result';

export const ACTION_TYPE_NEW_TEMPLATE = 'new_template';
export const ACTION_TYPE_EDIT_TEMPLATE = 'edit_template';
export const ACTION_TYPE_SAVE_TEMPLATE = 'save_template';
export const ACTION_TYPE_LINKTRACK_NOTIFICATION_SET = 'link_track_notification_set';

const action = (type, conversations, folder, messageFolderInfo, isSearchResult, uiActionId = 0) =>
  ({
    type,
    payload: {
      conversations,
      folder,
      messageFolderInfo,
      isSearchResult,
      uiActionId,
    },
  });

export const messageMove =
  (conversations, sourceFolder, targetFolder, messageFolderInfo, isSearchResult, uiActionId) => ({
    type: MESSAGE_MOVE,
    payload: {
      conversations,
      sourceFolder,
      targetFolder,
      messageFolderInfo,
      isSearchResult,
      uiActionId,
    },
  });

export const markStar = (conversations, folder, messageFolderInfo, isSearchResult) =>
  action(MESSAGE_STAR, conversations, folder, messageFolderInfo, isSearchResult);

export const markUnstar = (conversations, folder, messageFolderInfo, isSearchResult) =>
  action(MESSAGE_UNSTAR, conversations, folder, messageFolderInfo, isSearchResult);

export const markRead = (conversations, folder, messageFolderInfo, isSearchResult) =>
  action(MESSAGE_READ, conversations, folder, messageFolderInfo, isSearchResult);

export const markUnread = (conversations, folder, messageFolderInfo, isSearchResult) =>
  action(MESSAGE_UNREAD, conversations, folder, messageFolderInfo, isSearchResult);

export const markArchive =
  (
    conversations, folder, messageFolderInfo,
    isSearchResult, actionId, referencedActionId = null,
  ) => ({
    type: MESSAGE_ARCHIVE,
    payload: {
      conversations,
      folder,
      messageFolderInfo,
      isSearchResult,
      uiActionId: actionId,
      referencedActionId,
    },
  });

export const markDelete = (conversations, folder, messageFolderInfo, isSearchResult, actionId) =>
  action(MESSAGE_DELETE, conversations, folder, messageFolderInfo, isSearchResult, actionId);

export const markSingleDelete = (conversations, folder, messageFolderInfo) =>
  action(ACTION_TYPE_DELETE_SINGLE_MAIL, conversations, folder, messageFolderInfo, false);

export const markSpam = (conversations, folder, messageFolderInfo, isSearchResult, actionId) =>
  action(ACTION_TYPE_SPAM, conversations, folder, messageFolderInfo, isSearchResult, actionId);

export const markNotSpam = (conversations, folder, messageFolderInfo, isSearchResult, actionId) =>
  action(
    ACTION_TYPE_UNMARK_SPAM, conversations,
    folder, messageFolderInfo, isSearchResult,
    actionId,
  );

export const performActionOnFolderSet = (uiAction, folders, accountId, actionPayload) => ({
  type: FOLDER_NOT_SET,
  payload: {
    uiAction,
    folders,
    accountId,
    actionPayload, // Action to be performed after folder selected
  },
});

export const onFolderCreatedForAction = (uiAction, folder, accountId, actionPayload) => ({
  type: FOLDER_NOT_SET,
  payload: {
    uiAction,
    folders: [folder],
    accountId,
    actionPayload, // Action to be performed after folder selected
  },
});

export const getMoveDestinationFolders = (conversations, folder) => ({
  type: GET_MOVE_DESTIONATION_FOLDERS,
  conversations,
  folder,
});

export const setMoveDestinationFolders = (uiAction, folders, payload) => ({
  type: SET_MOVE_DESTIONATION_FOLDERS,
  payload: {
    uiAction,
    folders,
    payload,
  },
});

export const setArchiveFolder = (folder, actionPayload) => ({
  type: SET_ARCHIVE_FOLDER,
  folder,
  actionPayload,
});

export const clearFolderSelection = () => ({
  type: CLEAR_FOLDER_SELECTION,
});

export const sendMessage =
  ({
    type, account, addresses, subject, content,
    attachments, reference, createNewConversation,
    superchargers, discardDraftPayload, isUnsubscribedMail,
    donotShowToast, isEditOutbox, archiveOnReply,
    showToastWithoutUndo,
  }) => ({
    type: ACTION_TYPE_COMPOSE,
    payload: {
      type,
      account,
      addresses,
      subject,
      content,
      attachments,
      reference,
      createNewConversation,
      superchargers,
      discardPreviousDraftPayload: discardDraftPayload,
      isUnsubscribedMail: isUnsubscribedMail || false,
      donotShowToast,
      isEditOutbox,
      archiveOnReply,
      showToastWithoutUndo,
    },
  });

export const sendScheduledMessage = (message, referenceConversation, currentSelectedFolder) => ({
  type: ACTION_TYPE_SEND_NOW,
  payload: {
    message,
    referenceConversation,
    currentSelectedFolder,
  },
});

export const checkFolderExistsForAction =
  (
    actionType, conversations, folder, messageFolderInfo,
    isSearchResult, lastMessageIdentifier = null,
  ) => ({
    type: CHECK_FOLDER_EXISTS_FOR_ACTION,
    payload: {
      actionType,
      conversations,
      folder,
      messageFolderInfo,
      isSearchResult,
      lastMessageIdentifier,
    },
  });

export const discardDraft = (
  conversations, folder, messageFolderInfo,
  isSearchResult, hasMoreDraftMessages, isSingleMessage,
) =>
  ({
    type: ACTION_TYPE_DISCARD_DRAFT,
    payload: {
      conversations,
      folder,
      messageFolderInfo,
      isSearchResult,
      hasMoreDraftMessages,
      isSingleMessage,
    },
  });

export const onCardGalleryListResponse = (cards, error = null) => ({
  type: CARD_GALLERY_LIST_RESPONSE,
  cards,
  error,
});

export const onCardInstalled = card => ({
  type: CARD_INSTALLED,
  card,
});

export const onCardUninstalled = cardId => ({
  type: CARD_UNINSTALLED,
  cardId,
});

export const snoozeConversation =
  (conversations, folder, isSearchResult, tsSnooze, uiActionId = 0) =>
    ({
      type: ACTION_TYPE_SNOOZE,
      payload: {
        conversations,
        folder,
        isSearchResult,
        tsSnooze,
        uiActionId,
      },
    });

export const snoozeReturn = payload => ({
  type: ACTION_TYPE_SNOOZE_RETURN,
  payload,
});

export const emptyFolder = (folder, lastMessageIdentifier, uiActionId) => ({
  type: ACTION_TYPE_EMPTY_FOLDER,
  payload: {
    folder,
    lastMessageIdentifier,
    uiActionId,
  },
});

export const saveMessageTemplate =
  ({
    type, folder_id, templateId, addresses, title, subject, content, attachments }) => ({
      type: ACTION_TYPE_SAVE_TEMPLATE,
      payload: {
        type,
        folder_id,
        templateId,
        title,
        addresses,
        subject,
        content,
        attachments
      },
    });