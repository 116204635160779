import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { reducer as toastrReducer } from 'toastr';
import { login } from './login';
import { conversations } from './conversations';
import { folders } from './folders';
import { accounts } from './accounts';
import Actions from '../actions';
import history from './../history'
import { calendar } from './calendar'

const appReducer = combineReducers({
  router: connectRouter(history),
  login,
  conversations,
  folders,
  toastr: toastrReducer,
  accounts,
  calendar
});

const flushReducer = combineReducers({
  conversations,
  folders,
  accounts
});

const rootReducer = (state, action) => {
  if (action.type === Actions.LOGOUT_SUCCESS) {
    state = (combineReducers({
      router: connectRouter(history),
      login,
      toastr: toastrReducer,
    }))({ router: state.router }, {});
  } else if (action.type === Actions.FLUSH_SUCCESS) {
    return Object.assign({}, state, flushReducer({}, action));
  }
  return appReducer(state, action);
};

export default rootReducer;
