import JSHelper from './jshelper';
import * as Constants from './constant';

const INVALID_FOLDER_ID = -999;
const getTimezoneOffsetInSeconds = () => -(new Date().getTimezoneOffset() * 60);

let singleton;
const singletonEnforcer = Symbol();
let CRASH_OCCURED = false;

const parseDataFile = (defaults) => {
  try {
    const settings = localStorage.getItem('UserPreferences');
    if (settings)
      return JSON.parse(settings);
    return {};
  } catch (error) {
    return defaults;
  }
};

class UserPreferences {
  constructor(opts) {
    this.defaults = opts.defaults;
    this.data = parseDataFile(opts.defaults);
  }

  get(key, defaultValue) {
    if (JSHelper.containsKey(this.data, key)) {
      return this.data[key];
    }
    return defaultValue;
  }

  save(settings) {
    localStorage.setItem('UserPreferences', JSON.stringify(settings))
  }

  set(key, value) {
    this.data = parseDataFile(this.defaults);
    this.data[key] = value;
    this.save(this.data);
  }

  remove(key) {
    delete this.data[key];
    this.save(this.data);
  }

  parseDataFile() {
    this.data = parseDataFile(this.defaults);
  }

  contains(key) {
    return Object.prototype.hasOwnProperty.call(this.data, key);
  }
}

export class SnoozeConstants {
  static get SnoozeWeekend() {
    return {
      SATURDAY_SUNDAY: 0,
      FRIDAY_SATURDAY: 1,
      THURSDAY_FRIDAY: 2,
      FRIDAY_ONLY: 3,
      SATURDAY_ONLY: 4,
      SUNDAY_ONLY: 5
    };
  }

  // Snooze Constants
  static get SNOOZE_LATER_DEFAULT_VALUE() {
    return 2;
  }
  static get SNOOZE_WEEKDAY_START_DEFAULT_VALUE() {
    return 600;
  }
  static get SNOOZE_AFTERNOON_DEFAULT_VALUE() {
    return 900;
  }
  static get SNOOZE_WEEKEND_DEFAULT_VALUE() {
    return SnoozeConstants.SnoozeWeekend.SATURDAY_SUNDAY;
  }
  static get SNOOZE_WEEKEND_START_DEFAULT_VALUE() {
    return 600;
  }
  static get SNOOZE_BACK_MARK_STAR_DEFAULT_VALUE() {
    return false;
  }
}

export default class UserPreferenceSingleton {
  static get SNOOZE_TIMEZONE_OFFSET() {
    return 'snooze_timezone_offset';
  }
  static get ADDON_META() {
    return 'addon_meta';
  }
  static get CARD_UPDATE_TS() {
    return 'ts_card_update';
  }
  static get APP_VERSION() {
    return 'app_version';
  }
  static get ATTACHMENT_CACHE_SIZE() {
    return 'attachment_cache_size';
  }
  static get MASTER_ATTACHMENT_DOWNLOAD_SETTING() {
    return 'master_attachment_download_settings';
  }
  static get CHANGE_LOG_VERSION() {
    return 'change_log_version';
  }
  static get WHATSNEW_VERSION() {
    return 'whatsnew_version';
  }
  static get WHATSNEW_VERSION_CODE() {
    return 'whatsnew_version_code';
  }
  static get LAST_GETCHANGE_WITH_WARMUP_TS() {
    return 'last_getchanges_warmup';
  }
  static get PROFILE_INFO_FETCHED() {
    return 'profile_info_fetched';
  }
  static get PREFERENCE_SETTINGS_FETCHED() {
    return 'preference_settings_fetched';
  }
  static get SNOOZE_SYNC_PENDING_ON_UPGRADE() {
    return 'snooze_sync_pending';
  }
  static get HAS_DESKTOP() {
    return 'has_desktop';
  }
  static get LAST_ACTION_TS() {
    return 'last_action_ts';
  }
  static get EMAIL() {
    return 'email';
  }
  static get APP_BROKEN_MSG() {
    return 'app_broken_msg';
  }
  static get SERVER_URL() {
    return 'server_url';
  }
  static get ACCESS_TOKEN() {
    return 'access_tokens';
  }
  static get USER_IDENTIFIER() {
    return 'user_identifier';
  }
  static get CM_USER_TYPE() {
    return 'cm_user_type';
  }
  static get DEFAULT_FROM_ADDRESS() {
    return 'default_from_address';
  }
  static get ALL_INBOXES() {
    return 'all_inboxes';
  }
  static get REPLY_ALL_ACTION() {
    return 'reply_all';
  }
  static get ACCOUNT_ORDER() {
    return 'account_order';
  }
  static get MARK_AS_READ_ON_ARCHIVE() {
    return 'mark_as_read_on_archive';
  }
  static get MARK_AS_READ_ON_STAR() {
    return 'mark_as_read_on_star';
  }
  static get DELETE_ACTION() {
    return 'delete_action';
  }
  static get ARCHIVE_ACTION() {
    return 'archive_action';
  }
  static get MARK_READ_ACTION() {
    return 'mark_read_action';
  }
  static get STAR_ACTION() {
    return 'star_action';
  }
  static get SPAM_ACTION() {
    return 'spam_action';
  }
  static get CARD_ORDER() {
    return 'card_order';
  }
  static get GO_TO_LIST_ON_PREVIEW_ACTION() {
    return 'go_to_list_on_preview_action';
  }
  static get IS_CONVERSATION_VIEW() {
    return 'is_conversation_view_enabled';
  }
  static get IS_SP_ENABLED_FROM_MAILTO_URL() {
    return 'show_senderprofile_card_for_email';
  }
  static get IS_SENDER_PROFILE_ENABLED() {
    return 'should_show_sender_profile';
  }
  static get HAS_USER_SEEN_RR_GDPR() {
    return 'has_user_seen_read_receipt_gdpr_message';
  }
  static get IS_RR_ENABLED() {
    return 'is_rr_enabled';
  }
  static get IS_RR_ENABLED_FOR_ALL_MAILS() {
    return 'enable_rr_for_every_mail';
  }
  static get IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS() {
    return 'enable_rr_notification_for_every_mail';
  }
  static get READ_RECEIPTS_NOTIFICATIONS_FREQUENCY() {
    return 'read_receipts_notifications_frequency';
  }

  // Link receipts changes @LinkChange
  static get IS_LR_ENABLED() {
    return 'is_link_track_enabled';
  }
  static get IS_LR_ENABLED_FOR_ALL_MAILS() {
    return 'enable_link_track_for_every_mail';
  }
  static get IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS() {
    return 'enable_link_track_notification_for_every_mail';
  }
  static get LINK_RECEIPTS_NOTIFICATIONS_FREQUENCY() {
    return 'link_track_notifications_frequency';
  }

  // end link receipt change

  // public email sharing

  static get IS_EMAIL_LINK_VISIBLE() {
    return 'pls_is_visible_limit';
  }
  static get IS_ATTACHMENT_VISIBLE() {
    return 'pls_show_attachment';
  }
  static get IS_EMAIL_ADDRESS_VISIBLE() {
    return 'pls_show_sender_address';
  }


  // end public email sharing

  static get PREFERENCE_SETTINGS_JSON() {
    return 'preference_settings_json';
  }
  static get INBOX_ACTION_SEQUENCE() {
    return 'mac_inbox_action_sequence';
  }
  static get TOOLBAR_ACTION_SEQUENCE() {
    return 'mac_toolbar_action_sequence';
  }
  static get USER_NICK_NAME() {
    return 'user_nick_name';
  }
  static get PROFILE_PIC_URL() {
    return 'profile_pic_url';
  }
  static get SHOW_NAV_DRAWER_ON_HOVER() {
    return 'show_nav_drawer_on_hover';
  }
  static get DOWNLOAD_LOCATION() {
    return 'download_location';
  }
  static get PROMPT_DOWNLOAD_LOCATION() {
    return 'prompt_download_location';
  }
  static get CURRENT_LOG_FILE() {
    return 'current_log_file';
  }
  static get QUICK_ACTION_READ_UNREAD() {
    return 'read_or_unread';
  }
  static get QUICK_ACTION_SPAM_UNSPAM() {
    return 'spam_or_unspam';
  }
  static get QUICK_ACTION_DELETE() {
    return 'delete';
  }
  static get QUICK_ACTION_MOVE_TO() {
    return 'move_to';
  }
  static get QUICK_ACTION_ARCHIVE() {
    return 'archive';
  }
  static get QUICK_ACTION_SNOOZE() {
    return 'snooze';
  }
  static get LAST_YELLOW_NOTIFICATION_API_CALL() {
    return 'last_yellow_notification_api_call';
  }
  static get SELECTED_ACCOUNT_ID() {
    return 'selected_account_id';
  }
  static get SELECTED_FOLDER_ID() {
    return 'selected_folder_id';
  }
  static get LOGGED_EXCEPTION() {
    return 'logged_exception';
  }
  static get SUBSCRIPTION_EXPIRY_TS() {
    return 'ts_expiry';
  }
  static get SUBSCRIPTION_BEGIN_TS() {
    return 'ts_begin';
  }
  static get SUBSCRIPTION_SIGNUP_TS() {
    return 'ts_signup';
  }
  static get SUBSCRIPTION_STATUS() {
    return 'subscription_status';
  }
  static get NEWTON_SUBSCRIPTION_STATUS() {
    return 'newton_subscription_status';
  }
  static get SUBSCRIPTION_PLAN_ID_STORE() {
    return 'subscription_plan_id_store';
  }
  static get SUBSCRIPTION_ADDON() {
    return 'subscription_addon';
  }
  static get SUBSCRIPTION_TRIAL_DURATION() {
    return 'subscription_trial_duration';
  }
  static get IS_BRICKED() {
    return 'is_bricked';
  }
  static get IS_GIFTED() {
    return 'is_gifted';
  }
  static get PREVIEW_TIP() {
    return 'preview_tip';
  }
  static get FOLDER_PANEL_TIP() {
    return 'folder_panel_tip';
  }
  static get NOTIFICATION_REGISTRATION_ID() {
    return 'notification_registration_id';
  }
  static get NOTIFICATION_REGISTERED_ON_SERVER() {
    return 'notification_registered_on_server';
  }
  static get INITIAL_MESSAGE_SYNC_COMPLETED() {
    return 'initial_message_sync_completed';
  }
  static get APP_VERSION_FOR_GET_CHANGE_ON_UPGRADE() {
    return 'app_version_for_get_change_on_upgrade';
  }
  static get SNOOZE_MARK_STAR() {
    return 'mark_star_on_snooze_return';
  }
  static get SNOOZE_LATER() {
    return 'snooze_later';
  }
  static get SNOOZE_WEEKDAY_STARTS_AT() {
    return 'snooze_weekday_starts_at';
  }
  static get SNOOZE_AFTERNOON() {
    return 'snooze_afternoon';
  }
  static get SNOOZE_WEEKEND() {
    return 'snooze_weekend';
  }
  static get SNOOZE_WEEKEND_STARTS_AT() {
    return 'snooze_weekend_starts_at';
  }
  static get IS_FIRST_UNSUBSCRIBE_MAIL_POPUP() {
    return 'is_first_unsubscribe_mail_popup';
  }
  static get SHARE_BODY_MSG() {
    return 'share_body_msg';
  }
  static get SHARE_SUBJECT_MSG() {
    return 'share_subject_msg';
  }
  static get MAIL_FOOTER_MSG() {
    return 'mail_footer_msg';
  }
  static get LAST_PROMOTION_MESSAGE_API_CALL() {
    return 'last_promotion_msg_api_call';
  }
  static get LAST_MESSAGE_LANDING_TIME() {
    return 'last_message_landing_time';
  }
  static get UNSEEN_MESSAGE_MAPPING() {
    return 'unseen_message_mapping';
  }
  static get SNOOZE_RESCHEDULED_TS() {
    return 'snooze_rescheduled_ts';
  }
  static get TEMP_ACCESS_TOKEN() {
    return 'temp_access_tokens';
  }
  static get TRIAL_PROMPT_DAY() {
    return 'trial_prompt_day';
  }
  static get SUBSCRIPTION_PROMPT_DAY() {
    return 'subscription_prompt_day';
  }
  static get FOLDER_SYNC_TIP_SHOWN() {
    return 'folder_sync_tip_shown';
  }
  static get SEARCH_ESC_TIP_VISIBLE() {
    return 'search_esc_tip_visible';
  }
  static get RR_TIP_SHOWN() {
    return 'rr_tip_shown';
  }
  static get SP_TIP_SHOWN() {
    return 'sp_tip_shown';
  }
  static get FOCUSED_INBOX_SETTINGS_SEEN() {
    return 'focused_inbox_settings_seen';
  }
  static get FOCUSED_INBOX_BANNER_CHECK_TS() {
    return 'focused_inbox_banner_check_ts';
  }
  static get DISABLE_SPELLCHECK() {
    return 'disable_spellcheck';
  }
  static get LAST_GETCHANGE_RESPONSE_TS() {
    return 'last_getchange_response_ts';
  }
  static get GETCHANGE_RESPONSE_STATE() {
    return 'getchange_response_state';
  }
  static get REPLY_AND_ARCHIVE() {
    return 'should_archive_after_reply';
  }
  static get REPLY_AND_ARCHIVE_DIALOG_SHOWN_IN_COMPOSE() {
    return 'reply_archive_dialog_shown_in_compose';
  }
  static get ATTACHMENT_CACHE_DELETION_TS() {
    return 'attachment_cache_deletion_ts';
  }
  static get IS_UNIFIED_VIEW() {
    return 'is_unified_view_enabled';
  }
  static get IS_UNIFIED_VIEW_SWITCH_REQUIRED() {
    return 'is_unified_view_switch_required';
  }
  static get IS_UV_TIP_DISSMISSED() {
    return 'is_uv_tip_dismissed';
  }
  static get UV_EPOCH_FOR_SENT_MAILS() {
    return 'uv_epoch_for_sent_mails';
  }
  static get FIRST_LOGIN_TS() {
    return 'first_login_ts';
  }
  static get IS_24HR_FORMAT() {
    return '24_hour_format';
  }
  static get INSIGHT_FIRST_USE() {
    return 'ts_message_insight_first_use';
  }
  static get HAS_USER_SEEN_RECAP_INITIAL_STATE() {
    return 'has_user_seen_recap_initial_state';
  }
  static get TS_SIGNUP() {
    return 'ts_signup';
  }
  static get LAST_INSIGHT_SYNC_TS() {
    return 'last_insight_sync_ts';
  }
  static get DAILY_DIGEST_NOTIFICATIONS_FOR_TODAY() {
    return 'daily_digest_notifications_for_recap';
  }
  static get SHOW_INSIGHT_NOTIFICATIONS_EVERYDAY() {
    return 'show_insight_notifications_everyday';
  }
  static get INSIGHT_NOTIFICATIONS_TIME() {
    return 'insight_notifications_time';
  }
  static get SHOW_INSIGHT_NOTIFICATIONS_FOR_ALL_INBOXES() {
    return 'show_insight_notifications_for_all_inboxes';
  }
  static get ACCOUNTS_FOR_DAILY_DIGEST_NOTIFICATION() {
    return 'accounts_for_daily_digest_notification';
  }
  static get IS_RECAP_ENABLED() {
    return 'is_recap_enabled';
  }
  static get HAS_USER_ACCEPTED_TOS() {
    return 'has_general_tos_accepted';
  }
  static get SELECTED_THEME() {
    return 'selected_theme';
  }

  static get MAIN_WINDOW_BOUNDS() {
    return 'main_window_bounds';
  }
  static get COUPEN_CODE() {
    return 'coupen_code';
  }

  static get WINDOW_ZOOM_LEVEL() {
    return 'window_zoom_level';
  }

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');

    this.userPreferences = new UserPreferences({
      configName: 'user-preferences',
      defaults: {
        windowBounds: { width: 800, height: 600 }
      }
    });
  }

  static getInstance() {
    if (!singleton) {
      singleton = new UserPreferenceSingleton(singletonEnforcer);
    }

    /* var globalSymbols = Object.getOwnPropertySymbols(global);
    console.log(global.sharedObj);

    var hasPrefs = (globalSymbols.indexOf(singletonEnforcer) > -1);

    console.log("user pref 2 - " + hasPrefs);
    console.log(global[singletonEnforcer]);
    if (!hasPrefs) {
      singleton = new UserPreferenceSingleton(singletonEnforcer);
      global[singletonEnforcer] = singleton;
    } else {
      singleton = global[singletonEnforcer];
    } */

    return singleton;
  }

  static set crashOccured(value) {
    CRASH_OCCURED = value;
  }

  static get crashOccured() {
    return CRASH_OCCURED;
  }

  get(key, defaultValue = null) {
    return this.userPreferences.get(key, defaultValue);
  }

  set(key, value) {
    this.userPreferences.set(key, value);
  }

  setLastActionTime(time) {
    this.userPreferences.set(UserPreferenceSingleton.LAST_ACTION_TS, time);
  }

  getLastActionTime() {
    return this.userPreferences.get(UserPreferenceSingleton.LAST_ACTION_TS, 0);
  }

  getIsConversationView() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_CONVERSATION_VIEW, true);
  }

  setIsConversationView(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_CONVERSATION_VIEW, value);
  }

  getUnencryptedEmail() {
    return this.userPreferences.get(UserPreferenceSingleton.EMAIL, '');
  }

  changeServerUrl(url) {
    this.userPreferences.set(UserPreferenceSingleton.SERVER_URL, url);
  }

  getServerUrl() {
    return this.userPreferences.get(UserPreferenceSingleton.SERVER_URL, null);
  }

  storeEmail(value) {
    this.userPreferences.set(UserPreferenceSingleton.EMAIL, value);
  }

  getEmail() {
    return this.userPreferences.get(UserPreferenceSingleton.EMAIL, '');
  }

  getUserIdentifier() {
    return this.userPreferences.get(UserPreferenceSingleton.USER_IDENTIFIER, '');
  }

  getDefaultFromAddress() {
    return this.userPreferences.get(UserPreferenceSingleton.DEFAULT_FROM_ADDRESS, '');
  }

  setDefaultFromAddress(value) {
    this.userPreferences.set(UserPreferenceSingleton.DEFAULT_FROM_ADDRESS, value);
  }

  allInboxesEnabled() {
    return this.userPreferences.get(UserPreferenceSingleton.ALL_INBOXES, true);
  }

  enableAllInboxes(value) {
    this.userPreferences.set(UserPreferenceSingleton.ALL_INBOXES, value);
  }

  static getPreferenceKey(userEmail, preferenceType) {
    return `${userEmail}_${preferenceType}`;
  }

  getReplyAllSetting(key) {
    return this.userPreferences.get(key, true);
  }

  setAccountsOrder(value) {
    this.userPreferences.set(UserPreferenceSingleton.ACCOUNT_ORDER, value);
  }

  getAccountsOrder() {
    return this.userPreferences.get(UserPreferenceSingleton.ACCOUNT_ORDER, []);
  }

  getMarkAsReadArchived() {
    return this.userPreferences.get(UserPreferenceSingleton.MARK_AS_READ_ON_ARCHIVE, false);
  }

  setMarkAsReadArchived(value) {
    this.userPreferences.set(UserPreferenceSingleton.MARK_AS_READ_ON_ARCHIVE, value);
  }

  getMarkAsReadStarred() {
    return this.userPreferences.get(UserPreferenceSingleton.MARK_AS_READ_ON_STAR, false);
  }

  setMarkAsReadStarred(value) {
    this.userPreferences.set(UserPreferenceSingleton.MARK_AS_READ_ON_STAR, value);
  }

  getDeleteNotificationAction() {
    return this.userPreferences.get(UserPreferenceSingleton.DELETE_ACTION, true);
  }

  getArchiveNotificationAction() {
    return this.userPreferences.get(UserPreferenceSingleton.ARCHIVE_ACTION, true);
  }

  getMarkAsReadNotificationAction() {
    return this.userPreferences.get(UserPreferenceSingleton.MARK_READ_ACTION, true);
  }

  getStarNotificationAction() {
    return this.userPreferences.get(UserPreferenceSingleton.STAR_ACTION, false);
  }

  getSpamNotificationAction() {
    return this.userPreferences.get(UserPreferenceSingleton.SPAM_ACTION, false);
  }

  getCardsOrder() {
    return this.userPreferences.get(UserPreferenceSingleton.CARD_ORDER, []);
  }

  setCardsOrder(value) {
    this.userPreferences.set(UserPreferenceSingleton.CARD_ORDER, value);
  }

  getGoToListOnPreviewAction() {
    return this.userPreferences.get(UserPreferenceSingleton.GO_TO_LIST_ON_PREVIEW_ACTION, false);
  }

  setGoToListOnPreviewAction(value) {
    this.userPreferences.set(UserPreferenceSingleton.GO_TO_LIST_ON_PREVIEW_ACTION, value);
  }

  isSPViewEnabledFromMailtoUrl() {
    return false;
  }

  enableSPViewFromMailtoUrl(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_SP_ENABLED_FROM_MAILTO_URL, value);
  }

  isSenderProfileEnabled() {
    return false;
  }

  enableSenderProfile(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_SENDER_PROFILE_ENABLED, value);
  }

  hasUserSeenRRGdpr() {
    return this.userPreferences.get(UserPreferenceSingleton.HAS_USER_SEEN_RR_GDPR, 0);
  }

  setUserSeenRRGdpr(value) {
    if (!this.hasUserSeenRRGdpr())
      this.userPreferences.set(UserPreferenceSingleton.HAS_USER_SEEN_RR_GDPR, value || Date.now());
  }

  isRREnabled() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_RR_ENABLED, true);
  }

  enableRR(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_RR_ENABLED, value);
  }

  isRREnabledForAllEmails() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_RR_ENABLED_FOR_ALL_MAILS, true);
  }

  enableRRForAllEmails(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_RR_ENABLED_FOR_ALL_MAILS, value);
  }

  isRRNotifyEnableForAllEmails() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS, false);
  }

  enableRRNotificationForAllEmails(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS, value);
  }

  setRRNotificationFrequency(value) {
    this.userPreferences.set(UserPreferenceSingleton.READ_RECEIPTS_NOTIFICATIONS_FREQUENCY, value);
  }

  getRRNotificationFrequency() {
    return 0;
  }

  // Link Receipts start @LinkChange

  isLREnabled() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_LR_ENABLED, true);
  }

  enableLR(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_LR_ENABLED, value);
  }

  isLREnabledForAllEmails() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_LR_ENABLED_FOR_ALL_MAILS, true);
  }

  enableLRForAllEmails(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_LR_ENABLED_FOR_ALL_MAILS, value);
  }

  isLRNotifyEnableForAllEmails() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS, false);
  }

  enableLRNotificationForAllEmails(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS, value);
  }

  setLRNotificationFrequency(value) {
    this.userPreferences.set(UserPreferenceSingleton.LINK_RECEIPTS_NOTIFICATIONS_FREQUENCY, value);
  }

  getLRNotificationFrequency() {
    return 0;
  }



  // public sharing email settings
  isEmailLinkVisible() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_EMAIL_LINK_VISIBLE, false);
  }

  enableLinkVisible(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_EMAIL_LINK_VISIBLE, value);
  }


  isEmailAttachmentVisible() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_ATTACHMENT_VISIBLE, false);
  }

  enableAttachmentVisible(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_ATTACHMENT_VISIBLE, value);
  }

  isEmailAAdddressVisible() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_EMAIL_ADDRESS_VISIBLE, false);
  }

  enableEmailAddressVisible(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_EMAIL_ADDRESS_VISIBLE, value);
  }

  // link rreceipts.............

  setPreferenceSettingsJson(json) {
    this.userPreferences.set(UserPreferenceSingleton.PREFERENCE_SETTINGS_JSON, json);
    // RR & LR update values
    if (json && json.general) {
      const preference = json.general;
      if (preference[UserPreferenceSingleton.IS_RR_ENABLED] !== null && preference[UserPreferenceSingleton.IS_RR_ENABLED] !== undefined && preference[UserPreferenceSingleton.IS_RR_ENABLED] !== '') {
        //console.log("going to set" + UserPreferenceSingleton.IS_RR_ENABLED + "value....", preference[UserPreferenceSingleton.IS_RR_ENABLED])
        this.enableRR(preference[UserPreferenceSingleton.IS_RR_ENABLED])
      }

      if (preference[UserPreferenceSingleton.IS_RR_ENABLED_FOR_ALL_MAILS] !== null && preference[UserPreferenceSingleton.IS_RR_ENABLED_FOR_ALL_MAILS] !== undefined && preference[UserPreferenceSingleton.IS_RR_ENABLED_FOR_ALL_MAILS] !== '') {
        //console.log("going to set" + UserPreferenceSingleton.IS_RR_ENABLED_FOR_ALL_MAILS + "value....", preference[UserPreferenceSingleton.IS_RR_ENABLED_FOR_ALL_MAILS])
        this.enableRRForAllEmails(preference[UserPreferenceSingleton.IS_RR_ENABLED_FOR_ALL_MAILS])
      }

      if (preference[UserPreferenceSingleton.IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS] !== null && preference[UserPreferenceSingleton.IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS] !== undefined && preference[UserPreferenceSingleton.IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS] !== '') {
        //console.log("going to set" + UserPreferenceSingleton.IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS + "value....", preference[UserPreferenceSingleton.IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS])
        this.enableRRNotificationForAllEmails(preference[UserPreferenceSingleton.IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS])
      }

      // LR update values
      if (preference[UserPreferenceSingleton.IS_LR_ENABLED] !== null && preference[UserPreferenceSingleton.IS_LR_ENABLED] !== undefined && preference[UserPreferenceSingleton.IS_LR_ENABLED] !== '') {
        //console.log("going to set" + UserPreferenceSingleton.IS_LR_ENABLED + "value....", preference[UserPreferenceSingleton.IS_LR_ENABLED])
        this.enableLR(preference[UserPreferenceSingleton.IS_LR_ENABLED])
      }

      if (preference[UserPreferenceSingleton.IS_LR_ENABLED_FOR_ALL_MAILS] !== null && preference[UserPreferenceSingleton.IS_LR_ENABLED_FOR_ALL_MAILS] !== undefined && preference[UserPreferenceSingleton.IS_LR_ENABLED_FOR_ALL_MAILS] !== '') {
        //console.log("going to set" + UserPreferenceSingleton.IS_LR_ENABLED_FOR_ALL_MAILS + "value....", preference[UserPreferenceSingleton.IS_LR_ENABLED_FOR_ALL_MAILS])
        this.enableLRForAllEmails(preference[UserPreferenceSingleton.IS_LR_ENABLED_FOR_ALL_MAILS])
      }

      if (preference[UserPreferenceSingleton.IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS] !== null && preference[UserPreferenceSingleton.IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS] !== undefined && preference[UserPreferenceSingleton.IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS] !== '') {
        //console.log("going to set" + UserPreferenceSingleton.IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS + "value....", preference[UserPreferenceSingleton.IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS])
        this.enableLRNotificationForAllEmails(preference[UserPreferenceSingleton.IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS])
      }

    }
  }

  getPreferenceSettingsJson() {
    return this.userPreferences.get(UserPreferenceSingleton.PREFERENCE_SETTINGS_JSON, []);
  }

  setInboxActionSequence(value) {
    this.userPreferences.set(UserPreferenceSingleton.INBOX_ACTION_SEQUENCE, value);
  }

  getInboxActionSequence() {
    return this.userPreferences.get(UserPreferenceSingleton.INBOX_ACTION_SEQUENCE, [
      UserPreferenceSingleton.QUICK_ACTION_DELETE,
      UserPreferenceSingleton.QUICK_ACTION_ARCHIVE,
      UserPreferenceSingleton.QUICK_ACTION_MOVE_TO,
      UserPreferenceSingleton.QUICK_ACTION_SNOOZE,
      UserPreferenceSingleton.QUICK_ACTION_READ_UNREAD
    ]);
  }

  setToolbarActionSequence(value) {
    this.userPreferences.set(UserPreferenceSingleton.TOOLBAR_ACTION_SEQUENCE, value);
  }

  getToolbarActionSequence() {
    return this.userPreferences.get(UserPreferenceSingleton.TOOLBAR_ACTION_SEQUENCE, [
      UserPreferenceSingleton.QUICK_ACTION_DELETE,
      UserPreferenceSingleton.QUICK_ACTION_SPAM_UNSPAM,
      UserPreferenceSingleton.QUICK_ACTION_ARCHIVE,
      UserPreferenceSingleton.QUICK_ACTION_MOVE_TO,
      UserPreferenceSingleton.QUICK_ACTION_SNOOZE,
      UserPreferenceSingleton.QUICK_ACTION_READ_UNREAD
    ]);
  }

  getUserNickName() {
    return this.userPreferences.get(UserPreferenceSingleton.USER_NICK_NAME, '');
  }

  getProfilePicUrl() {
    return this.userPreferences.get(UserPreferenceSingleton.PROFILE_PIC_URL, '');
  }

  getDownloadLocation() {
    // return this.userPreferences.get(UserPreferenceSingleton.DOWNLOAD_LOCATION, downloadLocation);
    return undefined;
  }

  setDownloadLocation(value) {
    this.userPreferences.set(UserPreferenceSingleton.DOWNLOAD_LOCATION, value);
  }

  getIsPromptDownloadLocation() {
    return this.userPreferences.get(UserPreferenceSingleton.PROMPT_DOWNLOAD_LOCATION, false);
  }

  setIsPromptDownloadLocation(value) {
    this.userPreferences.set(UserPreferenceSingleton.PROMPT_DOWNLOAD_LOCATION, value);
  }

  getIsShowDrawerOnHover() {
    return this.userPreferences.get(UserPreferenceSingleton.SHOW_NAV_DRAWER_ON_HOVER, true);
  }

  setIsShowDrawerOnHover(value) {
    this.userPreferences.set(UserPreferenceSingleton.SHOW_NAV_DRAWER_ON_HOVER, value);
  }

  setCurrentLogFile(fileName) {
    this.userPreferences.set(UserPreferenceSingleton.CURRENT_LOG_FILE, fileName);
  }

  getCurrentLogFile() {
    return this.userPreferences.get(UserPreferenceSingleton.CURRENT_LOG_FILE, Constants.LOG_FILE_NAME_1);
  }

  setSelectedAccountId(accountId) {
    this.userPreferences.set(UserPreferenceSingleton.SELECTED_ACCOUNT_ID, accountId);
  }

  getSelectedAccountId() {
    return this.userPreferences.get(UserPreferenceSingleton.SELECTED_ACCOUNT_ID, -1);
  }

  setSelectedFolderId(folderId) {
    this.userPreferences.set(UserPreferenceSingleton.SELECTED_FOLDER_ID, folderId);
  }

  getSelectedFolderId() {
    return this.userPreferences.get(UserPreferenceSingleton.SELECTED_FOLDER_ID, INVALID_FOLDER_ID);
  }

  setLoggedException(value) {
    this.userPreferences.set(UserPreferenceSingleton.LOGGED_EXCEPTION, value);
  }

  previewTipClicked() {
    this.userPreferences.set(UserPreferenceSingleton.PREVIEW_TIP, true);
  }

  isPreviewTipClicked() {
    return this.userPreferences.get(UserPreferenceSingleton.PREVIEW_TIP, false);
  }

  showFolderPanelTip() {
    return this.userPreferences.get(UserPreferenceSingleton.FOLDER_PANEL_TIP, true);
  }

  hideFolderPanelTip() {
    this.userPreferences.set(UserPreferenceSingleton.FOLDER_PANEL_TIP, false);
  }

  getLoggedException() {
    return this.userPreferences.get(UserPreferenceSingleton.LOGGED_EXCEPTION, '');
  }

  storeNotificationRegistrationId(id) {
    this.userPreferences.set(UserPreferenceSingleton.NOTIFICATION_REGISTRATION_ID, id);
  }

  getNotificationRegistrationId() {
    return this.userPreferences.get(UserPreferenceSingleton.NOTIFICATION_REGISTRATION_ID, '');
  }

  setNotificationRegisteredOnServer(isRegistered) {
    this.userPreferences.set(UserPreferenceSingleton.NOTIFICATION_REGISTERED_ON_SERVER, isRegistered);
  }

  isNotificationRegisteredOnServer() {
    return this.userPreferences.get(UserPreferenceSingleton.NOTIFICATION_REGISTERED_ON_SERVER, false);
  }

  setInitialMessageSyncCompleted(value) {
    this.userPreferences.set(UserPreferenceSingleton.INITIAL_MESSAGE_SYNC_COMPLETED, value);
  }

  isInitialMessageSyncCompleted() {
    return this.userPreferences.get(UserPreferenceSingleton.INITIAL_MESSAGE_SYNC_COMPLETED, false);
  }

  storeAppVersionForGetChange(value) {
    this.userPreferences.set(UserPreferenceSingleton.APP_VERSION_FOR_GET_CHANGE_ON_UPGRADE, value);
  }

  getStoredAppVersionForGetChange() {
    return this.userPreferences.get(UserPreferenceSingleton.APP_VERSION_FOR_GET_CHANGE_ON_UPGRADE, '');
  }

  getSnoozeTimezoneOffset() {
    return this.userPreferences.get(UserPreferenceSingleton.SNOOZE_TIMEZONE_OFFSET, getTimezoneOffsetInSeconds());
  }

  isSnoozeTimezoneOffsetPresent() {
    if (this.userPreferences.contains(UserPreferenceSingleton.SNOOZE_TIMEZONE_OFFSET)) {
      return true;
    }
    return false;
  }

  shouldMarkStarOnSnoozeBack() {
    return this.userPreferences.get(
      UserPreferenceSingleton.SNOOZE_MARK_STAR,
      SnoozeConstants.SNOOZE_BACK_MARK_STAR_DEFAULT_VALUE
    );
  }

  setMarkStarOnSnoozeBack(shouldMarkStar) {
    this.userPreferences.set(UserPreferenceSingleton.SNOOZE_MARK_STAR, shouldMarkStar);
  }

  getSnoozeLaterTime() {
    return this.userPreferences.get(UserPreferenceSingleton.SNOOZE_LATER, SnoozeConstants.SNOOZE_LATER_DEFAULT_VALUE);
  }

  setSnoozeLaterTime(snoozeLaterTime) {
    this.userPreferences.set(UserPreferenceSingleton.SNOOZE_LATER, snoozeLaterTime);
  }

  setSnoozeWeekdayStartsAtTime(weekdayStartsAt) {
    this.userPreferences.set(UserPreferenceSingleton.SNOOZE_WEEKDAY_STARTS_AT, weekdayStartsAt);
  }

  getSnoozeWeekdayStartsAt() {
    return this.userPreferences.get(
      UserPreferenceSingleton.SNOOZE_WEEKDAY_STARTS_AT,
      SnoozeConstants.SNOOZE_WEEKDAY_START_DEFAULT_VALUE
    );
  }

  setSnoozeAfternoonTime(snoozeAfternoonTime) {
    this.userPreferences.set(UserPreferenceSingleton.SNOOZE_AFTERNOON, snoozeAfternoonTime);
  }

  getSnoozeAfternoonTime() {
    return this.userPreferences.get(
      UserPreferenceSingleton.SNOOZE_AFTERNOON,
      SnoozeConstants.SNOOZE_AFTERNOON_DEFAULT_VALUE
    );
  }

  setSnoozeWeekend(snoozeWeekend) {
    this.userPreferences.set(UserPreferenceSingleton.SNOOZE_WEEKEND, snoozeWeekend);
  }

  getSnoozeWeekend() {
    return this.userPreferences.get(
      UserPreferenceSingleton.SNOOZE_WEEKEND,
      SnoozeConstants.SNOOZE_WEEKEND_DEFAULT_VALUE
    );
  }

  setSnoozeWeekendStarsAt(weekendStartsAt) {
    this.userPreferences.set(UserPreferenceSingleton.SNOOZE_WEEKEND_STARTS_AT, weekendStartsAt);
  }

  getSnoozeWeekendStartsAt() {
    return this.userPreferences.get(
      UserPreferenceSingleton.SNOOZE_WEEKEND_STARTS_AT,
      SnoozeConstants.SNOOZE_WEEKEND_START_DEFAULT_VALUE
    );
  }

  markFirstUnsubscribeMailPopup(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_FIRST_UNSUBSCRIBE_MAIL_POPUP, value);
  }

  isFirstUnsubscribeMailPopup() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_FIRST_UNSUBSCRIBE_MAIL_POPUP, true);
  }

  getShareBody() {
    return this.userPreferences.get(UserPreferenceSingleton.SHARE_BODY_MSG, '');
  }

  storeShareBody(body) {
    this.userPreferences.set(UserPreferenceSingleton.SHARE_BODY_MSG, body);
  }

  getShareSubject() {
    return this.userPreferences.get(UserPreferenceSingleton.SHARE_SUBJECT_MSG, '');
  }

  storeShareSubject(subject) {
    this.userPreferences.set(UserPreferenceSingleton.SHARE_SUBJECT_MSG, subject);
  }

  getMailFooter() {
    return this.userPreferences.get(UserPreferenceSingleton.MAIL_FOOTER_MSG, '');
  }

  storeMailFooter(footer) {
    this.userPreferences.set(UserPreferenceSingleton.MAIL_FOOTER_MSG, footer);
  }

  setLastPromotionalMsgApiTS(timestampInMillis) {
    this.userPreferences.set(UserPreferenceSingleton.LAST_PROMOTION_MESSAGE_API_CALL, timestampInMillis);
  }

  getLastPromotionalMsgApiTS() {
    return this.userPreferences.get(UserPreferenceSingleton.LAST_PROMOTION_MESSAGE_API_CALL, 0);
  }

  setUnseenMessageMapping(mapping) {
    this.userPreferences.set(UserPreferenceSingleton.UNSEEN_MESSAGE_MAPPING, mapping);
  }

  getUnseenMessageMapping() {
    return this.userPreferences.get(UserPreferenceSingleton.UNSEEN_MESSAGE_MAPPING, '[]');
  }

  setLastMessageLandingTime(ts) {
    this.userPreferences.set(UserPreferenceSingleton.LAST_MESSAGE_LANDING_TIME, ts);
  }

  getLastMessageLandingTime() {
    return this.userPreferences.get(UserPreferenceSingleton.LAST_MESSAGE_LANDING_TIME, 0);
  }

  setSnoozeRescheduledTS(ts) {
    this.userPreferences.set(UserPreferenceSingleton.SNOOZE_RESCHEDULED_TS, ts);
  }

  getSnoozeRescheduledTS() {
    return this.userPreferences.get(UserPreferenceSingleton.SNOOZE_RESCHEDULED_TS, 0);
  }

  getTrialPromptDay() {
    return this.userPreferences.get(UserPreferenceSingleton.TRIAL_PROMPT_DAY, 99999);
  }

  setTrialPromptDay(day) {
    day = typeof day === undefined ? 99999 : day;
    return this.userPreferences.set(UserPreferenceSingleton.TRIAL_PROMPT_DAY, day);
  }

  getSubscriptionPromptDay() {
    return this.userPreferences.get(UserPreferenceSingleton.SUBSCRIPTION_PROMPT_DAY, 99999);
  }

  setSubscriptionPromptDay(day) {
    day = typeof day === undefined ? 99999 : day;
    return this.userPreferences.set(UserPreferenceSingleton.SUBSCRIPTION_PROMPT_DAY, day);
  }

  isFolderSyncTipShown() {
    return this.userPreferences.get(UserPreferenceSingleton.FOLDER_SYNC_TIP_SHOWN, false);
  }

  markFolderSyncTipShown(value) {
    this.userPreferences.set(UserPreferenceSingleton.FOLDER_SYNC_TIP_SHOWN, value);
  }

  isSearchEscTipVisible() {
    return this.userPreferences.get(UserPreferenceSingleton.SEARCH_ESC_TIP_VISIBLE, true);
  }

  disableSearchEscTip() {
    this.userPreferences.set(UserPreferenceSingleton.SEARCH_ESC_TIP_VISIBLE, false);
  }

  setFocusedInboxSettingsSeen(value) {
    this.userPreferences.set(UserPreferenceSingleton.FOCUSED_INBOX_SETTINGS_SEEN, value);
  }

  isFocusedInboxSettingsSeen() {
    return this.userPreferences.get(UserPreferenceSingleton.FOCUSED_INBOX_SETTINGS_SEEN, false);
  }

  setFocusedInboxBannerCheckTS(ts) {
    this.userPreferences.set(UserPreferenceSingleton.FOCUSED_INBOX_BANNER_CHECK_TS, ts);
  }

  getFocusedInboxBannerCheckTS() {
    return this.userPreferences.get(UserPreferenceSingleton.FOCUSED_INBOX_BANNER_CHECK_TS, 0);
  }

  isSpellCheckDisabled() {
    return this.userPreferences.get(UserPreferenceSingleton.DISABLE_SPELLCHECK, false);
  }

  disableSpellCheck(disable) {
    this.userPreferences.set(UserPreferenceSingleton.DISABLE_SPELLCHECK, disable);
  }

  getReplyAndArchive() {
    return this.userPreferences.get(UserPreferenceSingleton.REPLY_AND_ARCHIVE, false);
  }

  setReplyAndArchive(value) {
    this.userPreferences.set(UserPreferenceSingleton.REPLY_AND_ARCHIVE, value);
  }

  isReplyAndArchivePopupShown() {
    return this.userPreferences.get(UserPreferenceSingleton.REPLY_AND_ARCHIVE_DIALOG_SHOWN_IN_COMPOSE, false);
  }

  setReplyAndArchivePopupShown() {
    this.userPreferences.set(UserPreferenceSingleton.REPLY_AND_ARCHIVE_DIALOG_SHOWN_IN_COMPOSE, true);
  }

  setAttachmentCacheDeletionTS(ts) {
    this.userPreferences.set(UserPreferenceSingleton.ATTACHMENT_CACHE_DELETION_TS, ts);
  }

  getAttachmentCacheDeletionTS() {
    return this.userPreferences.get(UserPreferenceSingleton.ATTACHMENT_CACHE_DELETION_TS, 0);
  }

  getIsUnifiedView() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_UNIFIED_VIEW, false);
  }

  setIsUnifiedView(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_UNIFIED_VIEW, value);
  }

  setIsUnifiedViewSwitchRequired(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_UNIFIED_VIEW_SWITCH_REQUIRED, value);
  }

  getIsUnifiedViewSwitchRequired() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_UNIFIED_VIEW_SWITCH_REQUIRED, false);
  }

  getIsUVTipDismissed() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_UV_TIP_DISSMISSED, false);
  }

  setIsUVTipDismissed(value) {
    this.userPreferences.set(UserPreferenceSingleton.IS_UV_TIP_DISSMISSED, value);
  }

  getUvEpochForSentMails() {
    return this.userPreferences.get(UserPreferenceSingleton.UV_EPOCH_FOR_SENT_MAILS, 0);
  }

  setUvEpochForSentMails(value) {
    this.userPreferences.set(UserPreferenceSingleton.UV_EPOCH_FOR_SENT_MAILS, value);
  }

  setFirstLoginTs(ts) {
    this.userPreferences.set(UserPreferenceSingleton.FIRST_LOGIN_TS, ts);
  }

  getFirstLoginTs() {
    return this.userPreferences.get(UserPreferenceSingleton.FIRST_LOGIN_TS, 0);
  }

  setIs24HrClock(is24Hr) {
    this.userPreferences.set(UserPreferenceSingleton.IS_24HR_FORMAT, is24Hr);
  }

  is24HrClock() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_24HR_FORMAT, true);
  }

  getInsightFirstUse() {
    return this.userPreferences.get(UserPreferenceSingleton.INSIGHT_FIRST_USE, 0);
  }

  hasUserSeenInsightScreen() {
    return this.userPreferences.get(UserPreferenceSingleton.HAS_USER_SEEN_RECAP_INITIAL_STATE, false);
  }

  setUserSeenInsightScreen() {
    this.userPreferences.set(UserPreferenceSingleton.HAS_USER_SEEN_RECAP_INITIAL_STATE, true);
  }

  setLastInsightSyncTime(tsSync) {
    this.userPreferences.set(UserPreferenceSingleton.LAST_INSIGHT_SYNC_TS, tsSync);
  }

  getLastInsightSyncTime() {
    return this.userPreferences.get(UserPreferenceSingleton.LAST_INSIGHT_SYNC_TS, 0);
  }

  getSignupTime() {
    return this.userPreferences.get(UserPreferenceSingleton.TS_SIGNUP, 0);
  }

  isDailyDigestNotificationForTodayEnabled() {
    return this.userPreferences.get(UserPreferenceSingleton.DAILY_DIGEST_NOTIFICATIONS_FOR_TODAY, true);
  }

  setDailyDigestNotificationForToday(enable) {
    this.userPreferences.set(UserPreferenceSingleton.DAILY_DIGEST_NOTIFICATIONS_FOR_TODAY, enable);
  }

  showInsightNotificationsEveryday() {
    return this.userPreferences.get(UserPreferenceSingleton.SHOW_INSIGHT_NOTIFICATIONS_EVERYDAY, true);
  }

  setShowInsightNotificationsEveryday(enable) {
    this.userPreferences.set(UserPreferenceSingleton.SHOW_INSIGHT_NOTIFICATIONS_EVERYDAY, enable);
  }

  getInsightNotificationTime() {
    return this.userPreferences.get(UserPreferenceSingleton.INSIGHT_NOTIFICATIONS_TIME, 300);
  }

  setInsightNotificationTime(notificationTime) {
    this.userPreferences.set(UserPreferenceSingleton.INSIGHT_NOTIFICATIONS_TIME, notificationTime);
  }

  showInsighsForAllInboxes() {
    return this.userPreferences.get(UserPreferenceSingleton.SHOW_INSIGHT_NOTIFICATIONS_FOR_ALL_INBOXES, false);
  }

  setShowInsightsForAllInboxes(enable) {
    this.userPreferences.set(UserPreferenceSingleton.SHOW_INSIGHT_NOTIFICATIONS_FOR_ALL_INBOXES, enable);
  }

  getAccountsForDigestNotification() {
    return this.userPreferences.get(UserPreferenceSingleton.ACCOUNTS_FOR_DAILY_DIGEST_NOTIFICATION, null);
  }

  setAccountsForDigestNotification(accountIds) {
    this.userPreferences.set(UserPreferenceSingleton.ACCOUNTS_FOR_DAILY_DIGEST_NOTIFICATION, accountIds);
  }

  isRecapEnabled() {
    return this.userPreferences.get(UserPreferenceSingleton.IS_RECAP_ENABLED, true);
  }

  setIsRecapEnabled(newVal) {
    this.userPreferences.set(UserPreferenceSingleton.IS_RECAP_ENABLED, newVal);
  }

  setHasUserAcceptedTos(newVal) {
    this.userPreferences.set(UserPreferenceSingleton.HAS_USER_ACCEPTED_TOS, newVal);
  }

  getSelectedTheme() {
    return this.userPreferences.get(UserPreferenceSingleton.SELECTED_THEME, 'system');
  }

  setSelectedTheme(theme) {
    this.userPreferences.set(UserPreferenceSingleton.SELECTED_THEME, theme);
  }

  hasUserAcceptedTos() {
    return this.userPreferences.get(UserPreferenceSingleton.HAS_USER_ACCEPTED_TOS, false);
  }

  parseDataFile() {
    this.userPreferences.parseDataFile();
  }

  remove(key) {
    this.userPreferences.remove(key);
  }

  setMainWindowBounds(windowBounds) {
    this.userPreferences.set(UserPreferenceSingleton.MAIN_WINDOW_BOUNDS, windowBounds);
  }
  getCoupenCode() {
    return this.userPreferences.get(UserPreferenceSingleton.COUPEN_CODE, null);
  }

  setCoupenCode(code) {
    this.userPreferences.set(UserPreferenceSingleton.COUPEN_CODE, code);
  }

  getWindowZoomLevel() {
    return this.userPreferences.get(UserPreferenceSingleton.WINDOW_ZOOM_LEVEL, 1.0);
  }

  setWindowZoomLevel(zoomLevel) {
    this.userPreferences.set(UserPreferenceSingleton.WINDOW_ZOOM_LEVEL, zoomLevel);
  }


  clearStoredUserData(clearAll) {
    // erases the saved login data of the user    
    this.remove(UserPreferenceSingleton.SNOOZE_TIMEZONE_OFFSET);
    this.remove(UserPreferenceSingleton.ADDON_META);
    this.remove(UserPreferenceSingleton.CARD_UPDATE_TS);
    this.remove(UserPreferenceSingleton.APP_VERSION);
    this.remove(UserPreferenceSingleton.ATTACHMENT_CACHE_SIZE);
    this.remove(UserPreferenceSingleton.MASTER_ATTACHMENT_DOWNLOAD_SETTING);
    this.remove(UserPreferenceSingleton.CHANGE_LOG_VERSION);
    this.remove(UserPreferenceSingleton.WHATSNEW_VERSION);
    this.remove(UserPreferenceSingleton.WHATSNEW_VERSION_CODE);
    this.remove(UserPreferenceSingleton.LAST_GETCHANGE_WITH_WARMUP_TS);
    this.remove(UserPreferenceSingleton.LAST_YELLOW_NOTIFICATION_API_CALL);
    this.remove(UserPreferenceSingleton.PROFILE_INFO_FETCHED);
    this.remove(UserPreferenceSingleton.PREFERENCE_SETTINGS_FETCHED);
    this.remove(UserPreferenceSingleton.SNOOZE_SYNC_PENDING_ON_UPGRADE);
    this.remove(UserPreferenceSingleton.HAS_DESKTOP);
    this.remove(UserPreferenceSingleton.LAST_ACTION_TS);
    this.remove(UserPreferenceSingleton.APP_BROKEN_MSG);
    this.remove(UserPreferenceSingleton.ACCESS_TOKEN);
    this.remove(UserPreferenceSingleton.DEFAULT_FROM_ADDRESS);
    this.remove(UserPreferenceSingleton.ALL_INBOXES);
    this.remove(UserPreferenceSingleton.REPLY_ALL_ACTION);
    this.remove(UserPreferenceSingleton.ACCOUNT_ORDER);
    this.remove(UserPreferenceSingleton.MARK_AS_READ_ON_ARCHIVE);
    this.remove(UserPreferenceSingleton.MARK_AS_READ_ON_STAR);
    this.remove(UserPreferenceSingleton.DELETE_ACTION);
    this.remove(UserPreferenceSingleton.ARCHIVE_ACTION);
    this.remove(UserPreferenceSingleton.MARK_READ_ACTION);
    this.remove(UserPreferenceSingleton.STAR_ACTION);
    this.remove(UserPreferenceSingleton.SPAM_ACTION);
    this.remove(UserPreferenceSingleton.CARD_ORDER);
    this.remove(UserPreferenceSingleton.GO_TO_LIST_ON_PREVIEW_ACTION);
    this.remove(UserPreferenceSingleton.IS_CONVERSATION_VIEW);
    this.remove(UserPreferenceSingleton.IS_SP_ENABLED_FROM_MAILTO_URL);
    this.remove(UserPreferenceSingleton.IS_SENDER_PROFILE_ENABLED);
    this.remove(UserPreferenceSingleton.IS_RR_ENABLED_FOR_ALL_MAILS);
    this.remove(UserPreferenceSingleton.IS_RR_NOTIFICATION_ENABLED_FOR_ALL_MAILS);
    this.remove(UserPreferenceSingleton.READ_RECEIPTS_NOTIFICATIONS_FREQUENCY);
    this.remove(UserPreferenceSingleton.PREFERENCE_SETTINGS_JSON);
    this.remove(UserPreferenceSingleton.USER_NICK_NAME);
    this.remove(UserPreferenceSingleton.PROFILE_PIC_URL);
    this.remove(UserPreferenceSingleton.CM_USER_TYPE);
    this.remove(UserPreferenceSingleton.USER_IDENTIFIER);
    this.remove(UserPreferenceSingleton.CURRENT_LOG_FILE);
    this.remove(UserPreferenceSingleton.SELECTED_ACCOUNT_ID);
    this.remove(UserPreferenceSingleton.SELECTED_FOLDER_ID);
    this.remove(UserPreferenceSingleton.SUBSCRIPTION_EXPIRY_TS);
    this.remove(UserPreferenceSingleton.SUBSCRIPTION_BEGIN_TS);
    this.remove(UserPreferenceSingleton.SUBSCRIPTION_SIGNUP_TS);
    this.remove(UserPreferenceSingleton.SUBSCRIPTION_STATUS);
    this.remove(UserPreferenceSingleton.NEWTON_SUBSCRIPTION_STATUS);
    this.remove(UserPreferenceSingleton.SUBSCRIPTION_PLAN_ID_STORE);
    this.remove(UserPreferenceSingleton.SUBSCRIPTION_ADDON);
    this.remove(UserPreferenceSingleton.SUBSCRIPTION_TRIAL_DURATION);
    this.remove(UserPreferenceSingleton.IS_BRICKED);
    this.remove(UserPreferenceSingleton.IS_GIFTED);
    this.remove(UserPreferenceSingleton.NOTIFICATION_REGISTRATION_ID);
    this.remove(UserPreferenceSingleton.NOTIFICATION_REGISTERED_ON_SERVER);
    this.remove(UserPreferenceSingleton.INITIAL_MESSAGE_SYNC_COMPLETED);
    this.remove(UserPreferenceSingleton.SNOOZE_MARK_STAR);
    this.remove(UserPreferenceSingleton.SNOOZE_LATER);
    this.remove(UserPreferenceSingleton.SNOOZE_WEEKEND);
    this.remove(UserPreferenceSingleton.SNOOZE_WEEKDAY_STARTS_AT);
    this.remove(UserPreferenceSingleton.SNOOZE_AFTERNOON);
    this.remove(UserPreferenceSingleton.SNOOZE_WEEKEND_STARTS_AT);
    this.remove(UserPreferenceSingleton.SHARE_BODY_MSG);
    this.remove(UserPreferenceSingleton.SHARE_SUBJECT_MSG);
    this.remove(UserPreferenceSingleton.MAIL_FOOTER_MSG);
    this.remove(UserPreferenceSingleton.LAST_PROMOTION_MESSAGE_API_CALL);
    this.remove(UserPreferenceSingleton.UNSEEN_MESSAGE_MAPPING);
    this.remove(UserPreferenceSingleton.LAST_MESSAGE_LANDING_TIME);
    this.remove(UserPreferenceSingleton.SNOOZE_RESCHEDULED_TS);
    this.remove(UserPreferenceSingleton.TRIAL_PROMPT_DAY);
    this.remove(UserPreferenceSingleton.SUBSCRIPTION_PROMPT_DAY);
    this.remove(UserPreferenceSingleton.FOLDER_SYNC_TIP_SHOWN);
    this.remove(UserPreferenceSingleton.SEARCH_ESC_TIP_VISIBLE);
    this.remove(UserPreferenceSingleton.LAST_GETCHANGE_RESPONSE_TS);
    this.remove(UserPreferenceSingleton.GETCHANGE_RESPONSE_STATE);
    this.remove(UserPreferenceSingleton.REPLY_AND_ARCHIVE);
    this.remove(UserPreferenceSingleton.ATTACHMENT_CACHE_DELETION_TS);
    this.remove(UserPreferenceSingleton.IS_UNIFIED_VIEW);
    this.remove(UserPreferenceSingleton.IS_UNIFIED_VIEW_SWITCH_REQUIRED);
    this.remove(UserPreferenceSingleton.UV_EPOCH_FOR_SENT_MAILS);
    this.remove(UserPreferenceSingleton.IS_24HR_FORMAT);
    this.remove(UserPreferenceSingleton.INSIGHT_FIRST_USE);
    this.remove(UserPreferenceSingleton.HAS_USER_SEEN_RECAP_INITIAL_STATE);
    this.remove(UserPreferenceSingleton.HAS_USER_SEEN_RR_GDPR);
    this.remove(UserPreferenceSingleton.TS_SIGNUP);
    this.remove(UserPreferenceSingleton.LAST_INSIGHT_SYNC_TS);
    this.remove(UserPreferenceSingleton.DAILY_DIGEST_NOTIFICATIONS_FOR_TODAY);
    this.remove(UserPreferenceSingleton.SHOW_INSIGHT_NOTIFICATIONS_EVERYDAY);
    this.remove(UserPreferenceSingleton.INSIGHT_NOTIFICATIONS_TIME);
    this.remove(UserPreferenceSingleton.SHOW_INSIGHT_NOTIFICATIONS_FOR_ALL_INBOXES);
    this.remove(UserPreferenceSingleton.ACCOUNTS_FOR_DAILY_DIGEST_NOTIFICATION);
    this.remove(UserPreferenceSingleton.IS_RECAP_ENABLED);
    this.remove(UserPreferenceSingleton.HAS_USER_ACCEPTED_TOS);
    this.remove(UserPreferenceSingleton.COUPEN_CODE);

    // link receipts     @LinkChange
    this.remove(UserPreferenceSingleton.IS_LR_ENABLED_FOR_ALL_MAILS);
    this.remove(UserPreferenceSingleton.IS_LR_NOTIFICATION_ENABLED_FOR_ALL_MAILS);
    this.remove(UserPreferenceSingleton.HAS_USER_SEEN_LR_GDPR);

    // this.remove(UserPreferenceSingleton.SELECTED_THEME);

    // clearAll flag will set to false when only session changes from free user to paid user.
    // We don't have to clear them when session changes.
    if (clearAll) {
      /* this.remove(UserPreferenceSingleton.RINGTONES_ADDED_NEW);
        this.remove(UserPreferenceSingleton.RINGTONE_PERMISSION_REQUEST_TS);
        this.remove(UserPreferenceSingleton.RINGTONE_PERMISSION_TIP_COUNTER);
        this.remove(UserPreferenceSingleton.SHOW_NAVIGATION_DRAWER_GUIDE);
        this.remove(UserPreferenceSingleton.PRO_REASONS_CACHE); */
      /* this.remove(UserPreferenceSingleton.NEWTON_PLAN_ID_KEY);
        this.remove(UserPreferenceSingleton.PAYMENT_STATE_KEY); */
    }
  }
}
