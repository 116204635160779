export const SYNC_START = 'SYNC_START';
export const EMAIL_SYNC_START = 'EMAIL_SYNC_START';
export const INSIGHT_SYNC_START = 'INSIGHT_SYNC_START';
export const CARD_SYNC_START = 'CARD_SYNC_START';
export const CALENDAR_SYNC_START = 'CALENDAR_SYNC_START';
export const FOLDER_EMAIL_SYNC_START = 'FOLDER_EMAIL_SYNC_START';
export const ACCOUNT_N_FOLDER_SYNC_COMPLETE = 'ACCOUNT_N_FOLDER_SYNC_COMPLETE';
export const PEOPLE_SYNC_START = 'PEOPLE_SYNC_START';
export const START_GET_CHANGES = 'START_GET_CHANGES';
export const FORCE_REFRESH = 'FORCE_REFRESH';
export const FORCE_REFRESH_DONE = 'FORCE_REFRESH_DONE';
export const RESET_FORCE_REFRESH = 'RESET_FORCE_REFRESH';
export const PROCESS_SYNC_QUEUE = 'PROCESS_SYNC_QUEUE';
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';
export const NON_SYNCED_SNOOZED_EMAIL_SYNC_START = 'NON_SYNCED_SNOOZED_EMAIL_SYNC_START';
export const FETCH_PROMOTION_MESSAGE = 'FETCH_PROMOTION_MESSAGE';
export const START_DELTA_SYNC = 'START_DELTA_SYNC';

export const sync = isWarmup => ({ type: SYNC_START, isWarmup });

export const startGetChanges = payload => ({
  type: START_GET_CHANGES,
  payload,
});

export const onForceRefreshDone = errorMessage => ({
  type: FORCE_REFRESH_DONE,
  error: errorMessage,
});

export const startRefresh = payload => ({
  type: FORCE_REFRESH,
  payload,
});

export const resetForceRefresh = () => ({
  type: RESET_FORCE_REFRESH,
});

export const onNotificationReceived = payload => ({
  type: NOTIFICATION_RECEIVED,
  payload,
});
