import { BaseAPI, API } from './base-api';

export default class PreferenceSetApi extends BaseAPI {
  constructor() {
    super(API.PREFERENCE_SET);
  }

  execute(preferenceJson) {
    const postParams = {};
    postParams.changes = JSON.stringify(preferenceJson);
    return super.connect(postParams);
  }
}