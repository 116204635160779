import Actions from '../actions';

export const conversations = (state = {}, action) => {
  switch (action.type) {
    case Actions.SET_BULK_REQUEST:
      return Object.assign({}, state, {
        bulk: { requests: action.payload.requests },
      });
    case Actions.UPDATE_BULK_REQUEST: {
      const bulk = state.bulk || {};
      const copyRequests = Object.assign({}, bulk.requests);
      copyRequests[action.payload.request.folder.id] = action.payload.request;
      return Object.assign({}, state, {
        bulk: { requests: copyRequests },
      });
    }
    case Actions.SET_SELECTED_FOLDER:
      return Object.assign({}, state, {
        api: { syncHash: '', hasMore: true },
        db: { hasMore: true, offset: 0 },
        bulk: {},
        folderSelection: {},
        conversations: [],
        selection: {},
        undoState: {},
        listError: undefined,
        filter: undefined,
        search: {
          query: '',
          selectedSearchAccount: null,
          suggestions: null,
        },
        conversationUpdates: null,
        lastMessageIdentifier: null,
        showInsights: !!action.payload.showInsights,
        insightsBlueDot: false,
      });
    case Actions.CLEAR_LIST:
      return Object.assign({}, state, {
        api: { syncHash: '', hasMore: true },
        db: { hasMore: true, offset: 0 },
        bulk: {},
        filter: undefined,
        folderSelection: {},
        conversations: [],
        selection: {},
        undoState: {},
        listError: undefined,
        search: {
          query: '',
          selectedSearchAccount: (state.search && state.search.selectedSearchAccount ?
            state.search.selectedSearchAccount : null),
          suggestions: null,
        },
        conversationUpdates: null,
        lastMessageIdentifier: null,
        showInsights: false,
        insightsBlueDot: false,
      });
    case Actions.CLEAR_SEARCH_RESULT:
      return Object.assign({}, state, {
        api: { syncHash: '', hasMore: true },
        db: { hasMore: true, offset: 0 },
        listError: undefined,
        conversationUpdates: null,
      });
    case Actions.POST_DB_CONVERSATIONS:
      return Object.assign({}, state, {
        conversations: action.conversations,
        isFetching: false,
        db: {
          offset: action.offset,
          hasMore: action.dbHasMore,
        },
      });
    case Actions.MESSAGE_LIST_RESPONSE:
      return Object.assign({}, state, {
        conversations: action.conversations,
        isFetching: action.isFetching,
        lastMessageIdentifier: action.lastMessageIdentifier,
        api: {
          syncHash: action.syncHash,
          hasMore: action.hasMore,
        },
        db: {
          hasMore: false,
        },
      });
    case Actions.SEARCH_ACCOUNT_LIST_RESPONSE:
      return Object.assign({}, state, {
        isFetching: false,
        api: { syncHash: '', hasMore: false },
        search: {
          ...state.search,
          searchAccountList: action.searchAccountList,
        },
      });
    case Actions.REQUEST_CONVERSATIONS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case Actions.SET_CONVERSATIONS:
      return Object.assign({}, state, {
        conversations: action.payload.conversations.slice(),
        refreshView: true,
        isFromSync: action.payload.isFromSync,
      });
    case Actions.REMOVE_CONVERSATIONS: {
      const stateConversations = state.conversations;
      const { payload } = action;
      if (stateConversations && payload) {
        payload.conversations.forEach((conv) => {
          const indexToRemove = stateConversations.findIndex(stateConv => stateConv.equals(conv));
          if (indexToRemove !== -1) {
            stateConversations.splice(indexToRemove, 1);
          }
        });
      }
      return Object.assign({}, state, {
        conversations: stateConversations,
      });
    }
    case Actions.FORCE_REFRESH:
      return Object.assign({}, state, {
        forceRefresh: {
          isForceRefreshDone: false,
          errorMessage: null,
        },
      });
    case Actions.FORCE_REFRESH_DONE:
      return Object.assign({}, state, {
        forceRefresh: {
          isForceRefreshDone: true,
          errorMessage: action.error,
        },
      });
    case Actions.RESET_FORCE_REFRESH:
      return Object.assign({}, state, {
        forceRefresh: {
          ...state.forceRefresh,
          errorMessage: null,
        },
      });
    case Actions.FOLDER_NOT_SET:
      return Object.assign({}, state, {
        folderSelection: {
          uiAction: action.payload.uiAction,
          folders: action.payload.folders,
          accountId: action.payload.accountId,
          actionPayload: action.payload.actionPayload,
        },
      });
    case Actions.SET_MOVE_DESTIONATION_FOLDERS:
      return Object.assign({}, state, {
        folderSelection: {
          uiAction: action.payload.uiAction,
          folders: action.payload.folders,
          payload: action.payload.payload,
        },
      });
    case Actions.CLEAR_FOLDER_SELECTION:
      return Object.assign({}, state, {
        folderSelection: {},
      });
    case Actions.SET_SELETED_CONVERSATIONS:
      return Object.assign({}, state, {
        selection: {
          ...state.selection,
          selectedConversations: action.payload.selectedConversations,
          actionsSupported: action.payload.actionsSupported,
        },
      });
    case Actions.SET_MENU_ACTIONS:
      return {
        ...state,
        menuActions: action.actions,
      };
    case Actions.SET_CURRENT_SELECTION:
      return {
        ...state,
        selection: {
          ...state.selection,
          currentSelection: action.payload.conversation,
          currentSelectionIndex: action.payload.index,
        },
      };
    case Actions.SET_ACTIONS_SUPPORTED:
      return Object.assign({}, state, {
        selection: {
          ...state.selection,
          actionsSupported: action.payload.actionsSupported,
        },
      });
    case Actions.ADD_TO_UNDO_STATE: {
      const { currentSelectionIndex } = state.selection;
      return {
        ...state,
        undoState: {
          ...state.undoState,
          action: action.payload,
        },
        selection: {
          currentSelectionIndex,
        },
      };
    }
    case Actions.MARK_UNDO_STATE_DIRTY: {
      const newState = Object.assign({}, state);
      if (!newState.undoState.dirty) {
        newState.undoState.dirty = {};
      }
      newState.undoState.dirty[newState.undoState.action.actionId] = newState.undoState.action;
      newState.undoState.action = {};
      return newState;
    }
    case Actions.CLEAR_UNDO_ACTION: {
      return Object.assign({}, state, {
        undoState: {
          ...state.undoState,
          action: {},
        },
      });
    }
    case Actions.REMOVE_FROM_UNDO_STATE: {
      // return state;
      const newState = Object.assign({}, state);
      if (newState.undoState.dirty && action.actionId) {
        if (newState.undoState.dirty[action.actionId]) {
          const { payload } = newState.undoState.dirty[action.actionId];
          const stateConversations = newState.conversations;
          if (stateConversations && stateConversations.length > 0 &&
            payload && payload.conversations && payload.conversations.length > 0) {
            payload.conversations.forEach((conv) => {
              const indexToRemove =
                stateConversations.findIndex(stateConv => stateConv.equals(conv));
              if (indexToRemove !== -1) {
                stateConversations.splice(indexToRemove, 1);
              }
            });
          }
        }
        delete newState.undoState.dirty[action.actionId];
      }
      return newState;
    }
    case Actions.SET_UNDO_STATE:
      return Object.assign({}, state, {
        undoState: action.payload.undoState,
        selection: {},
      });
    case Actions.SEARCH_INITIALIZE:
      return Object.assign({}, state, {
        api: { syncHash: '', hasMore: true },
        db: { hasMore: true, offset: 0 },
        bulk: {},
        filter: undefined,
        folderSelection: {},
        selection: {},
        listError: undefined,
        search: {
          query: action.payload.query,
          selectedSearchAccount: (state.search && state.search.selectedSearchAccount ?
            state.search.selectedSearchAccount : null),
          suggestions: action.payload.query && action.payload.query.length > 0 ?
            (state.search ? state.search.suggestions : null) : null,
        },
        conversationUpdates: null,
        lastMessageIdentifier: null,
        showInsights: false,
      });
    case Actions.SET_SELECTED_SEARCH_ACCOUNT:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          selectedSearchAccount: action.payload.selectedSearchAccount,
          searchAccountList: null,
        },
        conversations: [],
      });
    case Actions.SEARCH_SUGGESTIONS:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          suggestions: action.payload.suggestions,
        },
      });
    case Actions.UPDATE_FILTER:
      return Object.assign({}, state, {
        filter: action.payload.filter,
        conversations: [],
        api: { syncHash: '', hasMore: true },
        db: { hasMore: true, offset: 0 },
        bulk: {},
        folderSelection: {},
        selection: {},
        undoState: {},
        listError: undefined,
        showInsights: false,
      });
    case Actions.REQUEST_MORE_CONVERSATIONS:
      return Object.assign({}, state, {
        requestMore: true,
      });
    case Actions.REFRESH_LIST_VIEW:
      return Object.assign({}, state, {
        refreshView: action.refreshView,
      });
    case Actions.FLUSH:
      return Object.assign({}, state, {
        flushRequested: true,
      });
    case Actions.UPDATE_ERROR:
      return {
        ...state,
        listError: action.error,
      };
    case Actions.RESET_FLUSH_FLAG:
      return Object.assign({}, state, {
        flushRequested: false,
      });
    case Actions.CLEAR_ACTIVE_UNDO_TOAST_EXTRAS: {
      const newState = {
        ...state,
      };
      if (newState.undoToastExtras) {
        newState.undoToastExtras.active = 0;
      }
      return newState;
    }
    case Actions.REMOVE_UNDO_TOAST_EXTRAS: {
      const newState = {
        ...state,
      };
      if (newState.undoToastExtras) {
        if (newState.undoToastExtras.active === action.id) {
          newState.undoToastExtras.active = 0;
        }
        delete newState.undoToastExtras[action.id];
      }
      return newState;
    }
    case Actions.SET_UNDO_TOAST_EXTRAS: {
      const newState = {
        ...state,
        undoToastExtras: {
          ...state.undoToastExtras,
        },
      };
      const key = action.extras.actionQueueId;
      newState.undoToastExtras.active = key;
      newState.undoToastExtras[key] = action.extras;
      return newState;
    }
    case Actions.UNDO_SEND_MESSAGE: {
      return {
        ...state,
        undoSendInfo: action.payload,
      };
    }
    case Actions.RESET_UNDO_SEND_MESSAGE: {
      return {
        ...state,
        undoSendInfo: undefined,
      };
    }
    case Actions.SHOW_WAIT_LOADER: {
      return {
        ...state,
        conversationUpdates: {
          showWaitLoader: action.payload.showWaitLoader,
          changes: action.payload.showWaitLoader ?
            (state.conversationUpdates ? state.conversationUpdates.changes : null) : null,
        },
      };
    }
    case Actions.CACHE_CONVERSATION_CHANGES: {
      return {
        ...state,
        conversationUpdates: {
          ...state.conversationUpdates,
          changes: action.payload.changes,
        },
      };
    }
    case Actions.UPDATE_INSIGHTS: {
      let insightsBlueDot = state.insightBlueDot;
      if (action.showInsights) {
        insightsBlueDot = false;
      }
      return {
        ...state,
        filter: undefined,
        conversations: [],
        api: { syncHash: '', hasMore: true },
        db: { hasMore: true, offset: 0 },
        bulk: {},
        folderSelection: {},
        selection: {},
        undoState: {},
        listError: undefined,
        showInsights: action.showInsights,
        insightsBlueDot,
      };
    }
    case Actions.UPDATE_INSIGHTS_BLUE_DOT: {
      return {
        ...state,
        insightsBlueDot: action.newInsights,
      };
    }
    default:
      return state;
  }
};
