import { BaseAPI, API } from './base-api';

export default class FetchAccountList extends BaseAPI {
  constructor() {
    super(API.ACCOUNT_LIST);
  }

  accountList(accountIds) {
    this.lAccountIds = accountIds;
    return this;
  }

  execute() {
    const postParams = {};

    if(this.lAccountIds && this.lAccountIds.length > 0) {
      postParams.account_ids = JSON.stringify(this.lAccountIds);
    }

    return super.connect(postParams);
  }
}
