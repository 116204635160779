import React, { Component } from 'react';
import user_1 from '../../../src/assets/images/user_3.jpg';


class ProfileDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <div className={'sidebar'}>
                <div className={'profile'}>
                    <div className={"user_image"}>
                        <img src={user_1} alt={"logo"} />
                    </div>
                    <div className={'text'}>
                        <div className={'user_name'}>Maitrik Kataria</div>
                        <div className={'user_email'}>maitrikvk@simform.com</div>
                    </div>
                </div>
                <div className={'all_details'}>
                    <div className={'settings_option'}>
                        <div className={'runner'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 3C17.2044 3 16.4413 3.31607 15.8787 3.87868C15.3161 4.44129 15 5.20435 15 6V18C15 18.7956 15.3161 19.5587 15.8787 20.1213C16.4413 20.6839 17.2044 21 18 21C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 17.2044 20.6839 16.4413 20.1213 15.8787C19.5587 15.3161 18.7956 15 18 15H6C5.20435 15 4.44129 15.3161 3.87868 15.8787C3.31607 16.4413 3 17.2044 3 18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21C6.79565 21 7.55871 20.6839 8.12132 20.1213C8.68393 19.5587 9 18.7956 9 18V6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9H18C18.7956 9 19.5587 8.68393 20.1213 8.12132C20.6839 7.55871 21 6.79565 21 6C21 5.20435 20.6839 4.44129 20.1213 3.87868C19.5587 3.31607 18.7956 3 18 3Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span>Runner</span>
                        </div>
                        <div className={'runner'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14 2V8H20" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 13H8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 17H8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 9H9H8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span>Template</span>
                        </div>
                        <div className={'runner'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23 7L16 12L23 17V7Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span>Conferncing Settings</span>
                        </div>
                    </div>
                    <div className={'calendars'}>
                        <div className={'text'}>Calendars</div>
                        <label className={"checkbox"} for="checkbox-eins">
                            <span className={"checkbox__label"}>Birthdays</span>
                            <input type={"checkbox"} id="checkbox-eins" />
                            <div className={"checkbox__indicator"}></div>
                        </label>
                        <label className={"checkbox"} for="checkbox-zwei">
                            <span className={"checkbox__label"}>Holidays in United States</span>
                            <input type={"checkbox"} id="checkbox-zwei" />
                            <div className={"checkbox__indicator"}></div>
                        </label>
                        <label className={"checkbox"} for="checkbox-drei">
                            <span className={"checkbox__label"}>Streak</span>
                            <input type={"checkbox"} id="checkbox-drei" />
                            <div className={"checkbox__indicator"}></div>
                        </label>
                        <label className={"checkbox"} for="checkbox-prei">
                            <span className={"checkbox__label"}>Maitrik Kataria</span>
                            <input type={"checkbox"} id="checkbox-prei" />
                            <div className={"checkbox__indicator"}></div>
                        </label>
                    </div>
                    <div className={'feeback_button'}>
                        <div className={'feedback'}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 12.5C17.5 12.942 17.3244 13.366 17.0118 13.6785C16.6993 13.9911 16.2754 14.1667 15.8333 14.1667H5.83333L2.5 17.5V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V12.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span>Send Feedback</span>
                        </div>
                        <div className={'log_out'}>
                            Logout
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileDrawer;