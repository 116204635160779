import Logger from '../helper/logger';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const PRODUCT_LIST_RESPONSE = 'PRODUCT_LIST_RESPONSE';
export const PRODUCT_LIST_ERROR = 'PRODUCT_LIST_ERROR';
export const PURCHASE_PRODUCT_CANCEL = 'PURCHASE_PRODUCT_CANCEL';
export const PURCHASE_PRODUCT = 'PURCHASE_PRODUCT';
export const PURCHASE_PRODUCT_RESPONSE = 'PURCHASE_PRODUCT_RESPONSE';
export const PURCHASE_PRODUCT_ERROR = 'PURCHASE_PRODUCT_ERROR';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';

function getErrorMessage(error) {
  try {
    if (error && typeof error === 'string') {
      return error;
    } else if (error.message && typeof error.message === 'string') {
      return error.message;
    }
  } catch (e) {
    Logger.error(e);
  }
  return 'Something went wrong';
}

export const getProductList = (loggedIn) => ({ type: GET_PRODUCT_LIST, loggedIn });

export const onProductListResponse = (productList) => ({ type: PRODUCT_LIST_RESPONSE, productList });

export const onProductListError = (e) => ({ type: PRODUCT_LIST_ERROR, error: { message: getErrorMessage(e) } });

export const cancelPurchase = () => ({ type: PURCHASE_PRODUCT_CANCEL });

export const purchaseProduct = (product, windowHandle) => ({ type: PURCHASE_PRODUCT, product, windowHandle });

export const onPurchaseProductResponse = () => ({ type: PURCHASE_PRODUCT_RESPONSE });

export const onPurchaseProductError = (e) => ({ type: PURCHASE_PRODUCT_ERROR, error: { message: getErrorMessage(e) } });

export const updateSubscription = (subscription) => ({ type: UPDATE_SUBSCRIPTION, subscription });
