import { fork, take, put, call, cancelled, select } from 'redux-saga/effects';
import JSHelper from '../helper/jshelper';
import Api from '../api';
import Actions from '../actions';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import Subscription from '../helper/subscription';
import { getCalendarList } from '../selectors';
import { fetchCalendarList } from './../sagas/events'


function* initialSync() {
  try {
    yield fork(fetchProfileInfo);
    yield fork(fetchAccounts);
    yield call(fetchCalendarList);
    const accounts = yield select(getCalendarList);
    yield put({ type: Actions.FETCH_CALENDER_EVENTS, payload: { accounts } });
  } catch (error) {
    console.log("error in initial sync");
    throw error;
  } finally {
    if (yield cancelled()) {
      console.log("initial sync cancelled");
    }
  }
}


export function* fetchAccounts() {
  try {
    const api = new Api.FetchAccountList();
    const response = yield api.execute();

    if (response.error) {
      console.log(response.error.getErrorMessage());
      yield put(Actions.accountUpdated([], response.error.getErrorMessage()));
      return;
    }

    const data = response.response.data;
    if (data) {
      yield put(Actions.accountUpdated(data.account_groups));
    }
  } catch (error) {
    yield put(Actions.accountUpdated([], error.message));
  }
}


export function* fetchProfileInfo() {
  try {
    const api = new Api.GetProfileInfoApi();
    const response = yield api.execute();

    console.log('profile info arrived');
    console.log(response);

    if (response.error) {
      console.log('error in fetchProfileInfo');
      console.log(response.error.getErrorMessage());
      return;
    }

    const json = response.response.data;
    if (json) {
      const userPreferences = UserPreferenceSingleton.getInstance();
      userPreferences.set(UserPreferenceSingleton.USER_NICK_NAME, json.nickname);
      userPreferences.set(UserPreferenceSingleton.PROFILE_PIC_URL, json.profile_pic);
      userPreferences.set(UserPreferenceSingleton.EMAIL, json.email);
    }
  } catch (error) {
    console.log("fetchProfileInfo", error)
  }

}

export function* handleGetChangeResponse(json) {
  try {
    const apiVersion = json.cm_version;
    const attachmentCacheSize = json.attachment_cache_size;
    const attachmentDownloadSetting = json.background_download_enabled;
    const newChangeLogVersion = json.clv;

    // Add subscription to UserPreferences
    const subscription = JSHelper.opt(json, "subscription");
    const addonMeta = JSHelper.opt(json, "addon_meta");
    const tsCardUpdate = JSHelper.containsKey(json, 'ts_card_update') ? json.ts_card_update : 0;

    const userPreferences = UserPreferenceSingleton.getInstance();
    const snoozeTimezoneOffset = JSHelper.containsKey(json, 'snooze_tz_offset') ? json.snooze_tz_offset : -1;
    if (snoozeTimezoneOffset !== -1) {
      userPreferences.set(UserPreferenceSingleton.SNOOZE_TIMEZONE_OFFSET, snoozeTimezoneOffset);
    }

    if (addonMeta) {
      userPreferences.set(UserPreferenceSingleton.ADDON_META, JSON.stringify(addonMeta));
    }

    userPreferences.set(UserPreferenceSingleton.CARD_UPDATE_TS, tsCardUpdate);
    userPreferences.set(UserPreferenceSingleton.APP_VERSION, apiVersion);
    userPreferences.set(UserPreferenceSingleton.ATTACHMENT_CACHE_SIZE, attachmentCacheSize);
    userPreferences.set(
      UserPreferenceSingleton.MASTER_ATTACHMENT_DOWNLOAD_SETTING,
      attachmentDownloadSetting,
    );
    userPreferences.set(UserPreferenceSingleton.CHANGE_LOG_VERSION, newChangeLogVersion);
    userPreferences.set(
      UserPreferenceSingleton.INSIGHT_FIRST_USE,
      json.ts_message_insight_first_use || 0,
    );
    Subscription.processSubscriptionInfo(subscription);
    yield put(Actions.updateSubscription(Subscription.getSubscription()));

    const isUVEnabled = JSHelper.optNumber(json, 'is_unified_view_enabled', 0);
    UserPreferenceSingleton.getInstance().setIsUnifiedViewSwitchRequired(isUVEnabled === 1);

    const isRecapEnabled = JSHelper.optNumber(json, 'is_recap_enabled', 1) === 1;
    if (isRecapEnabled !== UserPreferenceSingleton.getInstance().isRecapEnabled()) {
      UserPreferenceSingleton.getInstance().setIsRecapEnabled(isRecapEnabled);
      yield put({ type: Actions.INSIGHT_RESCHEDULING });
    }
  } catch (error) {
    console.log("handleGetChangeResponse", error)
  }

}

function* startGetChangesTaskWithWarmup() {
  try {
    const map = new Map();
    map.set("is_warmup", true);
    yield put(Actions.startGetChanges(map));
  } catch (error) {
    console.log("startGetChangesTaskWithWarmup", error)
  }

}

export function* watchSyncInit() {
  try {
    console.log('watchSyncInit')
    while (true) {
      const action = yield take(Actions.SYNC_START);
      console.log('action', action)
      console.log('UserPreferenceSingleton.getInstance().hasUserAcceptedTos()', UserPreferenceSingleton.getInstance().hasUserAcceptedTos())
      // if (UserPreferenceSingleton.getInstance().hasUserAcceptedTos()) {
      yield call(initialSync, action);
      if (action && action.isWarmup) {
        yield startGetChangesTaskWithWarmup();
        console.log("Get changes task with warmup called");
      }
      // }
    }
  } catch (error) {
    console.log("watchSyncInit", error)
  }
}
