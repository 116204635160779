import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import Actions from '../../actions';
import './style.scss';

class EnableSiblings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCalendars: [],
      selectedCalendar: undefined,
      errorMessage: ''
    }
  }

  onChangeSelectedCalendar = (e) => {
    let value = e.target.value;
    let arr = this.state.selectedCalendars;
    if (!_.includes(arr, value)) {
      arr.push(value);
    } else {
      _.remove(arr, (item) => item === value);
    }
    this.setState({ selectedCalendars: arr, errorMessage: '' });
  };

  onEnableSibling = () => {
    const { selectedCalendars, errorMessage } = this.state;
    if (errorMessage) this.setState({ errorMessage: '' });
    if (!selectedCalendars || selectedCalendars.length === 0) {
      this.setState({ errorMessage: 'Select atleast one account' });
      return;
    }

    const { addAccountObj = {} } = this.props.accounts;
    const { accounts = [] } = addAccountObj;
    const accountIds = [];
    selectedCalendars.forEach(index => {
      const messageAccount = accounts[index].accounts.find((x) => x.category === 'message');
      if (messageAccount)
        accountIds.push(messageAccount.id)
    })
    console.log('accountIds', accountIds)

    this.props.dispatch(Actions.enableSibligns(accountIds));
  }

  render() {
    const { addAccountObj = {} } = this.props.accounts;
    const { accounts = [] } = addAccountObj;
    const { errorMessage } = this.state;
    return (
      <div className={classNames('wrapper')}>
        <h2>Calendars</h2>
        <p>
          choose the account you wish to see calendar events from.Only google and Exchange(EWS) calendar at the moment.
        </p>
        <div className='form'>
          <FormGroup>
            {accounts &&
              accounts.map((item, i) => {
                return (
                  <div className={'checkbox'} key={i}>
                    <label className={'container'}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color={'primary'}
                            value={i}
                            onChange={this.onChangeSelectedCalendar}
                          />
                        }
                        label={item.display_name}
                      />
                    </label>
                  </div>
                );
              })}
          </FormGroup>
          <button type='button' onClick={this.onEnableSibling}>
            Done
          </button>
          {errorMessage ? <div>{errorMessage}</div> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
  calendar: state.calendar,
  accounts: state.accounts
});


export default connect(mapStateToProps)(EnableSiblings);