import Actions from '../actions'

export const LOGIN = {
  LOADER: 'loader',
  SUCCESS: 'success',
  ERROR: 'error',
  NETWORK_FAILURE: 'network_failure',
  RESET: 'reset',
};

export const login = (state = { login: LOGIN.RESET }, action) => {
  switch (action.type) {
    case Actions.PASSWORD_RESET:
    case Actions.LOGIN:
      return Object.assign({}, state, {
        login: LOGIN.LOADER,
      });
    case Actions.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        login: LOGIN.SUCCESS,
        logout: null,
      });
    case Actions.PASSWORD_RESET_SUCCESS:
      return Object.assign({}, state, {
        login: LOGIN.RESET,
      });
    case Actions.NETWORK_FAILURE:
      return Object.assign({}, state, {
        login: LOGIN.NETWORK_FAILURE,
      });
    case Actions.PASSWORD_RESET_FAIL:
    case Actions.LOGIN_FAILURE:
      return Object.assign({}, state, {
        login: LOGIN.ERROR, error: action.error,
      });
    case Actions.LOGOUT_FAIL:
      return Object.assign({}, state, {
        logout: {
          error: action.error,
        }
      });
    case Actions.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        logout: {
          isSuccessful: true,
        },
        login: LOGIN.RESET,
      });
    case Actions.FLUSH_SUCCESS:
      return state;
    case Actions.LOGOUT_RESET:
      return Object.assign({}, state, {
        logout: null,
      });
    case Actions.RESET_FORM:
      return Object.assign({}, state, {
        login: LOGIN.RESET,
      });
    default:
      return state;
  }
};



