import Actions from '../actions';

export const accounts = (state = {}, action) => {
  switch (action.type) {
    case Actions.ACCOUNT_ADDED:
      if (state.addAccountObj && state.addAccountObj.accounts) {
        return Object.assign({}, state, {
          addAccountObj: {
            accounts: (action.payload.accountList ?
              state.addAccountObj.accounts.concat(action.payload.accountList) :
              state.addAccountObj.accounts),
            error: action.payload.error
          }
        });
      }
      return Object.assign({}, state, {
        addAccountObj: {
          accounts: action.payload.accountList,
          error: action.payload.error
        }
      });

    case Actions.ADDED_ACCOUNT_RESET:
      return Object.assign({}, state, {
        addAccountObj: null
      });

    case Actions.SET_ACCOUNTS_COLOR_MAP:
      return Object.assign({}, state, {
        accountColorMap: action.payload.accountColorMap,
      });

    case Actions.SET_ACCOUNTS_ACTION_STATUS_MAP:
      return Object.assign({}, state, {
        accountActionStatusMap: action.payload.accountActionStatusMap,
      });

    default:
      return state;
  }
};
