import { BaseAPI, API } from './base-api';

export default class GetProfileInfoApi extends BaseAPI {

  constructor() {
    super(API.PROFILE_INFO_GET);
  }

  execute() {
    return super.connect(null);
  }
}
