import { BaseAPI, API } from './base-api';

export default class GetCalendarList extends BaseAPI {
  constructor() {
    super(API.CALENDER_LIST);
  }

  execute() {
    const postParams = {};
    postParams.sync_info = '{"sync_hash":"{}"}';
    postParams.sync_type = 'all';
    postParams.timezone_offset = '19800';
    return super.connect(postParams);
  }
}
