import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from '../reducers';
import history from './../history';

let singleton;
let singletonEnforcer = Symbol();

class Store {
  constructor() {
    this.store = null;

    this.sagaMiddleware = createSagaMiddleware();
    this.router = routerMiddleware(history);
  }

  configureStore(initialState) {
    const middlewares = [this.sagaMiddleware, this.router];

    this.store = createStore(
      rootReducer,
      initialState,
      applyMiddleware(...middlewares),
    );

    this.store.runSaga = this.sagaMiddleware.run;
    this.store.close = () => this.store.dispatch(END);
  }

  getStore() {
    return this.store;
  }
}

export default class StoreSingleton {

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');

    this.store = new Store();
  }

  static getInstance() {
    if (!singleton) {
      singleton = new StoreSingleton(singletonEnforcer);
    }

    return singleton;
  }

  configureStore(initialState) {
    this.store.configureStore(initialState);
  }

  getStore() {
    return this.store.getStore();
  }
}