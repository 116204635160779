export const CALENDAR_CREATE_EVENT = 'create_event';

export const xiTimeZone = [
  { country: 'Eastern Time (EST/EDT)', timezone: 'America/New_York' },
  { country: 'Central Time (CST/CDT)', timezone: 'America/Chicago' },
  { country: 'Mountain Time (MST/MDT)', timezone: 'America/Denver' },
  { country: 'Pacific Time (PST/PDT)', timezone: 'America/Los_Angeles' },
  { country: 'London Time (GMT/BST)', timezone: 'Europe/London' },
  { country: 'Central European Time (CET/CEST)', timezone: 'Europe/Copenhagen' },
  { country: 'Israel Time (IST/IDT)', timezone: 'Asia/Jerusalem' },
  { country: 'China Standard Time (CST)', timezone: 'Asia/Shanghai' },
  { country: 'India Standard Time (IST)', timezone: 'Asia/Calcutta' },
  { country: 'Australia Eastern Time (AEDT/AEST)', timezone: 'Australia/Sydney' },
  { country: '(GMT-11:00) Niue', timezone: 'Pacific/Niue' },
  { country: '(GMT-11:00) Pago Pago', timezone: 'Pacific/Pago_Pago' },
  { country: '(GMT-10:00) Hawaii Time', timezone: 'Pacific/Honolulu' },
  { country: '(GMT-10:00) Rarotonga', timezone: 'Pacific/Rarotonga' },
  { country: '(GMT-10:00) Tahiti', timezone: 'Pacific/Tahiti' },
  { country: '(GMT-09:30) Marquesas', timezone: 'Pacific/Marquesas' },
  { country: '(GMT-09:00) Gambier', timezone: 'Pacific/Gambier' },
  { country: '(GMT-09:00) Alaska Time', timezone: 'America/Anchorage' },
  { country: '(GMT-08:00) Pitcairn', timezone: 'Pacific/Pitcairn' },
  { country: '(GMT-08:00) Pacific Time', timezone: 'America/Los_Angeles' },
  { country: '(GMT-08:00) Pacific Time - Tijuana', timezone: 'America/Tijuana' },
  { country: '(GMT-08:00) Pacific Time - Vancouver', timezone: 'America/Vancouver' },
  { country: '(GMT-07:00) Pacific Time - Whitehorse', timezone: 'America/Whitehorse' },
  { country: '(GMT-07:00) Mountain Time - Dawson Creek', timezone: 'America/Dawson_Creek' },
  { country: '(GMT-07:00) Mountain Time - Hermosillo', timezone: 'America/Hermosillo' },
  { country: '(GMT-07:00) Mountain Time - Arizona', timezone: 'America/Phoenix' },
  { country: '(GMT-07:00) Mountain Time', timezone: 'America/Denver' },
  { country: '(GMT-07:00) Mountain Time - Edmonton', timezone: 'America/Edmonton' },
  { country: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan', timezone: 'America/Mazatlan' },
  { country: '(GMT-07:00) Mountain Time - Yellowknife', timezone: 'America/Yellowknife' },
  { country: '(GMT-06:00) Belize', timezone: 'America/Belize' },
  { country: '(GMT-06:00) Costa Rica', timezone: 'America/Costa_Rica' },
  { country: '(GMT-06:00) El Salvador', timezone: 'America/El_Salvador' },
  { country: '(GMT-06:00) Guatemala', timezone: 'America/Guatemala' },
  { country: '(GMT-06:00) Managua', timezone: 'America/Managua' },
  { country: '(GMT-06:00) Central Time - Regina', timezone: 'America/Regina' },
  { country: '(GMT-06:00) Central Time - Tegucigalpa', timezone: 'America/Tegucigalpa' },
  { country: '(GMT-06:00) Galapagos', timezone: 'Pacific/Galapagos' },
  { country: '(GMT-06:00) Central Time', timezone: 'America/Chicago' },
  { country: '(GMT-06:00) Central Time - Mexico City', timezone: 'America/Mexico_City' },
  { country: '(GMT-06:00) Central Time - Winnipeg', timezone: 'America/Winnipeg' },
  { country: '(GMT-05:00) Bogota', timezone: 'America/Bogota' },
  { country: '(GMT-05:00) America Cancun', timezone: 'America/Cancun' },
  { country: '(GMT-05:00) Guayaquil', timezone: 'America/Guayaquil' },
  { country: '(GMT-05:00) Jamaica', timezone: 'America/Jamaica' },
  { country: '(GMT-05:00) Lima', timezone: 'America/Lima' },
  { country: '(GMT-05:00) Panama', timezone: 'America/Panama' },
  { country: '(GMT-05:00) Rio Branco', timezone: 'America/Rio_Branco' },
  { country: '(GMT-05:00) Easter Island', timezone: 'Pacific/Easter' },
  { country: '(GMT-04:00) Caracas', timezone: 'America/Caracas' },
  { country: '(GMT-05:00) Cayman', timezone: 'America/Cayman' },
  { country: '(GMT-05:00) Havana', timezone: 'America/Havana' },
  { country: '(GMT-05:00) Eastern Time - Iqaluit', timezone: 'America/Iqaluit' },
  { country: '(GMT-05:00) Nassau', timezone: 'America/Nassau' },
  { country: '(GMT-05:00) Eastern Time', timezone: 'America/New_York' },
  { country: '(GMT-05:00) Port-au-Prince', timezone: 'America/Port-au-Prince' },
  { country: '(GMT-05:00) Eastern Time - Toronto', timezone: 'America/Toronto' },
  { country: '(GMT-03:00) Asuncion', timezone: 'America/Asuncion' },
  { country: '(GMT-04:00) Barbados', timezone: 'America/Barbados' },
  { country: '(GMT-04:00) Boa Vista', timezone: 'America/Boa_Vista' },
  { country: '(GMT-04:00) Campo Grande', timezone: 'America/Campo_Grande' },
  { country: '(GMT-04:00) Cuiaba', timezone: 'America/Cuiaba' },
  { country: '(GMT-04:00) Curacao', timezone: 'America/Curacao' },
  { country: '(GMT-05:00) Grand Turk', timezone: 'America/Grand_Turk' },
  { country: '(GMT-04:00) Guyana', timezone: 'America/Guyana' },
  { country: '(GMT-04:00) La Paz', timezone: 'America/La_Paz' },
  { country: '(GMT-04:00) Manaus', timezone: 'America/Manaus' },
  { country: '(GMT-04:00) Martinique', timezone: 'America/Martinique' },
  { country: '(GMT-04:00) Port of Spain', timezone: 'America/Port_of_Spain' },
  { country: '(GMT-04:00) Porto Velho', timezone: 'America/Porto_Velho' },
  { country: '(GMT-04:00) Puerto Rico', timezone: 'America/Puerto_Rico' },
  { country: '(GMT-04:00) Santo Domingo', timezone: 'America/Santo_Domingo' },
  { country: '(GMT-04:00) Atlantic Time - Halifax', timezone: 'America/Halifax' },
  { country: '(GMT-04:00) Thule', timezone: 'America/Thule' },
  { country: '(GMT-04:00) Bermuda', timezone: 'Atlantic/Bermuda' },
  { country: '(GMT-03:00) Araguaina', timezone: 'America/Araguaina' },
  { country: '(GMT-03:00) Buenos Aires', timezone: 'America/Argentina/Buenos_Aires' },
  { country: '(GMT-03:00) Salvador', timezone: 'America/Bahia' },
  { country: '(GMT-03:00) Belem', timezone: 'America/Belem' },
  { country: '(GMT-03:00) Cayenne', timezone: 'America/Cayenne' },
  { country: '(GMT-03:00) Fortaleza', timezone: 'America/Fortaleza' },
  { country: '(GMT-03:00) Maceio', timezone: 'America/Maceio' },
  { country: '(GMT-03:00) Montevideo', timezone: 'America/Montevideo' },
  { country: '(GMT-03:00) Paramaribo', timezone: 'America/Paramaribo' },
  { country: '(GMT-03:00) Recife', timezone: 'America/Recife' },
  { country: '(GMT-03:00) Santiago', timezone: 'America/Santiago' },
  { country: '(GMT-03:00) Sao Paulo', timezone: 'America/Sao_Paulo' },
  { country: '(GMT-03:00) Palmer', timezone: 'Antarctica/Palmer' },
  { country: '(GMT-03:00) Rothera', timezone: 'Antarctica/Rothera' },
  { country: '(GMT-03:00) Stanley', timezone: 'Atlantic/Stanley' },
  { country: '(GMT-03:30) Newfoundland Time - St. Johns', timezone: 'America/St_Johns' },
  { country: '(GMT-03:00) Godthab', timezone: 'America/Godthab' },
  { country: '(GMT-03:00) Miquelon', timezone: 'America/Miquelon' },
  { country: '(GMT-02:00) Noronha', timezone: 'America/Noronha' },
  { country: '(GMT-02:00) South Georgia', timezone: 'Atlantic/South_Georgia' },
  { country: '(GMT-01:00) Cape Verde', timezone: 'Atlantic/Cape_Verde' },
  { country: '(GMT-01:00) Scoresbysund', timezone: 'America/Scoresbysund' },
  { country: '(GMT-01:00) Azores', timezone: 'Atlantic/Azores' },
  { country: '(GMT+00:00) Abidjan', timezone: 'Africa/Abidjan' },
  { country: '(GMT+00:00) Accra', timezone: 'Africa/Accra' },
  { country: '(GMT+00:00) Bissau', timezone: 'Africa/Bissau' },
  { country: '(GMT+00:00) Monrovia', timezone: 'Africa/Monrovia' },
  { country: '(GMT+00:00) Danmarkshavn', timezone: 'America/Danmarkshavn' },
  { country: '(GMT+00:00) Reykjavik', timezone: 'Atlantic/Reykjavik' },
  { country: '(GMT+00:00) GMT (no daylight saving)', timezone: 'Etc/GMT' },
  { country: '(GMT+01:00) Casablanca', timezone: 'Africa/Casablanca' },
  { country: '(GMT+01:00) El Aaiun', timezone: 'Africa/El_Aaiun' },
  { country: '(GMT+00:00) Canary Islands', timezone: 'Atlantic/Canary' },
  { country: '(GMT+00:00) Faeroe', timezone: 'Atlantic/Faroe' },
  { country: '(GMT+00:00) Dublin', timezone: 'Europe/Dublin' },
  { country: '(GMT+00:00) Lisbon', timezone: 'Europe/Lisbon' },
  { country: '(GMT+00:00) London', timezone: 'Europe/London' },
  { country: '(GMT+01:00) Algiers', timezone: 'Africa/Algiers' },
  { country: '(GMT+01:00) Lagos', timezone: 'Africa/Lagos' },
  { country: '(GMT+01:00) Ndjamena', timezone: 'Africa/Ndjamena' },
  { country: '(GMT+01:00) Tunis', timezone: 'Africa/Tunis' },
  { country: '(GMT+02:00) Windhoek', timezone: 'Africa/Windhoek' },
  { country: '(GMT+01:00) Ceuta', timezone: 'Africa/Ceuta' },
  { country: '(GMT+01:00) Amsterdam', timezone: 'Europe/Amsterdam' },
  { country: '(GMT+01:00) Andorra', timezone: 'Europe/Andorra' },
  { country: '(GMT+01:00) Central European Time - Belgrade', timezone: 'Europe/Belgrade' },
  { country: '(GMT+01:00) Berlin', timezone: 'Europe/Berlin' },
  { country: '(GMT+01:00) Brussels', timezone: 'Europe/Brussels' },
  { country: '(GMT+01:00) Budapest', timezone: 'Europe/Budapest' },
  { country: '(GMT+01:00) Copenhagen', timezone: 'Europe/Copenhagen' },
  { country: '(GMT+01:00) Gibraltar', timezone: 'Europe/Gibraltar' },
  { country: '(GMT+01:00) Luxembourg', timezone: 'Europe/Luxembourg' },
  { country: '(GMT+01:00) Madrid', timezone: 'Europe/Madrid' },
  { country: '(GMT+01:00) Malta', timezone: 'Europe/Malta' },
  { country: '(GMT+01:00) Monaco', timezone: 'Europe/Monaco' },
  { country: '(GMT+01:00) Oslo', timezone: 'Europe/Oslo' },
  { country: '(GMT+01:00) Paris', timezone: 'Europe/Paris' },
  { country: '(GMT+01:00) Central European Time - Prague', timezone: 'Europe/Prague' },
  { country: '(GMT+01:00) Rome', timezone: 'Europe/Rome' },
  { country: '(GMT+01:00) Stockholm', timezone: 'Europe/Stockholm' },
  { country: '(GMT+01:00) Tirane', timezone: 'Europe/Tirane' },
  { country: '(GMT+01:00) Vienna', timezone: 'Europe/Vienna' },
  { country: '(GMT+01:00) Warsaw', timezone: 'Europe/Warsaw' },
  { country: '(GMT+01:00) Zurich', timezone: 'Europe/Zurich' },
  { country: '(GMT+02:00) Cairo', timezone: 'Africa/Cairo' },
  { country: '(GMT+02:00) Johannesburg', timezone: 'Africa/Johannesburg' },
  { country: '(GMT+02:00) Maputo', timezone: 'Africa/Maputo' },
  { country: '(GMT+02:00) Tripoli', timezone: 'Africa/Tripoli' },
  { country: '(GMT+02:00) Moscow-01 - Kaliningrad', timezone: 'Europe/Kaliningrad' },
  { country: '(GMT+02:00) Amman', timezone: 'Asia/Amman' },
  { country: '(GMT+02:00) Beirut', timezone: 'Asia/Beirut' },
  { country: '(GMT+02:00) Damascus', timezone: 'Asia/Damascus' },
  { country: '(GMT+02:00) Gaza', timezone: 'Asia/Gaza' },
  { country: '(GMT+02:00) Jerusalem', timezone: 'Asia/Jerusalem' },
  { country: '(GMT+02:00) Nicosia', timezone: 'Asia/Nicosia' },
  { country: '(GMT+02:00) Athens', timezone: 'Europe/Athens' },
  { country: '(GMT+02:00) Bucharest', timezone: 'Europe/Bucharest' },
  { country: '(GMT+02:00) Chisinau', timezone: 'Europe/Chisinau' },
  { country: '(GMT+02:00) Helsinki', timezone: 'Europe/Helsinki' },
  { country: '(GMT+03:00) Istanbul', timezone: 'Europe/Istanbul' },
  { country: '(GMT+02:00) Kiev', timezone: 'Europe/Kiev' },
  { country: '(GMT+02:00) Riga', timezone: 'Europe/Riga' },
  { country: '(GMT+02:00) Sofia', timezone: 'Europe/Sofia' },
  { country: '(GMT+02:00) Tallinn', timezone: 'Europe/Tallinn' },
  { country: '(GMT+02:00) Vilnius', timezone: 'Europe/Vilnius' },
  { country: '(GMT+02:00) Khartoum', timezone: 'Africa/Khartoum' },
  { country: '(GMT+03:00) Nairobi', timezone: 'Africa/Nairobi' },
  { country: '(GMT+03:00) Syowa', timezone: 'Antarctica/Syowa' },
  { country: '(GMT+03:00) Baghdad', timezone: 'Asia/Baghdad' },
  { country: '(GMT+03:00) Qatar', timezone: 'Asia/Qatar' },
  { country: '(GMT+03:00) Riyadh', timezone: 'Asia/Riyadh' },
  { country: '(GMT+03:00) Minsk', timezone: 'Europe/Minsk' },
  { country: '(GMT+03:00) Moscow+00 - Moscow', timezone: 'Europe/Moscow' },
  { country: '(GMT+03:30) Tehran', timezone: 'Asia/Tehran' },
  { country: '(GMT+04:00) Dubai', timezone: 'Asia/Dubai' },
  { country: '(GMT+04:00) Tbilisi', timezone: 'Asia/Tbilisi' },
  { country: '(GMT+04:00) Yerevan', timezone: 'Asia/Yerevan' },
  { country: '(GMT+04:00) Moscow+01 - Samara', timezone: 'Europe/Samara' },
  { country: '(GMT+04:00) Mahe', timezone: 'Indian/Mahe' },
  { country: '(GMT+04:00) Mauritius', timezone: 'Indian/Mauritius' },
  { country: '(GMT+04:00) Reunion', timezone: 'Indian/Reunion' },
  { country: '(GMT+04:30) Kabul', timezone: 'Asia/Kabul' },
  { country: '(GMT+04:00) Baku', timezone: 'Asia/Baku' },
  { country: '(GMT+05:00) Mawson', timezone: 'Antarctica/Mawson' },
  { country: '(GMT+05:00) Aqtau', timezone: 'Asia/Aqtau' },
  { country: '(GMT+05:00) Aqtobe', timezone: 'Asia/Aqtobe' },
  { country: '(GMT+05:00) Ashgabat', timezone: 'Asia/Aqtobe' },
  { country: '(GMT+05:00) Dushanbe', timezone: 'Asia/Aqtobe' },
  { country: '(GMT+05:00) Karachi', timezone: 'Asia/Karachi' },
  { country: '(GMT+05:00) Tashkent', timezone: 'Asia/Tashkent' },
  { country: '(GMT+05:00) Moscow+02 - Yekaterinburg', timezone: 'Asia/Yekaterinburg' },
  { country: '(GMT+05:00) Kerguelen', timezone: 'Indian/Kerguelen' },
  { country: '(GMT+05:00) Maldives', timezone: 'Indian/Maldives' },
  { country: '(GMT+05:30) India Standard Time', timezone: 'Asia/Kolkata' },
  { country: '(GMT+05:30) Colombo', timezone: 'Asia/Colombo' },
  { country: '(GMT+05:45) Katmandu', timezone: 'Asia/Katmandu' },
  { country: '(GMT+06:00) Vostok', timezone: 'Antarctica/Vostok' },
  { country: '(GMT+06:00) Almaty', timezone: 'Asia/Almaty' },
  { country: '(GMT+06:00) Bishkek', timezone: 'Asia/Bishkek' },
  { country: '(GMT+06:00) Dhaka', timezone: 'Asia/Dhaka' },
  { country: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk', timezone: 'Asia/Omsk' },
  { country: '(GMT+06:00) Thimphu', timezone: 'Asia/Thimphu' },
  { country: '(GMT+06:00) Chagos', timezone: 'Indian/Chagos' },
  { country: '(GMT+06:30) Rangoon', timezone: 'Asia/Rangoon' },
  { country: '(GMT+06:30) Cocos', timezone: 'Indian/Cocos' },
  { country: '(GMT+07:00) Davis', timezone: 'Antarctica/Davis' },
  { country: '(GMT+07:00) Bangkok', timezone: 'Asia/Bangkok' },
  { country: '(GMT+07:00) Jakarta', timezone: 'Asia/Jakarta' },
  { country: '(GMT+07:00) Moscow+04 - Krasnoyarsk', timezone: 'Asia/Krasnoyarsk' },
  { country: '(GMT+07:00) Hanoi', timezone: 'Asia/Saigon' },
  { country: '(GMT+07:00) Christmas', timezone: 'Indian/Christmas' },
  { country: '(GMT+07:00) Hovd', timezone: 'Asia/Hovd' },
  { country: '(GMT+11:00) Casey', timezone: 'Antarctica/Casey' },
  { country: '(GMT+08:00) Brunei', timezone: 'Asia/Brunei' },
  { country: '(GMT+08:00) Hong Kong', timezone: 'Asia/Hong_Kong' },
  { country: '(GMT+08:00) Moscow+05 - Irkutsk', timezone: 'Asia/Irkutsk' },
  { country: '(GMT+08:00) Kuala Lumpur', timezone: 'Asia/Kuala_Lumpur' },
  { country: '(GMT+08:00) Macau', timezone: 'Asia/Macau' },
  { country: '(GMT+08:00) Makassar', timezone: 'Asia/Makassar' },
  { country: '(GMT+08:00) Manila', timezone: 'Asia/Manila' },
  { country: '(GMT+08:00) China Time - Beijing', timezone: 'Asia/Shanghai' },
  { country: '(GMT+08:00) Singapore', timezone: 'Asia/Singapore' },
  { country: '(GMT+08:00) Taipei', timezone: 'Asia/Taipei' },
  { country: '(GMT+08:00) Western Time - Perth', timezone: 'Australia/Perth' },
  { country: '(GMT+08:00) Choibalsan', timezone: 'Asia/Choibalsan' },
  { country: '(GMT+08:00) Ulaanbaatar', timezone: 'Asia/Ulaanbaatar' },
  { country: '(GMT+09:00) Dili', timezone: 'Asia/Dili' },
  { country: '(GMT+09:00) Jayapura', timezone: 'Asia/Jayapura' },
  { country: '(GMT+09:00) Pyongyang', timezone: 'Asia/Pyongyang' },
  { country: '(GMT+09:00) Seoul', timezone: 'Asia/Seoul' },
  { country: '(GMT+09:00) Tokyo', timezone: 'Asia/Tokyo' },
  { country: '(GMT+09:00) Moscow+06 - Yakutsk', timezone: 'Asia/Yakutsk' },
  { country: '(GMT+09:00) Palau', timezone: 'Pacific/Palau' },
  { country: '(GMT+10:30) Central Time - Adelaide', timezone: 'Australia/Adelaide' },
  { country: '(GMT+09:30) Central Time - Darwin', timezone: 'Australia/Darwin' },
  { country: "(GMT+10:00) Dumont D'Urville", timezone: 'Antarctica/DumontDUrville' },
  { country: '(GMT+11:00) Moscow+07 - Magadan', timezone: 'Asia/Magadan' },
  { country: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk', timezone: 'Asia/Vladivostok' },
  { country: '(GMT+10:00) Eastern Time - Brisbane', timezone: 'Australia/Brisbane' },
  { country: '(GMT+11:00) Eastern Time - Hobart', timezone: 'Australia/Hobart' },
  { country: '(GMT+11:00) Eastern Time - Melbourne, Sydney', timezone: 'Australia/Sydney' },
  { country: '(GMT+10:00) Truk', timezone: 'Pacific/Chuuk' },
  { country: '(GMT+10:00) Guam', timezone: 'Pacific/Guam' },
  { country: '(GMT+10:00) Port Moresby', timezone: 'Pacific/Port_Moresby' },
  { country: '(GMT+11:00) Efate', timezone: 'Pacific/Efate' },
  { country: '(GMT+11:00) Guadalcanal', timezone: 'Pacific/Guadalcanal' },
  { country: '(GMT+11:00) Kosrae', timezone: 'Pacific/Kosrae' },
  { country: '(GMT+11:00) Noumea', timezone: 'Pacific/Noumea' },
  { country: '(GMT+11:00) Ponape', timezone: 'Pacific/Pohnpei' },
  { country: '(GMT+12:00) Norfolk', timezone: 'Pacific/Norfolk' },
  { country: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy', timezone: 'Asia/Kamchatka' },
  { country: '(GMT+13:00) Auckland', timezone: 'Pacific/Auckland' },
  { country: '(GMT+12:00) Fiji', timezone: 'Pacific/Fiji' },
  { country: '(GMT+12:00) Funafuti', timezone: 'Pacific/Funafuti' },
  { country: '(GMT+12:00) Kwajalein', timezone: 'Pacific/Kwajalein' },
  { country: '(GMT+12:00) Majuro', timezone: 'Pacific/Majuro' },
  { country: '(GMT+12:00) Nauru', timezone: 'Pacific/Nauru' },
  { country: '(GMT+12:00) Tarawa', timezone: 'Pacific/Tarawa' },
  { country: '(GMT+12:00) Wake', timezone: 'Pacific/Wake' },
  { country: '(GMT+12:00) Wallis', timezone: 'Pacific/Wallis' },
  { country: '(GMT+14:00) Apia', timezone: 'Pacific/Apia' },
  { country: '(GMT+13:00) Enderbury', timezone: 'Pacific/Enderbury' },
  { country: '(GMT+13:00) Fakaofo', timezone: 'Pacific/Fakaofo' },
  { country: '(GMT+13:00) Tongatapu', timezone: 'Pacific/Tongatapu' },
  { country: '(GMT+14:00) Kiritimati', timezone: 'Pacific/Kiritimati' }
];
