export const PERFORM_LOGIN = 'PERFORM_LOGIN';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const NETWORK_FAILURE = 'NETWORK_FAILURE';
export const RESET_FORM = 'RESET_FORM';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_RESET = 'LOGOUT_RESET';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const FLUSH = 'FLUSH';
export const FLUSH_SUCCESS = 'FLUSH_SUCCESS';
export const DELETE_ALL_MY_DATA = 'DELETE_ALL_MY_DATA';

export const requestUserLogin = (username, password, otp, otpContactMethod) => ({
  type: LOGIN,
  payload: {
    username,
    password,
    otp,
    otpContactMethod,
  },
});

export const resetForm = () => ({
  type: RESET_FORM,
});

export const onLoginNetworkFail = username => ({
  type: NETWORK_FAILURE,
  username,
  receivedAt: Date.now(),
});

export const onLoginResponse = (username, json) => ({
  type: LOGIN_SUCCESS,
  username,
  response: json,
  receivedAt: Date.now(),
});

export const onLoginFail = (username, error) => ({
  type: LOGIN_FAILURE,
  error,
});

export const requestLogout = (sessionExpired, remoteWipeInitiated = false) => ({
  type: LOGOUT,
  sessionExpired,
  remoteWipeInitiated,
});

export const onLogoutFail = error => ({
  type: LOGOUT_FAIL,
  error,
});

export const onLogoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const requestPasswordReset = username => ({
  type: PASSWORD_RESET,
  payload: { username },
});

export const onResetPasswordFail = (username, error) => ({
  type: PASSWORD_RESET_FAIL,
  error,
});

export const onResetPasswordNetworkFail = onLoginNetworkFail;

export const onResetPasswordResponse = username => ({
  type: PASSWORD_RESET_SUCCESS,
  username,
  receivedAt: Date.now(),
});

export const requestFlush = () => ({
  type: FLUSH,
});

export const onFlushSuccess = () => ({
  type: FLUSH_SUCCESS,
});

export const requestDeleteData = () => ({
  type: DELETE_ALL_MY_DATA,
});