import { toastr } from 'toastr';
import { LOGIN } from '../reducers/login';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import AccountSettingsPreferences from '../helper/account-settings-preferences';
import * as Constants from '../helper/constant';
import { BaseAPI, API } from '../api/base-api';

const isAccountSupported = (accountList) => {
  for (const accountGroup of Constants.SUPPORTED_ACCOUNT_LIST) {
    if (accountGroup === accountList.group) {
      return true;
    }
  }
  return false;
};

const getDomainFromAccount = (account) => {
  if (!account.accountName) {
    return '';
  }

  let domain = account.accountName.substring(account.accountName.indexOf('@') + 1);

  const lastIndex = domain.indexOf('.'); // or should it be lastIndexOf('.')?
  domain = lastIndex > -1 ? domain.substring(0, lastIndex) : domain;

  // handle icloud separately,
  // check if domain is icloud then change it to iCloud
  if (domain.toLowerCase() === 'icloud') {
    return 'iCloud';
  }

  return domain.toLowerCase().split('.').map(c => c[0].toUpperCase() + c.substring(1)).join('.');
};

const isSameDomainExistInMultipleAccounts = (actualAccount, actualDomain, accounts) => {
  if (accounts) {
    for (const userAccount of accounts) {
      if (userAccount.accountId === actualAccount.accountId
        || userAccount.accountId === -1) {
        continue;
      }

      const domain = getDomainFromAccount(userAccount);
      if (domain === actualDomain) {
        return true;
      }
    }
  }

  return false;
};

const getUsernameFromAccount = account =>
  (!account.accountName ? '' :
    account.accountName.substring(0, account.accountName.indexOf('@')));

const getUserNickName = () => {
  let userName = UserPreferenceSingleton.getInstance().getUserNickName();
  if (userName.trim()) {
    userName = userName.split(' ')[0];
  } else {
    const email = UserPreferenceSingleton.getInstance().getEmail();
    if (email) {
      userName = email.split('@')[0];
    }
  }
  return userName;
};

const getExtension = (pathString) => {
  let ext = '';
  if (pathString && pathString.lastIndexOf('.') !== -1) {
    ext = pathString.substring(pathString.lastIndexOf('.') + 1);
    if (ext) {
      ext = ext.toUpperCase();
    }
  }

  return ext;
};

const isFilePathIsImage = (pathString) => {
  const extensions = ['JPG', 'JPE', 'BMP', 'GIF', 'PNG'];

  const extension = getExtension(pathString);

  if (extension && extension.length > 0) {
    for (let i = 0; i < extensions.length; i += 1) {
      if (extension === extensions[i]) {
        return true;
      }
    }
  }
  return false;
};

const isSpace = (ch) => {
  if (ch === ' ' || ch === '\t' || ch === '\n' || ch === '\f' || ch === '\r') {
    return true;
  }
  return false;
};

const getFirstName = (input) => {
  let output = null;
  input = input.trim();
  const commaIndex = input.indexOf(',');

  if (commaIndex !== -1 && (input.length - commaIndex) > 4) {
    const nextDot = input.indexOf('.', commaIndex + 2);

    if (nextDot === -1 || (nextDot - commaIndex) > 4) {
      output = input.substring(commaIndex + ', '.length);
      input = output;
      input = input.trim();
    }
  }

  if (input.length > 6) {
    if (input.charAt(0).toLowerCase === ('M').toLowerCase()) {
      if (input.toLowerCase().startsWith(('Mr. ').toLowerCase())) {
        input = input.replace('Mr. ', '');
      } else if (input.toLowerCase()
        .startsWith(('Mr ').toLowerCase())) {
        input = input.replace('Mr ', '');
      } else if (input.toLowerCase().startsWith(('Mrs. ').toLowerCase())) {
        input = input.replace('Mrs. ', '');
      } else if (input.toLowerCase().startsWith(('Mrs ').toLowerCase())) {
        input = input.replace('Mrs ', '');
      } else if (input.toLowerCase().startsWith(('Ms. ').toLowerCase())) {
        input = input.replace('Ms. ', '');
      } else if (input.toLowerCase().startsWith(('Ms ').toLowerCase())) {
        input = input.replace('Ms ', '');
      } else if (input.toLowerCase().startsWith(('Miss ').toLowerCase())) {
        input = input.replace('Miss ', '');
      }
    } else if (input.toLowerCase().startsWith(('The ').toLowerCase())) {
      input = input.replace('The ', '');
    } else if (input.charAt(0).toLowerCase() === ('D').toLowerCase()) {
      if (input.toLowerCase().startsWith(('Dr. ').toLowerCase())) {
        input = input.replace('Dr. ', '');
      } else if (input.toLowerCase()
        .startsWith(('Dr ').toLowerCase())) {
        input = input.replace('Dr ', '');
      }
    } else if (input.charAt(0).toLowerCase() === ('P').toLowerCase()) {
      if (input.toLowerCase().startsWith(('Prof. ').toLowerCase())) {
        input = input.replace('Prof. ', '');
      } else if (input.toLowerCase().startsWith(('Prof ').toLowerCase())) {
        input = input.replace('Prof ', '');
      }
    }
  }

  input = input.trim();
  let index = 0;
  for (let i = 0; i < input.length; i += 1) {
    const ch = input.charAt(i);
    if (i < 3) {
      continue;
    }

    if (isSpace(ch)) {
      index = i;
      break;
    }

    switch (ch) {
      case ' ':
      case '@':
      case '<':
      case '(':
      case '[': {
        index = i;
        break;
      }
      default:
        continue;
    }
    index = i;
    break;
  }

  output = input.substring(0, index);
  output = output.trim();

  if (output.length === 0) {
    output = input;
  }

  if (output.endsWith(',') || output.endsWith('.')) {
    output = output.substring(0, output.length - 1);
  }
  return output;
};

const getNameFromEmailPair = (pair) => {
  let name = pair[0];
  let email = pair[1];

  if (name && name !== '') {
    name = getFirstName(name);
    if (name.length > 0) {
      return name;
    }
  }
  const array = email.split('@');
  // if more than one @ is present or no @ is present then return the email itself
  // if email is amazon@fakemail@gmail.com then we will show amazon as name that is wrong.
  if (array.length === 2) {
    email = array[0];
  }
  return email;
};

const removeFromJsonArray = (oldJsonArray, index) => {
  const newJsonArray = [];

  try {
    if (oldJsonArray) {
      for (let i = 0; i < oldJsonArray.length; i += 1) {
        // Excluding the item at position
        if (i !== index) {
          newJsonArray.push(oldJsonArray[i]);
        }
      }
    }
  } catch (error) {
    console.log('error in removeFromJsonArray');
    console.log(error);
  }
  return newJsonArray;
};

const getBackOffInterval = (retryCount) => {
  const backOffIntervalInMillis = Math.pow(2, (retryCount - 1)) * 1000;
  return backOffIntervalInMillis;
};

const generateRandomNumber = (seed) => {
  const x = Math.sin(seed) * 1000000000000;
  return x - Math.floor(x);
};

const getTimezoneOffsetInSeconds = () => -(new Date().getTimezoneOffset() * 60);

const getCustomDraftFolderId = (accountId) => {
  const pref = AccountSettingsPreferences.getInstance();
  return pref.getDraftFolderId(AccountSettingsPreferences
    .getPreferenceKey(accountId, AccountSettingsPreferences.TYPE_DRAFT_FOLDER_ID));
};

/**
 * method is used for checking valid email id format.
 *
 * @param email
 * @return boolean true for valid false for invalid
 */
const isEmailValid = (email) => {
  if (!email) {
    return false;
  }

  const pattern = /^[a-z0-9,!#$%&'*+/=?^_`{|}~-]+(\.[a-z0-9,!#$%&'*+/=?^_`{|}~-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.([a-z]{2,})$/gi;
  const inputStr = email.trim();
  return pattern.test(inputStr);
};

const getStringArrayFromCommaSeparatedString = (str) => {
  if (!str) {
    return null;
  }

  const result = str.split(',');
  return result;
};

const getDefaultQueryStringForFooter = () => {
  const getParams = {
    source: 'email_footer_2',
    ln: navigator.language,
  };
  return BaseAPI.getQueryParams(getParams);
};

const getComposeFooterHyperlink = () =>
  `via <a href="${Constants.MOBILE_PAGE_ROOT}${API.COMPOSE_FOOTER_PATH}${getDefaultQueryStringForFooter()}">Newton Mail</a>`;

const getComposeFooterText = (account) => {
  let sentUsingCMString = '';
  const pref = UserPreferenceSingleton.getInstance();
  if (account.isSentUsingCMSet) {
    if (pref.getMailFooter()) {
      sentUsingCMString = `<div>${pref.getMailFooter()}</div>`;
    } else {
      sentUsingCMString = `<div>${getComposeFooterHyperlink()}</div>`;
    }
  }
  return sentUsingCMString;
};

/**
 * Shamelessly copied from android.text.util.Rfc822Token
 *
 * Returns the name, conservatively quoting it if there are any
 * characters that are likely to cause trouble outside of a
 * quoted string, or returning it literally if it seems safe.
 */
const quoteNameIfNecessary = (name) => {
  const len = name.length;

  for (let i = 0; i < len; i += 1) {
    const c = name.charAt(i);

    if (!((c >= 'A' && c <= 'Z') ||
      (c >= 'a' && c <= 'z') ||
      (c === ' ') ||
      (c >= '0' && c <= '9'))) {
      return '"' + quoteName(name) + '"';
    }
  }

  return name;
};

/**
 * Shamelessly copied from android.text.util.Rfc822Token
 *
 * Returns the name, with internal backslashes and quotation marks
 * preceded by backslashes.  The outer quote marks themselves are not
 * added by this method.
 */
const quoteName = (name) => {
  const sb = [];

  const len = name.length;
  for (let i = 0; i < len; i += 1) {
    const c = name.charAt(i);
    if (c === '\\' || c === '"') {
      sb.push('\\');
    }

    sb.push(c);
  }
  return sb.join('');
};

const getCustomColorNameFromGroup = (colorGroup, colorIndex) => {
  if (colorGroup === 'custom') {
    try {
      const colorPalette = Constants.ACCOUNT_COLOR_PALETTE;
      const customPaletteArray = colorPalette.data.account_group_color_list.custom;

      return customPaletteArray[colorIndex][0];
    } catch (err) {

    }
  }

  return '';
};

const hashCode = (str) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

const sortFoldersByRank = folders => folders.sort((a, b) => (a.folderRank - b.folderRank));

const sortAccountsByKey = (accounts) => {
  if (!accounts) {
    return null;
  }

  const preference = UserPreferenceSingleton.getInstance();
  const accountOrder = preference.getAccountsOrder();

  if (!accountOrder || accountOrder.length === 0) {
    return accounts;
  }

  // Here we are adding other accountIds which are not present in accountOrder array.
  // This will make sure correct sorting of accounts inside sort function.
  for (const acc of accounts) {
    let presentInAccOrder = false;
    for (const accId of accountOrder) {
      if (accId === acc.accountId) {
        presentInAccOrder = true;
        break;
      }
    }

    if (!presentInAccOrder) {
      accountOrder.push(acc.accountId);
    }
  }

  return accounts.sort((a, b) => {
    const lhsIndex = accountOrder.indexOf(a.accountId);
    const rhsIndex = accountOrder.indexOf(b.accountId);

    if (lhsIndex === -1) {
      return 1;
    }

    if (rhsIndex === -1) {
      return -1;
    }

    return (lhsIndex - rhsIndex);
  });
};

const filterInlineAttachments = (attachmentList) => {
  if (attachmentList) {
    return attachmentList
      .filter(attachment => attachment.contentPath && attachment.contentPath.length > 0)
      .map((attachment) => {
        const contentPath = attachment.tempContentPath || attachment.contentPath;
        if (!attachment.tempContentPath) {
          attachment.tempContentPath = attachment.contentPath;
        }
        attachment.contentPath = BaseAPI.getServerMobilePageUrl() + contentPath;
        return attachment;
      });
  }
  return [];
};

const getNickname = (accountId, prefs) => {
  if (!prefs) {
    prefs = AccountSettingsPreferences.getInstance();
  }
  const nickName = prefs.getNickName(AccountSettingsPreferences
    .getPreferenceKey(accountId, AccountSettingsPreferences.TYPE_NAME));
  let display;
  if (nickName.length === 0) {
    display = prefs.getDefaultNickName(AccountSettingsPreferences.getPreferenceKey(
      accountId,
      AccountSettingsPreferences.TYPE_DEFAULT_NAME,
    ));
  } else {
    display = nickName;
  }
  return display;
};

const getFirstNameFromFullName = (fullName) => {
  if (!fullName || fullName.trim().length === 0) {
    return fullName;
  }

  const names = fullName.split(' ');
  if (names && names.length > 0) {
    return names[0];
  }

  return fullName;
};

const getContactUsDetails = () => {
  const message = {};
  message.toAddresses = JSON.stringify([['hello@newtonhq.com', 'hello@newtonhq.com']]);
  message.subject = `Contact Us - ${UserPreferenceSingleton.getInstance().getEmail()}`;
  message.ignoreAutoBcc = true;
  message.defaultSelectedAccount = UserPreferenceSingleton.getInstance().getDefaultFromAddress();
  return message;
};

const getInvitefriendDetails = () => {
  const message = {};
  const coupen = UserPreferenceSingleton.getInstance().getCoupenCode()
  message.ccAddresses = JSON.stringify([['hello@newtonhq.com', 'hello@newtonhq.com']]);
  message.subject = '20% off: Newton Mail Coupon Code';
  message.ignoreAutoBcc = true;
  message.defaultSelectedAccount = UserPreferenceSingleton.getInstance().getDefaultFromAddress();
  message.comesFrom = 'invite_friend'
  let body = 'Hey,<br/><br/>';
  body = body.concat('I am loving the Newton app. It is one of the better cross platform email experiences.');
  body = body.concat('<br/><br/>');
  body = body.concat('Please download the apps from <a href="http://www.newtonhq.com/">www.newtonhq.com</a> and use my coupon code <b>' + coupen + '</b> to get 20% off on your subscription.');
  body = body.concat('<br/><br/>');
  body = body.concat('Thanks');
  message.body = body
  return message;
};

const getConversationKey = (conversation) => {
  if (UserPreferenceSingleton.getInstance().getIsConversationView()) {
    return conversation.conversationServerId || conversation.conversationId;
  }
  return conversation.resourceId;
};

const getNameFromEmailId = (emailId) => {
  if (!emailId || !emailId.includes('@')) {
    return emailId;
  }

  const splitter = emailId.split('@');
  return splitter[0];
};

const showErrorToast = (message) => {
  // toastr.removeByType('light');
  const toastrOptions = {
    timeOut: 3000,
    actionText: '',
    progressBar: false,
  };
  toastr.light('', message, '@newton/toastr/error', toastrOptions);
};


const showToast = (message, options = {}) => {
  const toastrOptions = {
    timeOut: 3000,
    actionText: '',
    progressBar: false,
    ...options
  };
  toastr.light('', message, toastrOptions);
};

const deleteOutboxOrSentMail = async (previousOutboxId, dbWrapper,
  accountID, previousFolderId,
  conversationId, addToBacklog = true) => {
  const resourceIdList = [previousOutboxId];
  await dbWrapper.deleteSendLaterInfo(previousOutboxId);
  if (addToBacklog) {
    await dbWrapper.addMessageResourceIdToDeleteBackLog(resourceIdList);
  }
  await dbWrapper.deleteOnlyMessage(previousOutboxId, accountID);
  await dbWrapper.deleteConverstionFolderMapping(previousFolderId, conversationId);
};

const getCookiesString = (url) => {
  const accessTokens = UserPreferenceSingleton.getInstance().getAccessTokens();
  let cookieArray = [];
  if (accessTokens.length > 0) {
    const accessTokenObject = JSON.parse(accessTokens);
    cookieArray = Object.keys(accessTokenObject).map(key => `${key}=${accessTokenObject[key]};`);
  }
  const userIdentifier = UserPreferenceSingleton.getInstance().getUserIdentifier();
  cookieArray.push(`CM-USER-IDENTIFIER=${userIdentifier};`);
  cookieArray.push(`path=${url}`);
  return cookieArray.join('');
};

const getServerMobilePageUrl = () =>
  BaseAPI.getServerMobilePageUrl();

const getServerTrackingMobilePageUrl = () => {
  const userProvidedurl = UserPreferenceSingleton.getInstance().getServerUrl();
  if (userProvidedurl) {
    if (userProvidedurl === 'cloudmagic.com') {
      return 'https://tr.cloudmagic.com';
    }
    return `https://tr-${userProvidedurl}`;
  }
  return Constants.TRACKING_API_ROOT;
};

const removeFromUnseenMessagesUsingAccountId = (accountId) => {
  try {

    let unseenMessagesMapping = UserPreferenceSingleton.getInstance().getUnseenMessageMapping();
    let unseenMessageJsonArray = JSON.parse(unseenMessagesMapping);

    const itemsToRemove = [];
    for (let i = 0; i < unseenMessageJsonArray.length; i++) {
      const jsonObj = unseenMessageJsonArray[i];
      if (jsonObj.account_id === accountId) {
        itemsToRemove.push(jsonObj.resource_id);
      }
    }

    if (itemsToRemove.length > 0) {
      for (const resId of itemsToRemove) {
        let indexToRemove = -1;
        for (let i = 0; i < unseenMessageJsonArray.length; i++) {
          const jsonObj = unseenMessageJsonArray[i];
          if (jsonObj.resource_id === resId) {
            indexToRemove = i;
            break;
          }
        }

        if (indexToRemove !== -1) {
          unseenMessageJsonArray.splice(indexToRemove, 1);
        }
      }

      UserPreferenceSingleton.getInstance().setUnseenMessageMapping(JSON.stringify(unseenMessageJsonArray));
      return true;
    }

    return false;

  } catch (error) {
    console.error('unseen message update error');
    console.error(error);
  }
};

const removeUnseenMessageUsingResourceIds = (resourceIds) => {
  if (resourceIds && resourceIds.length === 0) {
    return;
  }

  try {
    const unseenMessagesMapping = UserPreferenceSingleton.getInstance().getUnseenMessageMapping();
    const unseenMessageJsonArray = JSON.parse(unseenMessagesMapping);

    for (const resourceId of resourceIds) {
      let itemToRemoveIndex = -1;
      for (let i = 0; i < unseenMessageJsonArray.length; i += 1) {
        const jsonObj = unseenMessageJsonArray[i];

        if (resourceId === jsonObj.resource_id) {
          itemToRemoveIndex = i;
          break;
        }
      }

      if (itemToRemoveIndex !== -1) {
        unseenMessageJsonArray.splice(itemToRemoveIndex, 1);
      }
    }

    UserPreferenceSingleton.getInstance()
      .setUnseenMessageMapping(JSON.stringify(unseenMessageJsonArray));
  } catch (error) {
    console.error('unseen message update error');
    console.error(error);
  }
};

const getMachineId = () => {
  let id = '';
  const { v4: uuid } = require('uuid');
  id = uuid();
  return id;
};

const isUserLoggedIn = (loginState) => {
  const userpreference = UserPreferenceSingleton.getInstance();
  const accessToken = userpreference.get(UserPreferenceSingleton.ACCESS_TOKEN);
  const userType = userpreference.get(UserPreferenceSingleton.CM_USER_TYPE);
  if (accessToken && userType === Constants.CM_USER_WITH_ACCOUNT && loginState === LOGIN.SUCCESS) {
    console.log("isUserLoggedIn =====> true")
    return true;
  }
  console.log("isUserLoggedIn ====> false")
  return false;
};

const getThumbnailCharcters = (name) => {
  if (name) {
    const tokens = name.trim().toUpperCase().split(" ");
    if (tokens.length >= 2)
      return `${tokens[0].charAt(0)}${tokens[1].charAt(0)}`;
    return `${tokens[0].charAt(0)}`;
  }
  return 'U';
}

/**
 * @desc check does it dev mode or live mode
 * it return false only if its a production build
 */
export const isDev = () => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return false;
};

const exports = {
  getDomainFromAccount,
  isSameDomainExistInMultipleAccounts,
  getExtension,
  isFilePathIsImage,
  getUsernameFromAccount,
  getNameFromEmailPair,
  getFirstName,
  removeFromJsonArray,
  getBackOffInterval,
  generateRandomNumber,
  getTimezoneOffsetInSeconds,
  isEmailValid,
  quoteNameIfNecessary,
  getCustomColorNameFromGroup,
  isAccountSupported,
  hashCode,
  sortAccountsByKey,
  sortFoldersByRank,
  filterInlineAttachments,
  getComposeFooterText,
  getComposeFooterHyperlink,
  getNickname,
  getFirstNameFromFullName,
  getContactUsDetails,
  getConversationKey,
  showErrorToast,
  showToast,
  deleteOutboxOrSentMail,
  getCookiesString,
  getServerTrackingMobilePageUrl,
  getUserNickName,
  getStringArrayFromCommaSeparatedString,
  removeUnseenMessageUsingResourceIds,
  removeFromUnseenMessagesUsingAccountId,
  getMachineId,
  isUserLoggedIn,
  getCustomDraftFolderId,
  getNameFromEmailId,
  getServerMobilePageUrl,
  getThumbnailCharcters,
  getInvitefriendDetails
};
export default exports;