import EnableSiblings from './pages/EnableSiblings';
import Home from './pages/Home';
import Login from './pages/MemberShip/Login';
import TwoStepVerification from './pages/MemberShip/TwoStepVerification';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
    private: true
  },
  {
    path: '/enable-siblings',
    exact: true,
    component: EnableSiblings,
    private: true
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    private: false
  },
  {
    path: '/two-step-verification',
    exact: true,
    component: TwoStepVerification,
    private: false
  }
];

export default routes;
