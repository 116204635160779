import { put, take, call } from 'redux-saga/effects';
import Actions from '../actions';
import Logger from '../helper/logger';
import Api from '../api';
import { CALENDAR_CREATE_EVENT } from './../constants/constants';

export function* fetchCalendarList() {
  try {
    yield put(Actions.setCalenderLoader(true));
    const apiObj = new Api.GetCalendarList();
    const apiResponse = yield apiObj.execute();
    if (apiResponse.error) {
      console.error('API Error', apiResponse.error);
    } else {
      const eventsData = apiResponse.response.data;
      yield put(Actions.updateCalendarList(eventsData.list));
      return eventsData.list;
    }
    yield put(Actions.setCalenderLoader(false));
  } catch (error) {
    console.log('ERROR', error);
    Logger.log(error);
  }
}

export function* fetchEventList(action, syncType = 'initial') {
  try {
    yield put(Actions.setCalenderLoader(true));
    let accountIds = [];
    action.payload.accounts.map((item) => {
      item.list.forEach((account) => {
        if (account.is_primary) {
          accountIds.push({
            account_id: item.account_id,
            calendar_uid: account.calendar_uid,
            color: account.color
          });
        }
      });
      const calendarUser = item.list.find((x) => x.is_primary === 1);
      return {
        account_id: item.account_id,
        calendar_uid: calendarUser ? calendarUser.calendar_uid : undefined
      };
    });
    let accountEvents = [];
    let hasMore = true;
    while (hasMore) {
      const apiObj = new Api.GetEventList();
      const apiResponse = yield apiObj.execute(accountIds, syncType);
      if (apiResponse.error) {
        console.error('API Error', apiResponse.error);
        break;
      } else {
        const eventsData = apiResponse.response.data;
        const list = eventsData.list || [];
        accountIds.forEach((account) => {
          const accountWiseEvent = accountEvents.find((x) => x.account_id === account.account_id);
          const apiAccountEvents = list.find((x) => x.account_id === account.account_id);
          if (apiAccountEvents) {
            if (!accountWiseEvent) {
              const newItem = {
                account_type: apiAccountEvents.account_type,
                account_id: apiAccountEvents.account_id,
                list: apiAccountEvents.list[0].list,
                has_more: apiAccountEvents.list[0].has_more,
                calendar_uid: apiAccountEvents.list[0].calendar_uid,
                sync_hash: apiAccountEvents.list[0].sync_hash,
                color: account.color
              };
              accountEvents.push(newItem);
            } else {
              accountWiseEvent.has_more = apiAccountEvents.list[0].has_more;
              accountWiseEvent.calendar_uid = apiAccountEvents.list[0].calendar_uid;
              accountWiseEvent.sync_hash = apiAccountEvents.list[0].sync_hash;
              accountWiseEvent.has_more = apiAccountEvents.list[0].has_more;

              apiAccountEvents.list[0].list.forEach((event) => {
                accountWiseEvent.list.push(event);
              });
            }
          }
        });

        accountIds = accountEvents
          .filter((x) => x.has_more)
          .map((item) => {
            return {
              account_id: item.account_id,
              calendar_uid: item.calendar_uid,
              sync_hash: item.sync_hash
            };
          });
        hasMore = accountIds.length > 0;
      }
    }
    yield put(Actions.updateCalendarEventList(accountEvents));
    yield put(Actions.setCalenderLoader(false));
  } catch (error) {
    console.log('ERROR', error);
    Logger.log(error);
  }
}

export function* createCalendarEvent(action) {
  try {
    // yield put(Actions.setCalenderLoader(true));
    const apiObj = new Api.CreateCalendarEvent(CALENDAR_CREATE_EVENT);
    const { event, calendarAccount } = action.payload;
    if (!event || !calendarAccount) return;
    const apiResponse = yield apiObj.execute(
      calendarAccount.account_id,
      CALENDAR_CREATE_EVENT,
      `${calendarAccount.account_id}_${new Date().getTime()}`,
      JSON.stringify(event)
    );
    if (apiResponse.error) {
      console.error('API Error', apiResponse.error);
    } else {
      yield put(Actions.createEventStatus(true));
    }
  } catch (error) {
    console.log('ERROR', error);
    Logger.log(error);
  } finally {
    // yield put(Actions.setCalenderLoader(false));
  }
}

export function* enableSiblings(action) {
  try {
    yield put(Actions.setCalenderLoader(true));
    const { accountIds } = action.payload;
    if (!accountIds || accountIds.length === 0) return;

    const apiObj = new Api.EnableSiblings();
    const apiResponse = yield apiObj.execute(accountIds);
    if (apiResponse.error) {
      console.error('API Error', apiResponse.error);
    } else {
      // const data = apiResponse.response.data;
      yield put({ type: Actions.SYNC_START });
    }
    yield put(Actions.setCalenderLoader(false));
  } catch (error) {
    console.log('ERROR', error);
    Logger.log(error);
  }
}

export function* watchCalenderEvents() {
  while (true) {
    try {
      const action = yield take([
        Actions.FETCH_CALENDER_LIST,
        Actions.FETCH_CALENDER_EVENTS,
        Actions.CALENDER_EVENT_CREATE,
        Actions.CALENDER_ENABLE_SIBLINGS
      ]);

      if (action.type === Actions.FETCH_CALENDER_LIST) yield call(fetchCalendarList, action);
      else if (action.type === Actions.FETCH_CALENDER_EVENTS) yield call(fetchEventList, action);
      else if (action.type === Actions.CALENDER_EVENT_CREATE) yield call(createCalendarEvent, action);
      else if (action.type === Actions.CALENDER_ENABLE_SIBLINGS) yield call(enableSiblings, action);
    } catch (e) {
      console.log('ERROR Template', e);
    }
  }
}
