import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import UserPreferenceSingleton from './helper/UserPreferenceSingleton';
import './App.scss';
import routes from './routes';
import history from './history';
import PageNotFound from './pages/PageNotFound';
import PrivateRoute from './components/PrivateRoutes';
import StoreSingleton from './store/store';
import * as Constants from './helper/constant';
import { LOGIN } from './reducers/login';
import Analytics from './utils/analytics-service';
import rootSaga from './sagas';

const accessToken = UserPreferenceSingleton.getInstance().get('access_tokens');
const userIdentifier = UserPreferenceSingleton.getInstance().get('user_identifier');
const userType = UserPreferenceSingleton.getInstance().get(UserPreferenceSingleton.CM_USER_TYPE);
let initialState = {};
if (accessToken && userIdentifier && userType === Constants.CM_USER_WITH_ACCOUNT) {
  initialState = { login: { login: LOGIN.SUCCESS } };
}

Analytics.setUserId(userIdentifier);

const storeSingleton = StoreSingleton.getInstance();
storeSingleton.configureStore(initialState);
const store = storeSingleton.getStore();

store.runSaga(rootSaga);

class App extends Component {

  render() {
    const routeComponents = routes.map((r, i) => {
      if (r.private) {
        return <PrivateRoute key={i} {...r} />;
      } else {
        return <Route key={i} {...r} />;
      }
    });

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <>
            <Switch>
              {/* <Route exact path='/' component={Login} /> */}
              {routeComponents}
              <Route exact component={PageNotFound} />
            </Switch>
          </>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
