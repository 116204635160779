
// import amplitude from 'amplitude-js';
// import { AMPLITUDE_ANALYTICS_KEY } from './../helper/constant'
import { isDev } from './common-utils';

/**
 * @desc init analytics
 */
const init = () => {
  try {
    if (isDev()) {
      console.log('initAnalytics');
      return;
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * @desc set user
 */
const setUserId = (userId) => {
  try {
    if (isDev()) {
      console.log('setAnalyticUserId', userId);
      return;
    }
    // if (userId) {
    //   amplitude.setUserId(userId);
    // }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc set user props
 */
const setUserProps = (props) => {
  try {
    if (isDev()) {
      console.log('setAnalyticUserProps', props);
      return;
    }
    // if (props) {
    //   amplitude.setUserProperties(props);
    // }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc track activity in analytics
 */
const trackActivity = (name, params) => {
  try {
    if (isDev()) {
      console.log('trackActivity', name, params);
      return;
    }
    // amplitude.logEvent(name, params);
  } catch (e) {
    console.error(e);
  }
};

const exports = { init, setUserId, setUserProps, trackActivity };
export default exports;