import { BaseAPI, API } from './base-api';

export default class GetProfileImageLoad extends BaseAPI {

  constructor() {
    super(API.PROFILE_LOAD_IMAGE);
  }

  execute(payload) {
    const params = {
      email: payload.to
    }
    return super.connect(params);
  }
}
