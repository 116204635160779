import { BaseAPI, API } from './base-api';

export default class GetEventList extends BaseAPI {
  constructor() {
    super(API.CALENDER_EVENT_LIST);
  }

  execute(accountIds, sync_type = 'initial') {
    const postParams = {};
    const sync_info = accountIds.map((item) => {
      return {
        "account_id": item.account_id,
        "list": [{
          "sync_hash": item.sync_hash || {},
          "calendar_uid": item.calendar_uid
        }]
      }
    });
    postParams.sync_info = JSON.stringify(sync_info);
    postParams.sync_type = sync_type;
    postParams.timezone_offset = '19800';
    return super.connect(postParams);
  }
}
